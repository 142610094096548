import { isEmpty } from 'lodash-es';
import { paramCleaner } from '@/helpers/formatters';
import { getRequest, postRequest } from './request';

export const initLibrary = async () => {
  const {
    menuPages = [], defaultPage = '', pipelineEnabled = false, applicationReportingEnabled = false,
  } = await getRequest({ url: '/library/init' });
  return {
    menuPages, defaultPage, pipelineEnabled, applicationReportingEnabled,
  };
};

export const libraryGetDocuments = async ({
  mode, params = {}, selectedFilters, selectedMyJobs, searchTerm,
}) => {
  const {
    sort, page, q, ...paramFilters
  } = params;

  const cleanedParams = paramCleaner(paramFilters);

  const searchParams = new URLSearchParams({ mode });
  if (typeof sort !== 'undefined') searchParams.append('sort', sort);
  else if (searchTerm) searchParams.append('sort', 'relevance');

  if (typeof page !== 'undefined') searchParams.append('page', page);

  const {
    documents = [],
    filters = [],
    globalFilters = [],
    sorting = {},
    pagination = {},
    totals = {},
    owned = {},
    textFilter = {},
  } = await postRequest({
    /*
    Note that this URL is different from the vue URL. This is because filters must be sent in body.
      (backend URL example) = /library/documents?mode=draft
      (frontend URL example) = /document-library/draft?
    */
    url: `/library/documents?${searchParams}`,
    body: {
      filters: !isEmpty(selectedFilters) ? selectedFilters : cleanedParams,
      owned: selectedMyJobs,
      textFilter: searchTerm,
    },
  });
  return {
    documents,
    filters,
    globalFilters,
    sorting,
    pagination,
    totals,
    owned,
    textFilter,
  };
};

export const deleteLibraryDocument = async (id) => {
  const { success, code } = await postRequest({
    url: '/library/delete',
    body: { id },
  });
  return { success, code };
};

export const updateGlobalFilters = async ({
  selectedGlobalFilters,
}) => {
  const { success, code } = await postRequest({
    url: '/library/global-filters',
    body: selectedGlobalFilters,
  });
  return { success, code };
};
