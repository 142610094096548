import { ref, computed } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { isEqual, isEmpty } from 'lodash-es';
import { getAsyncSearchResults } from '@/api/asyncSearch';
import { useDatePicker } from '@/stores/filters/datePicker';
import { useGlobalFilters } from '@/stores/filters/globalFilters';
import { useSubMenu } from '@/stores/filters/subMenu';
import { useModals } from '@/stores/modals/modals';
import { useAppRoute } from '@/stores/app/route';
import { useAppPagination } from '@/stores/app/pagination';
import { useAppLoading } from '@/stores/app/loading';
import { useAppAlerts } from '@/stores/app/alerts';
import { updateGlobalFilters } from '@/api/library';
import { emitter } from '@/helpers/emitter';
import { format } from 'date-fns';

export const useFilters = defineStore('filters', () => {
  // Other Stores
  const { setDateRange } = useDatePicker();
  const { tempDateRange, dateWeekIndex } = storeToRefs(useDatePicker());
  const { setGlobalFilters, resetGlobalFilters } = useGlobalFilters();
  const { mergedTempGlobalFilters } = storeToRefs(useGlobalFilters());
  const {
    setSubMenuOptions,
    setSelectedSubMenuOption,
    setSubMenuDisabled,
  } = useSubMenu();
  const { selectedSubMenuOption } = storeToRefs(useSubMenu());
  const { setModal } = useModals();
  const { show } = storeToRefs(useModals());
  const { route } = storeToRefs(useAppRoute());
  const { setPageNumber } = useAppPagination();
  const { setLoading } = useAppLoading();
  const { addAlert } = useAppAlerts();

  // State
  const fields = ref([]);
  const tempFilters = ref([]);
  const myJobsEnabled = ref(false);
  const enforcedMyJobsEnabled = ref(false);
  const searchTerm = ref('');

  const filterConfig = ref({
    module: '',
    showMyJobs: false,
    datepicker: false,
    subMenuType: null,
  });

  // Getters
  const getAlwaysOn = computed(() => {
    const filteredFields = fields.value.filter((field) => field.alwaysOn);
    return filteredFields.length;
  });

  const activeFilters = computed(() => fields.value.some((field) => field.value.length));

  const noActiveFilters = computed(() => Boolean(
    getAlwaysOn.value || activeFilters.value,
  ));

  const mergedTempFilters = computed(() => {
    const filters = {};
    tempFilters.value.forEach((filter) => {
      if (filter.value.length) {
        filters[filter.id] = filter.value.map((value) => value.id);
      }
    });
    return filters;
  });

  const trackingFilters = computed(() => {
    /* compare last successful filter request (fields) to newly-selected filters (tempFilters) */
    const filterTypes = [];
    fields.value.forEach((field, index) => {
      if (!isEqual(field.value, tempFilters.value[index].value)) {
        filterTypes.push(field.label);
      }
    });
    return filterTypes;
  });

  // Actions
  const setFilterConfig = (props) => {
    Object.entries(props).forEach(([key, value]) => {
      filterConfig.value[key] = value;
    });
  };

  const setFilterFields = (newFields) => {
    const filteredFields = newFields.filter((filter) => filter !== null);
    const convertLabelsToString = (items) => items.map((item) => {
      if (typeof item.label !== 'string') {
        if (typeof item.label === 'boolean') {
          return {
            ...item,
            label: item.label ? 'Yes' : 'No',
          };
        }
        return {
          ...item,
          label: String(item.label),
        };
      }
      return item;
    });

    tempFilters.value = filteredFields.map((field, index) => ({
      ...field,
      options: convertLabelsToString(field.options),
      value: convertLabelsToString(filteredFields[index].value),
      loadOptions: field.async ? function loadOptionsCallback({
        action, searchQuery, callback,
      }) {
        if (
          field.async
          && searchQuery.length >= 2
          && action === 'ASYNC_SEARCH'
        ) {
          getAsyncSearchResults({
            query: searchQuery,
            urlObj: field.asyncUrl,
          })
            .then((res) => {
              /* callback notifies vue-treeselect about data population completion */
              callback(null, res);
            });
        }
      } : null,
    }));
    fields.value = filteredFields.map((field, index) => ({
      ...field,
      options: convertLabelsToString(field.options),
      value: convertLabelsToString(filteredFields[index].value),
    }));
  };

  const setTempFilters = ({ value, index }) => {
    const newTempFilters = [...tempFilters.value];
    newTempFilters[index].value = value;
    tempFilters.value = newTempFilters;
  };

  const setMyJobsEnabled = ({ value = false, enforced = false }) => {
    myJobsEnabled.value = value;
    enforcedMyJobsEnabled.value = enforced;
  };

  const setSearchTerm = (value) => {
    searchTerm.value = value || '';
  };

  const resetValues = ({ fieldIndex, setToAppliedFilters = false } = {}) => {
    // clear individual field value if fieldIndex is passed, else clear values for all fields
    if (typeof fieldIndex !== 'undefined') {
      tempFilters.value[fieldIndex].value = setToAppliedFilters
        ? fields.value[fieldIndex].value : [];
    } else {
      fields.value.forEach((_, index) => {
        tempFilters.value[index].value = setToAppliedFilters ? fields.value[index].value : [];
      });
    }
  };

  const clearPersistentFilters = () => {
    searchTerm.value = '';
    myJobsEnabled.value = false;
  };

  const updateFilters = ({
    filters,
    globalFilters,
    subMenuOptions,
    dateRange,
    myJobsEnabled: newMyJobsEnabled,
    searchTerm: newSearchTerm,
  }) => {
    if (filters) { setFilterFields(filters); }
    if (dateRange) { setDateRange(dateRange); }
    if (globalFilters) { setGlobalFilters(globalFilters); }
    if (newMyJobsEnabled) { setMyJobsEnabled(newMyJobsEnabled); }
    if (newSearchTerm) { setSearchTerm(newSearchTerm.value); }
    if (subMenuOptions) {
      setSubMenuOptions(subMenuOptions);
      setSelectedSubMenuOption(subMenuOptions.value);
      setSubMenuDisabled(subMenuOptions.disabled || false);
    } else setSubMenuOptions({ options: [] });
  };

  const sendFilterRequest = ({
    pageNumber, router, resetData,
  }) => {
    if (show.value.filter) {
      setModal({ field: 'filter', value: false });
    }
    if (show.value.globalFilter) {
      setModal({ field: 'globalFilter', value: false });
    }

    let page = '';
    if (![
      'inbound-report',
      'outbound-report',
      'overall-report',
      'drop-off',
      'pass-through',
      'gender-report',
      'team',
    ].includes(route.value.name)) {
      /* If pagination is clicked, send specific page number,
      otherwise set page to 1 before sending */
      page = pageNumber || 1;
      setPageNumber(page);
    }

    /* send current route name and filters to reload current page with filter data */
    emitter.emit('updatedFilters', {
      router,
      page,
      mode: route.value.name,
      selectedFilters: mergedTempFilters.value,
      selectedGlobalFilters: mergedTempGlobalFilters.value,
      selectedMyJobs: myJobsEnabled.value,
      searchTerm: searchTerm.value,
      selectedDateRange: isEmpty(tempDateRange.value)
        ? {}
        : {
          from: format(tempDateRange.value[0], 'yyyy-MM-dd'),
          to: format(tempDateRange.value[1], 'yyyy-MM-dd'),
        },
      selectedDateWeek: dateWeekIndex.value || router.currentRoute?.value?.query?.week,
      resetData,
      selectedSubMenuOption: selectedSubMenuOption.value,
    });
  };

  const applyGlobalFilters = async ({ router }) => {
    setLoading({ filtersLoading: true, listLoading: true });

    /* Update global filters before loading filtered documents */
    try {
      const { success } = await updateGlobalFilters({
        selectedGlobalFilters: mergedTempGlobalFilters.value,
      });

      if (success) {
        /* Send request to filter after global filters have been updated */
        sendFilterRequest({ router });

        addAlert({
          message: 'Global filters have been updated',
          type: 'success',
          thisPageOnly: true,
          timeout: 3500,
        });
      }
    } catch (error) {
      addAlert({
        message: `Unable to update global filters - ${error.message}`,
        thisPageOnly: true,
        timeout: 3500,
      });
    }
  };

  const submitSubMenu = (({ optionId, router }) => {
    setSelectedSubMenuOption(optionId);
    sendFilterRequest({ router });
  });

  const submitMyJobs = ({ myJobsEnabled: newMyJobsEnabled, router }) => {
    setMyJobsEnabled({ value: newMyJobsEnabled, enforced: enforcedMyJobsEnabled.value });
    sendFilterRequest({ router });
  };

  const submitSearchTerm = ({ searchTerm: newSearchTerm, router, defaultSort = 'relevance' }) => {
    if (newSearchTerm) {
      setSelectedSubMenuOption(defaultSort);
    } else {
      setSelectedSubMenuOption('-date');
    }
    setSearchTerm(newSearchTerm);
    sendFilterRequest({ router });
  };

  const setUserFilter = async ({ user, router }) => {
    const newFields = fields.value.map((field) => {
      if (field.id === 'user') {
        const alreadyHasUserSelected = field.value.some(({ id }) => id === user.userId);

        return {
          ...field,
          value: alreadyHasUserSelected ? field.value : [...field.value, user],
        };
      }

      return field;
    });

    updateFilters({ filters: newFields });
    sendFilterRequest({ router });
  };

  const clearValues = ({ index, router }) => {
    resetValues({ fieldIndex: index });
    sendFilterRequest({ router });
  };

  const clearGlobalFilters = ({ router }) => {
    resetGlobalFilters();
    applyGlobalFilters({ router });
  };

  return {
    // State
    fields,
    tempFilters,
    myJobsEnabled,
    enforcedMyJobsEnabled,
    searchTerm,

    filterConfig,

    // Getters
    getAlwaysOn,
    activeFilters,
    noActiveFilters,
    mergedTempFilters,
    trackingFilters,

    // Actions
    setFilterConfig,
    setFilterFields,
    setTempFilters,
    setMyJobsEnabled,
    setSearchTerm,
    resetValues,
    clearPersistentFilters,

    updateFilters,
    sendFilterRequest,
    applyGlobalFilters,
    submitSubMenu,
    submitMyJobs,
    submitSearchTerm,
    setUserFilter,
    clearValues,
    clearGlobalFilters,
  };
});
