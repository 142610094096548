import TrackingPlugin from '@/helpers/tracking-plugin';
import { initPartytown } from '@/init/partytown';

export const initTracking = ({ app }) => {
  if (process.env.NODE_ENV === 'production'
    && [
      'app.datapeople.io',
      'sandbox.datapeople.io',
      'test-app.taprecruit.co',
    ].includes(window.location.hostname)
    && !window.navigator.userAgent.endsWith('AWS-Canary-Noscript')
  ) {
    initPartytown();
    app.use(TrackingPlugin);
  } else if (process.env.VUE_APP_MIXPANEL_LOG === 'true') {
    app.use({
      /* eslint-disable-next-line no-console */
      install() { window.mixpanel = { track(...params) { console.log('mixpanel', ...params); } }; },
    });
  }
};
