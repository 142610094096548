import { createApp } from 'vue';
import { createHead, VueHeadMixin } from '@unhead/vue';
import App from './App.vue';
import { initSentry } from './init/sentry';
import { initPinia } from './init/pinia';
import { initProxySessionCookie } from './init/proxySessionCookie';
import { initRouter } from './init/router';
import { initTracking } from './init/tracking';
import { initVuetify } from './init/vuetify';
// Vue.config.productionTip = false;

const app = createApp(App);

const head = createHead();
app.mixin(VueHeadMixin);
app.use(head);

const pinia = initPinia(app);
const router = initRouter({ pinia, app });
initSentry({ router, app });

initVuetify(app);

app.mount('#app');

initTracking({ app });
initProxySessionCookie();
