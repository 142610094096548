import { handleRedirectToLogin } from '@/routes/helpers';
import { useRouter, useRoute } from 'vue-router';
import { h } from 'vue';

export const editor = (authDataStore) => ([
  {
    path: '/view/:id',
    name: 'editor-view',
    props: true,
    component: () => import(/* webpackChunkName: "editorview" */ '@/views/EditorView.vue'),
    meta: {
      authStateRequired: 'either',
      timer: 6000,
      title: ({ title } = {}) => ((typeof title === 'string')
        ? title
        : ''),
      parentTitle: ({ documentTypeLabel } = {}) => (typeof documentTypeLabel === 'string' && documentTypeLabel ? `${documentTypeLabel}s` : ''),
    },
    beforeEnter: async (to, from, next) => {
      if (!window?.sessionStorage?.shareKey) {
        await handleRedirectToLogin(authDataStore, to, next);
        next();
      } else {
        next();
      }
    },
  },
  // a hacky way to fully re-render editor-view
  {
    path: '/view/reload/:id',
    name: 'editor-view-reload',
    props: true,
    meta: { authStateRequired: 'either', timer: 6000 },
    component: {
      setup(props) {
        const router = useRouter();
        const route = useRoute();

        setTimeout(() => {
          const { id } = props;
          router.replace({
            name: 'editor-view',
            params: { id },
            query: route.query,
          });
        }, 0);
        return h('div');
      },
    },
  },
]);
