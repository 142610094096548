import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

export const useGlobalFilters = defineStore('globalFilters', () => {
  // State
  const globalFilters = ref([]);
  const tempGlobalFilters = ref([]);

  // Getters
  const activeGlobalFilters = computed(() => globalFilters.value.some((f) => f.value.length));

  const mergedTempGlobalFilters = computed(() => {
    const newGlobalFilters = {};
    tempGlobalFilters.value.forEach((filter) => {
      if (filter.value.length) {
        newGlobalFilters[filter.id] = filter.value.map((value) => value.id);
      }
    });
    return newGlobalFilters;
  });

  // Actions
  const setGlobalFilters = (newFilters) => {
    tempGlobalFilters.value = JSON.parse(JSON.stringify(newFilters));
    globalFilters.value = newFilters.map((field, index) => ({
      ...field,
      value: newFilters[index].value,
    }));
  };

  const setTempGlobalFilters = ({ value, index }) => {
    const newTempGlobalFilters = [...tempGlobalFilters.value];
    newTempGlobalFilters[index].value = value;
    tempGlobalFilters.value = newTempGlobalFilters;
  };

  const resetGlobalFilters = () => {
    globalFilters.value.forEach((_, index) => {
      tempGlobalFilters.value[index].value = [];
    });
  };

  return {
    // State
    globalFilters,
    tempGlobalFilters,

    // Getters
    activeGlobalFilters,
    mergedTempGlobalFilters,

    // Actions
    setGlobalFilters,
    setTempGlobalFilters,
    resetGlobalFilters,
  };
});
