import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { useAppLoading } from '@/stores/app/loading';
import { useAppAlerts } from '@/stores/app/alerts';
import { fetchAdminHome, fetchNonAdminHome } from '@/api/home';
import { useAppAuthData } from '@/stores/app/authData';
import { adminCards, editorCards } from '@/stores/home/learnCardsData';

export const useHome = defineStore('home', () => {
  // State
  const isAdminOn = ref(false);

  // TODO: Replace with true default values and update with API data
  const score = ref(0);
  const activatedUsers = ref(0);
  const nonCompliantJobPosts = ref(0);
  const easyWinsJobPosts = ref(0);
  const openReqs = ref(0);
  const openPosts = ref(0);
  const draftReqs = ref(0);
  const draftPosts = ref(0);
  const userHasAtsId = ref(true);
  const usersNotUsingDA = ref(0);

  const recentViewsItems = ref([]);

  // Getters
  const learnCards = computed(() => {
    if (isAdminOn.value) return adminCards();

    return editorCards();
  });

  // Actions
  const setIsAdminOn = (value) => {
    isAdminOn.value = value;
  };

  const updateSummary = ({
    score: newScore = score.value,
    activatedUsers: newActivatedUsers = activatedUsers.value,
    usersNotUsingDA: newUsersNotUsingDA = usersNotUsingDA.value,
    nonCompliantJobPosts: newNonCompliantJobPosts = nonCompliantJobPosts.value,
    easyWinsJobPosts: newEasyWinsJobPosts = easyWinsJobPosts.value,
    openReqs: newOpenReqs = openReqs.value,
    openPosts: newOpenPosts = openPosts.value,
    draftReqs: newDraftReqs = draftReqs.value,
    draftPosts: newDraftPosts = draftPosts.value,
  }) => {
    score.value = newScore;
    activatedUsers.value = newActivatedUsers;
    usersNotUsingDA.value = newUsersNotUsingDA;
    nonCompliantJobPosts.value = newNonCompliantJobPosts;
    easyWinsJobPosts.value = newEasyWinsJobPosts;
    openReqs.value = newOpenReqs;
    openPosts.value = newOpenPosts;
    draftReqs.value = newDraftReqs;
    draftPosts.value = newDraftPosts;
  };

  const updateRecentViews = (recentViews) => {
    recentViewsItems.value = recentViews;
  };

  const getAdminHome = async () => {
    const [adminPostSummary, adminUserSummary, postSummary, reqSummary] = await fetchAdminHome();
    const update = {};

    if (adminPostSummary.status === 'fulfilled') {
      update.score = adminPostSummary.value.average_open_job_score?.value;
      update.nonCompliantJobPosts = adminPostSummary.value.non_compliant_jobs?.doc_count;
      update.easyWinsJobPosts = adminPostSummary.value.easy_wins_jobs?.doc_count;
    }

    if (adminUserSummary.status === 'fulfilled') {
      update.activatedUsers = adminUserSummary.value.active_users;
      update.usersNotUsingDA = adminUserSummary.value.users_not_using_da;
    }

    if (postSummary.status === 'fulfilled') {
      update.openPosts = postSummary.value.published_posts;
      update.draftPosts = postSummary.value.draft_posts;
    }

    if (reqSummary?.status === 'fulfilled') {
      update.openReqs = reqSummary.value.open || 0;
      update.draftReqs = reqSummary.value.draft || 0;
    }

    updateSummary(update);
  };

  const getNonAdminHome = async () => {
    const [postSummary, reqSummary] = await fetchNonAdminHome();

    updateSummary({
      openPosts: postSummary.published_posts,
      draftPosts: postSummary.draft_posts,
      openReqs: reqSummary?.open || 0,
      draftReqs: reqSummary?.draft || 0,
    });
  };

  const initHome = async () => {
    useAppLoading().setLoading({ pageLoading: true });

    try {
      if (useAppAuthData()?.authData?.role === 'admin' || useAppAuthData()?.authData?.isSuperuser) {
        await getAdminHome();
      } else {
        await getNonAdminHome();
      }
    } catch (error) {
      if (error.message !== 'We are unable to determine requisition counts for this user.') {
        useAppAlerts().addAlert({
          message: 'Unable to load home',
          thisPageOnly: true,
        });

        throw error;
      } else {
        // We use this to hide the requisition counts if the user does not have an ATS ID
        userHasAtsId.value = false;
      }
    } finally {
      useAppLoading().setLoading({ pageLoading: false });
    }
  };

  return {
    // State
    isAdminOn,
    score,
    activatedUsers,
    usersNotUsingDA,
    nonCompliantJobPosts,
    easyWinsJobPosts,
    openReqs,
    openPosts,
    draftReqs,
    draftPosts,
    learnCards,
    recentViewsItems,
    userHasAtsId,

    // Actions
    setIsAdminOn,
    updateSummary,
    updateRecentViews,
    initHome,
  };
});
