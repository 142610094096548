import theme from '@/configs/vuetify';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { snakeCase } from 'lodash-es';
import { roundFloat } from '@/helpers/formatters';
import { dataLabelPercentages, xTickPercentFormat } from '@/api/helpers/insights/commonChartConfigs';
import { getWidgets, getTables } from './transformers';
import {
  calculatePassthroughPercentages,
  getWidgetAndTableLabels,
} from './common';

const {
  valenciaRed,
  valenciaRedLighten20,
  zirconGrey,
  valenciaRedLighten40,
  valenciaRedLighten60,
  valenciaRedLighten80,
} = theme.theme.themes.light.colors;

/** defaultWidgetConfig manages the frontend config for each api widget */
const defaultWidgetConfig = [
  {
    id: 'top-sources-by-application',
    tooltipKey: 'outbound-source-for-apps',
    itemLabel: 'What outbound sources do our applications come from?',
    groupLabel: '',
    labels: [],
    values: [],
    type: 'horizontalBarChart',
    colors: [[
      valenciaRed,
      valenciaRedLighten20,
      valenciaRedLighten40,
      valenciaRedLighten60,
      valenciaRedLighten80,
      zirconGrey,
    ]],
    optionsOverride: {
      ...xTickPercentFormat,
      ...dataLabelPercentages,
    },
    pluginsOverride: [ChartDataLabels],
  },
  {
    id: 'top-sources-by-hire',
    tooltipKey: 'outbound-source-for-hires',
    itemLabel: 'What outbound sources do our hires come from?',
    groupLabel: '',
    labels: [],
    values: [],
    type: 'horizontalBarChart',
    colors: [[
      valenciaRed,
      valenciaRedLighten20,
      valenciaRedLighten40,
      valenciaRedLighten60,
      valenciaRedLighten80,
      zirconGrey,
    ]],
    optionsOverride: {
      ...xTickPercentFormat,
      ...dataLabelPercentages,
    },
    pluginsOverride: [ChartDataLabels],
  },
];

/**
 * fieldMappings is used to map the index of a widget from `defaultWidgetConfig`
 * to which values it will need to pull off the api object, labels, and formatting
 */
const fieldMappings = [
  {
    value: 'count',
    label: 'count',
    labelFormat: 'percent',
  },
  {
    value: 'count',
    label: 'count',
    labelFormat: 'percent',
  },
];

const tableLabelForLoading = 'Outbound Report';
const tableKeys = ['applied', 'screening', 'assessment', 'offer', 'hired'];
const tableLabels = ['Outbound Report'];
const metricsToUse = ['Prospecting', 'Agencies'];
const displayOptions = {
  passthroughPercentages: {
    showTotals: true,
    showRows: true,
  },
};

const getOutboundMetrics = (data) => {
  const metrics = {};

  data.forEach((instance) => {
    if (metricsToUse.includes(instance.label)) {
      Object.keys(instance.all).forEach((metricKey) => {
        const snakeCaseKey = snakeCase(`${instance.label}_${metricKey}`);

        metrics[snakeCaseKey] = roundFloat(
          instance.all[metricKey].percentage * 100,
        );
      });
    }
  });

  return metrics;
};

const getTablesWithMetrics = (tables, data) => {
  const tablesWithMetrics = {};

  Object.keys(tables).forEach((key) => {
    tablesWithMetrics[key] = {
      ...tables[key],
      metrics: getOutboundMetrics(data),
    };
  });

  return tablesWithMetrics;
};

const getOutboundTables = (tableData) => {
  const tables = getTables(tableData, { tableKeys, tableLabels, calcTotals: true });
  const tablesWithMetrics = getTablesWithMetrics(tables, tableData);
  const tablesWithPassthroughPercentages = calculatePassthroughPercentages(
    tablesWithMetrics,
    displayOptions.passthroughPercentages,
  );

  return tablesWithPassthroughPercentages;
};

/**
 * getOutboundReportData decouples the totals data at index `0`
 * and then handles failed and successful requests. It generates the widget for successful requests
 * @param results
 * @param results.chartData
 * @param results.tableData
 * @returns {object} { widgets, totals }
 */
export const getOutboundReportData = ({ chartData, tableData }) => {
  const widgets = getWidgets(chartData, { defaultWidgetConfig, fieldMappings, aggregatedOtherLabel: 'Other Outbound Sources' });
  const tables = getOutboundTables(tableData);
  const labelFormats = fieldMappings.map(({ labelFormat }) => labelFormat);

  return {
    widgets, totals: {}, labelFormats, tables, displayOptions,
  };
};

export const getOutboundReportLabelsForLoading = getWidgetAndTableLabels(
  defaultWidgetConfig,
  tableLabelForLoading,
);
