import { defineStore, storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import { useAppLoading } from '@/stores/app/loading';
import { useAppRoute } from '@/stores/app/route';
import { format } from 'date-fns';
import { getQuarter } from '@/helpers/date';
import { removeEmptyParams } from '@/stores/filters/helpers';
import { isEqual } from 'lodash-es';
import { getExecutiveInsights } from '@/api/insights';
import { useFilters } from '@/stores/filters/filters';

export const useExecutiveInsights = defineStore('executiveInsights', () => {
  const applicationsReceived = ref(0);
  const overallPassthroughRate = ref(0);
  const hiresMade = ref(0);
  const timeToHire = ref(0);
  const offerAcceptanceRate = ref(0);
  const dropOffRate = ref(0);
  const genderDistribution = ref({
    femaleAppsPercent: 0,
    maleAppsPercent: 0,
    undeterminedAppsPercent: 0,
    femaleHiredPercent: 0,
    maleHiredPercent: 0,
    undeterminedHiredPercent: 0,
  });
  const sourceDistribution = ref({
    internalAppsPercent: 0,
    internalHiredPercent: 0,
    referralAppsPercent: 0,
    referralHiredPercent: 0,
    outboundAppsPercent: 0,
    outboundHiredPercent: 0,
    inboundAppsPercent: 0,
    inboundHiredPercent: 0,
    otherAppsPercent: 0,
    otherHiredPercent: 0,
  });
  const flags = ref({});
  const labelsForLoading = ref({});

  const isExecutiveInsightsEmpty = computed(() => applicationsReceived.value === 0
    && overallPassthroughRate.value === 0
    && hiresMade.value === 0
    && timeToHire.value === 0
    && offerAcceptanceRate.value === 0
    && dropOffRate.value === 0
    && Object.values(genderDistribution.value).every((value) => value === 0)
    && Object.values(sourceDistribution.value).every((value) => value === 0));

  const setData = ({
    total_applications: newApplicationsReceived,
    overall_passthrough: newOverallPassthroughRate,
    number_of_hires: newHiresMade,
    median_hire_time: newTimeToHire,
    avg_acceptance_rate: newOfferAcceptanceRate,
    drop_off_avg: newDropOffRate,
    female_apps_percentage: newFemaleAppsPercent,
    male_apps_percentage: newMaleAppsPercent,
    undetermined_apps_percentage: newUndeterminedAppsPercent,
    female_hired_percentage: newFemaleHiredPercent,
    male_hired_percentage: newMaleHiredPercent,
    undetermined_hired_percentage: newUndeterminedHiredPercent,
    internal_apps_percentage: newInternalAppsPercent,
    internal_hired_percentage: newInternalHiredPercent,
    referral_apps_percentage: newReferralAppsPercent,
    referral_hired_percentage: newReferralHiredPercent,
    outbound_apps_percentage: newOutboundAppsPercent,
    outbound_hired_percentage: newOutboundHiredPercent,
    inbound_apps_percentage: newInboundAppsPercent,
    inbound_hired_percentage: newInboundHiredPercent,
    other_apps_percentage: newOtherAppsPercent,
    other_hired_percentage: newOtherHiredPercent,
  } = {}) => {
    applicationsReceived.value = newApplicationsReceived;
    overallPassthroughRate.value = newOverallPassthroughRate;
    hiresMade.value = newHiresMade;
    timeToHire.value = newTimeToHire;
    offerAcceptanceRate.value = newOfferAcceptanceRate;
    dropOffRate.value = newDropOffRate;
    genderDistribution.value = {
      femaleAppsPercent: newFemaleAppsPercent,
      maleAppsPercent: newMaleAppsPercent,
      undeterminedAppsPercent: newUndeterminedAppsPercent,
      femaleHiredPercent: newFemaleHiredPercent,
      maleHiredPercent: newMaleHiredPercent,
      undeterminedHiredPercent: newUndeterminedHiredPercent,
    };
    sourceDistribution.value = {
      internalAppsPercent: newInternalAppsPercent,
      internalHiredPercent: newInternalHiredPercent,
      referralAppsPercent: newReferralAppsPercent,
      referralHiredPercent: newReferralHiredPercent,
      outboundAppsPercent: newOutboundAppsPercent,
      outboundHiredPercent: newOutboundHiredPercent,
      inboundAppsPercent: newInboundAppsPercent,
      inboundHiredPercent: newInboundHiredPercent,
      otherAppsPercent: newOtherAppsPercent,
      otherHiredPercent: newOtherHiredPercent,
    };
  };

  const setFlags = (newFlags) => {
    flags.value = newFlags;
  };

  const setLabelsForLoading = (value) => {
    labelsForLoading.value = value;
  };

  const loadExecutiveInsights = async ({
    selectedDateRange,
    routeQuery,
    router,
    selectedFilters,
    selectedMyJobs,
  }) => {
    const routeStore = useAppRoute();
    const { route } = storeToRefs(routeStore);
    const lastQuarter = getQuarter(1);
    const fallbackDateRange = selectedDateRange || { from: format(lastQuarter[0], 'yyyy-MM-dd'), to: format(lastQuarter[1], 'yyyy-MM-dd') };
    const date = routeQuery?.from && routeQuery?.to
      ? { from: routeQuery.from, to: routeQuery.to }
      : fallbackDateRange;

    const skeletonLoadersTimeout = setTimeout(() => {
      setLabelsForLoading({
        // NOTE: in exec insights widgetLabels is an object, other insights is an array
        widgetLabels: {
          'time-to-hire': 'Time to Hire vs Benchmark',
          'offer-acceptance': 'Offer Acceptance Rate vs Benchmark',
          'drop-off': 'Drop-off Rate vs Benchmark',
          'gender-distribution': 'What is the gender distribution of our candidates?',
          'source-distribution': 'What are our best sources?',
        },
        tableLabel: '',
      });
      // add intermediate loading state to filter bar
      useAppLoading().setLoading({ halfTopBarLoading: false });
    }, 4000);

    useAppLoading().setLoading({
      pageLoading: true, filtersLoading: true, halfTopBarLoading: true,
    });

    const {
      data: newData = {},
      filters = [],
      owned = {},
      dateRange,
      flags: newFlags = {},
    } = await getExecutiveInsights({
      selectedDateRange: date, routeQuery, filters: selectedFilters, owned: selectedMyJobs,
    });

    clearTimeout(skeletonLoadersTimeout);
    setLabelsForLoading({});

    /* Send relevant updates to filters */
    useFilters().updateFilters({
      dateRange,
      filters,
      myJobsEnabled: owned,
    });

    setData(newData);
    setFlags(newFlags);

    const prunedRootStateQuery = removeEmptyParams(route?.query);
    const prunedRouteQuery = removeEmptyParams(routeQuery);
    const dateAvailable = !prunedRootStateQuery?.to
        && !prunedRootStateQuery?.from
        && !prunedRouteQuery?.to
        && !prunedRouteQuery?.from
        && dateRange?.from
        && dateRange?.to;

    // Checks if the query changed or if there are selected date range and no query
    if (
      router
        && (!isEqual(prunedRootStateQuery, prunedRouteQuery)
          || !isEqual(prunedRouteQuery, { from: dateRange.from, to: dateRange.to })
          || dateAvailable
        )
    ) {
      const { mergedTempFilters } = storeToRefs(useFilters());

      const newQuery = {
        ...prunedRouteQuery,
        ...mergedTempFilters.value,
        from: dateRange?.from,
        to: dateRange?.to,
      };

      if (selectedMyJobs) {
        newQuery.owned = selectedMyJobs;
      }

      router.push({
        query: newQuery,
      }).catch(() => true); // ignore duplicate navigation error due to global filter selection
    }
  };

  return {
    // State
    applicationsReceived,
    overallPassthroughRate,
    hiresMade,
    timeToHire,
    offerAcceptanceRate,
    dropOffRate,
    genderDistribution,
    sourceDistribution,
    flags,
    labelsForLoading,
    // Getters
    isExecutiveInsightsEmpty,
    // Actions
    setData,
    setFlags,
    loadExecutiveInsights,
  };
});
