import { roundFloat } from '@/helpers/formatters';
import theme from '@/configs/vuetify';
import { getValues } from './transformers';
import { getWidgetAndTableLabels } from './common';

const {
  primaryColor, primaryColorLighten90, caribbeanGreen, caribbeanGreenLighten80, pacificBlue,
} = theme.theme.themes.light.colors;

/** defaultWidgetConfig manages the frontend config for each api widget */
const defaultWidgetConfig = [
  {
    id: 'requisition-success-by-time',
    itemLabel: 'Requisition Success',
    groupLabel: 'by Time',
    labels: [],
    values: [],
    type: 'lineChart',
    size: 'large',
    colors: [
      primaryColor,
      primaryColorLighten90,
    ],
    isTimestamp: true,
  },
  {
    id: 'offer-success-by-time',
    itemLabel: 'Offer Success',
    groupLabel: 'by Time',
    labels: [],
    values: [],
    type: 'lineChart',
    size: 'large',
    colors: [
      caribbeanGreen,
      caribbeanGreenLighten80,
    ],
    isTimestamp: true,
  },
  {
    id: 'time-to-fill',
    itemLabel: 'Time-to-Fill',
    groupLabel: '',
    labels: [],
    values: [],
    type: 'horizontalBarChart',
    colors: [
      primaryColor,
    ],
    isTimestamp: true,
  },
  {
    id: 'time-to-hire',
    itemLabel: 'Time-to-Hire',
    groupLabel: '',
    labels: [],
    values: [],
    type: 'horizontalBarChart',
    colors: [
      pacificBlue,
    ],
    isTimestamp: true,
  },
];

/**
 * fieldMappings is used to map the index of a widget from `defaultWidgetConfig`
 * to which values it will need to pull off the api object, labels, and formatting
 */
const fieldMappings = [
  {
    value: 'reqs_success',
    label: 'date',
    labelFormat: 'timestamp',
  },
  {
    value: 'offer_success',
    label: 'date',
    labelFormat: 'timestamp',
  },
  {
    value: 'avg_ttf',
    label: 'date',
    labelFormat: 'timestamp',
  },
  {
    value: 'median_tth_days',
    label: 'date',
    labelFormat: 'timestamp',
  },
];

/**
 * widgetApiDataIndex is used to map the index of a widget from `defaultWidgetConfig`
 * to the api data definition `dashboardRequests` defined in `src/api/helpers/insights/insights`
 */
const widgetApiDataIndex = [
  0,
  1,
  2,
  3,
];

/**
 * getRequisitionEfficiencyTotals maps what fields the frontend needs for totals to the api dataset
 *
 * @param totals
 * @returns {object}
 */
const getRequisitionEfficiencyTotals = (totals) => {
  const data = totals[0];

  if (data) {
    const reqSuccess = roundFloat(data.req_success_pct, { isPercent: true });
    const offerSuccess = roundFloat(data.offer_hired_pct, { isPercent: true });
    const timeToFill = roundFloat(data.median_days_to_first_hire);
    const timeToHire = roundFloat(data.median_application_to_hire_days);

    return {
      time_to_fill: timeToFill,
      time_to_hire: timeToHire,
      requisition_success: `${reqSuccess}%`,
      offer_success: `${offerSuccess}%`,
    };
  }

  return {};
};

/**
 * getRequisitionEfficiencyData decouples the totals data at index `0`
 * and then handles failed and successful requests. It generates the widget for successful requests
 *
 * @param results
 * @returns {object} { widgets, totals }
 */
export const getRequisitionEfficiencyData = (results) => {
  const totals = results[0].status === 'fulfilled' ? getRequisitionEfficiencyTotals(results[0].value) : {};
  results.shift();

  const widgets = widgetApiDataIndex.map((apiDataIndex, i) => {
    const result = results[apiDataIndex];
    const defaultConfig = defaultWidgetConfig[i];
    const isValidData = Array.isArray(result.value) ? false : Object.keys(result.value).length > 0;

    if (result.status === 'fulfilled' && isValidData) {
      const fields = fieldMappings[i];
      const { labels, datasets } = result.value;
      const isTimestamp = fields.labelFormat.startsWith('timestamp');

      return {
        ...defaultConfig,
        isTimestamp,
        labels,
        values: getValues(datasets, fields, labels, defaultConfig.type, isTimestamp),
      };
    }

    return { ...defaultConfig };
  });

  const labelFormats = fieldMappings.map(({ labelFormat }) => labelFormat);

  return { widgets, totals, labelFormats };
};

export const getRequisitionEfficiencyLabelsForLoading = getWidgetAndTableLabels(
  defaultWidgetConfig,
);
