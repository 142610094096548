import { roundFloat } from '@/helpers/formatters';
import { format } from 'date-fns';
import theme from '@/configs/vuetify';
import { getValues } from './transformers';
import { getWidgetAndTableLabels } from './common';

const {
  primaryColor, pacificBlue, violetPurple, newOrange, zirconGrey, pacificBlueLighten60,
} = theme.theme.themes.light.colors;

/** defaultWidgetConfig manages the frontend config for each api widget */
const defaultWidgetConfig = [
  {
    id: 'proportionalized-median-candidate-pool-by-time',
    itemLabel: 'Proportionalized Median Candidate Pools',
    groupLabel: 'by Time',
    labels: [],
    values: [],
    type: 'verticalStackedBarChart',
    size: 'large',
    colors: [
      primaryColor,
      pacificBlue,
      violetPurple,
      newOrange,
      zirconGrey,
    ],
    isTimestamp: true,
    config: {
      titleFormatter: (title) => format(new Date(`${title} 00:00`), 'MM/dd/yyyy'),
    },
  },
  {
    id: 'applicants-by-gender',
    itemLabel: 'Applicants',
    groupLabel: 'by Gender',
    labels: [],
    values: [],
    type: 'verticalBarChart',
    size: 'large',
    colors: [pacificBlue, pacificBlueLighten60, zirconGrey],
    colorPerDataset: true,
    isTimestamp: true,
  },

];

/**
 * fieldMappings is used to map the index of a widget from `defaultWidgetConfig`
 * to which values it will need to pull off the api object, labels, and formatting
 */
const fieldMappings = [
  {
    value: [['median_organic', 'median_referrals', 'median_sourcing', 'median_agencies', 'median_other']],
    label: 'date',
    labelFormat: 'timestamp',
    datasetLabels: [
      'Organic',
      'Referrals',
      'Sourcing',
      'Agencies',
      'Other',
    ],
  },
  {
    value: ['count_male', 'count_female', 'count_unknown'],
    label: 'date',
    labelFormat: 'timestamp',
    datasetLabels: [
      'Male',
      'Female',
      'Unknown',
    ],
  },
];

/**
 * widgetApiDataIndex is used to map the index of a widget from `defaultWidgetConfig`
 * to the api data definition `dashboardRequests` defined in `src/api/helpers/insights/insights`
 */
const widgetApiDataIndex = [
  0,
  1,
];

/**
 * getCandidatePoolMetricsTotals maps what fields the frontend needs for totals to the api dataset
 *
 * @param totals
 * @returns {object}
 */
const getCandidatePoolMetricsTotals = (totals) => {
  const data = totals[0];

  if (data) {
    const appsPerReq = roundFloat(data.median_applications_per_req);
    const appsAtPhoneScreen = roundFloat(data.median_applications_at_screening);
    const appsAtInterview = roundFloat(data.median_applications_at_interviewing);
    const pctDropOffsPerReq = roundFloat(
      data.median_applications_dropoff_all_pct,
      { isPercent: true },
    );

    return {
      median_applicants_per_requisition: appsPerReq,
      median_applicants_at_phone_screen: appsAtPhoneScreen,
      median_applicants_at_interview: appsAtInterview,
      median_percent_drop_offs_per_requisition: `${pctDropOffsPerReq}%`,
    };
  }

  return {};
};

/**
 * getCandidatePoolMetricsData decouples the totals data at index `0`
 * and then handles failed and successful requests. It generates the widget for successful requests
 *
 * @param results
 * @returns {object} { widgets, totals }
 */
export const getCandidatePoolMetricsData = (results) => {
  const totals = results[0].status === 'fulfilled' ? getCandidatePoolMetricsTotals(results[0].value) : {};

  results.shift();

  const widgets = widgetApiDataIndex.map((apiDataIndex, i) => {
    const result = results[apiDataIndex];
    const defaultConfig = defaultWidgetConfig[i];
    const isValidData = Array.isArray(result.value) ? false : Object.keys(result.value).length > 0;

    if (result.status === 'fulfilled' && isValidData) {
      const fields = fieldMappings[i];
      const { labels, datasets } = result.value;
      const isTimestamp = fields.labelFormat.startsWith('timestamp');

      return {
        ...defaultConfig,
        isTimestamp,
        labels,
        values: getValues(datasets, fields, labels, defaultConfig.type, isTimestamp),
      };
    }

    return { ...defaultConfig };
  });

  const labelFormats = fieldMappings.map(({ labelFormat }) => labelFormat);

  return { widgets, totals, labelFormats };
};

export const getCandidatePoolMetricsLabelsForLoading = getWidgetAndTableLabels(defaultWidgetConfig);
