import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useAppPagination = defineStore('appPagination', () => {
  const page = ref(1);
  const totalPages = ref(1);

  const setPageNumber = (pageNumber) => {
    page.value = pageNumber;
  };

  const updatePagination = (data) => {
    page.value = data.page;
    totalPages.value = data.totalPages;
  };

  return {
    page, totalPages, setPageNumber, updatePagination,
  };
});
