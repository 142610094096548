import { defineStore } from 'pinia';
import { vueHubSpotToken } from '@/api/vue';

const TOKEN_EXPIRE_TIMEOUT = 1000 * 60 * 60 * 12; // 12 hours

export const useAppThirdPartyPlugins = defineStore('appThirdPartyPlugins', {
  state: () => ({
    beamerIsActive: false,
    beamer: {},
    fullstory: {},
    googleAnalytics: {},
    mixpanel: {},
    helpscoutSiteUrl: '',
    hubSpot: {
      email: null,
      token: null,
      timestamp: 0,
    },
    user: {},
  }),

  actions: {
    setThirdPartyConfigs(configs) {
      this.beamer = configs.beamer;
      this.fullstory = configs.fullstory;
      this.googleAnalytics = configs.googleAnalytics;
      this.mixpanel = configs.mixpanel;
      this.helpscoutSiteUrl = configs.helpscoutSiteUrl;
      this.user = configs.user;
    },

    setBeamer(boolean) {
      this.beamerIsActive = boolean;
    },

    /* ==============
      HUBSPOT
    ================= */

    initHubSpot() {
      if (window.navigator.userAgent.endsWith('AWS-Canary-Noscript')) return;
      window.hsConversationsSettings = { loadImmediately: false };

      const tag = document.createElement('script');
      tag.setAttribute('src', '//js.hs-scripts.com/20222851.js');
      tag.setAttribute('async', true);
      tag.setAttribute('defer', true);
      tag.setAttribute('type', 'text/javascript');
      document.head.appendChild(tag);

      this.loadHubSpotChat();

      window.setInterval(() => {
        this.checkExpiration();
      }, 1000 * 60);
    },

    async loadHubSpotChat({ reset } = {}) {
      this.hubSpot = { email: null, token: null, timestamp: Date.now() };

      const widgetLoad = () => {
        if (reset) {
          window.HubSpotConversations.resetAndReloadWidget();
        }
        window.HubSpotConversations.widget.load();
      };

      try {
        const { email, token } = await vueHubSpotToken();

        if (token && email && window.hsConversationsSettings) {
          this.hubSpot = { email, token, timestamp: Date.now() };
          window.hsConversationsSettings.identificationEmail = email;
          window.hsConversationsSettings.identificationToken = token;
        }
      } finally {
        if (window.HubSpotConversations) {
          widgetLoad();
        } else {
          // hubspot script may not be ready yet
          const interval = window.setInterval(() => {
            if (window.HubSpotConversations) {
              widgetLoad();
              window.clearInterval(interval);
            }
          }, 1000);
        }
      }
    },

    checkExpiration() {
      if (Date.now() > this.hubSpot.timestamp + TOKEN_EXPIRE_TIMEOUT) {
        this.loadHubSpotChat();
      }
    },

    initBeacon() {
      if (window.navigator.userAgent.endsWith('AWS-Canary-Noscript')) return;

      const pushToQueue = (method, options, data) => {
        window.Beacon.readyQueue.push({ method, options, data });
      };

      window.Beacon = window.Beacon || pushToQueue;
      window.Beacon.readyQueue = [];

      const tag = document.createElement('script');
      tag.setAttribute('type', 'text/javascript');
      tag.setAttribute('src', 'https://beacon-v2.helpscout.net');
      tag.setAttribute('async', true);
      document.head.appendChild(tag);

      window.Beacon('init', '3cce4214-8caf-4aa1-a25f-05e695617f7c');
    },
  },
});
