import { defineStore } from 'pinia';
import { ref } from 'vue';
import mixpanel from 'mixpanel-browser';
import { capitalize } from 'lodash-es';
import { intervalToDuration } from 'date-fns';
import { isNotTestAccount } from '@/helpers/thirdParty/verifyTestAccount';
import { useAppAuthData } from '@/stores/app/authData';
import { useAppRoute } from '@/stores/app/route';

export const useTracking = defineStore('tracking', () => {
  // state
  const sessionPage = ref(null);
  const sessionStart = ref(null);

  // actions

  /* ===========================
  General Tracking
  =========================== */

  const track = ({ name, params }) => {
    const {
      atsId, userId, integrationType, role,
    } = useAppAuthData().authData;
    const customParams = {
      ...params,
      'ATS Id': atsId,
      Integration: integrationType,
      role,
    };
    try {
    /* Don't track if user is engineeringuptimemetrics (AWS Canary tests that run every 5 mins) */
      if (window && isNotTestAccount(userId)) {
        mixpanel.track(name, customParams);
      }
    // eslint-disable-next-line no-empty
    } catch (ignore) {}
  };

  const trackName = ({ name }) => track({ name });

  const trackJob = ({ name, job, props }) => {
    const {
      jobId, company, title, location, score,
    } = job;

    track({
      name,
      params: {
        Id: Number(jobId),
        Company: company,
        'Job Title': title,
        'Job Location': location,
        Score: score,
        ...props,
      },
    });
  };

  const trackPageLoad = ({ page }) => trackName({ name: `Landed on Datapeople ${page} Page` });

  /* ===========================
  Session Duration
  =========================== */

  const handleSession = (page) => {
    if (sessionPage.value !== page) {
      if (!sessionStart.value) {
        sessionPage.value = page;
        sessionStart.value = Date.now();
      } else {
      /* If start already exists, trigger an end event, and set a new start */
        const end = Date.now();
        const { hours, minutes, seconds } = intervalToDuration({
          start: sessionStart.value,
          end,
        });
        track({
          name: `Session duration: ${sessionPage.value}`,
          params: { hours, minutes, seconds },
        });
        sessionPage.value = page;
        sessionStart.value = Date.now();
      }
    }
  };

  const onUpdateRoute = ({ route }) => {
    [
      'settings',
      'published',
      'unpublished',
      'email',
      'draft',
      'reporting',
      'analytics',

    ].forEach((page) => {
      if (route.path.includes(page)) {
        handleSession(route.name);
      }
    });
  };

  useAppRoute().$subscribe((mutation, state) => {
    onUpdateRoute(state);
  }, { flush: 'sync' });

  const login = () => trackName({ name: 'Logged in' });

  /* ===========================
  App Navbar
  =========================== */

  const search = () => trackName({ name: 'performed search using nav searchbar' });

  const navLinkClick = ({ label }) => trackName({ name: `clicked ${label} link in navbar` });

  const navDropdownHover = ({ label }) => trackName({ name: `hovered over ${label} dropdown in navbar` });

  /* ===========================
  Breadcrumbs navigation
  ============================ */
  const breadCrumbLinkClick = ({ label }) => trackName({ name: `Clicked ${label} link in Breadcrumbs` });

  /* ===========================
  Library
  =========================== */

  const librarySidebarClick = ({ linkLabel }) => trackName({
    name: `clicked '${capitalize(linkLabel)}' in Library sidebar`,
  });

  const libraryReqReportLinkClick = ({ req_uuid: reqUUid, id, title }) => track({
    name: 'clicked Req Report link in Post Library',
    params: { ReqId: reqUUid, JobId: id, JobTitle: title },
  });

  /* ===========================
  Filterbar
  =========================== */

  const filterApply = ({ filters, page }) => {
    filters.forEach((filter) => {
      const filterType = [
        'Department', 'Users', 'Seniority', 'Location', 'Job Family', 'Status', 'Role',
      ].includes(filter) ? filter : 'Custom';
      trackName({ name: `Applied '${filterType}' filters on ${capitalize(page)} page` });
    });
  };

  const filterClear = ({ filter, page }) => {
    const filterType = [
      'Department', 'Users', 'Seniority', 'Location', 'Job Family', 'Status', 'Role', 'all',
    ].includes(filter) ? filter : 'Custom';
    trackName({ name: `Cleared '${filterType}' filters on ${capitalize(page)} page` });
  };

  const filterSubMenuClick = ({ option }) => trackName({ name: `Selected '${option}' in filter submenu` });

  const filterMyJobsClick = ({ enable, page }) => trackName({
    name: `${enable ? 'Selected' : 'Unselected'} 'My Jobs' filter on ${capitalize(page)} page`,
  });

  const filterDateRange = ({ page, dates }) => {
    track({
      name: `Filtered by date on ${capitalize(page)} page`,
      params: {
        To: dates.to,
        From: dates.from,
      },
    });
  };

  /* ===========================
  New Events Filters
  =========================== */
  const appliedFilters = ({ page, filterTypes, filterName = '' }) => {
    filterTypes.forEach((filter) => {
      const filterType = [
        'Department', 'Users', 'Seniority', 'Location', 'Job Family', 'Status', 'Role', 'My Jobs',
      ].includes(filter) ? filter : 'Custom';
      track({
        name: 'Applied Filters',
        params: { $page: page, $filter_type: filterType, $filter_name: filterName },
      });
    });
  };

  /* ===========================
  Analytics
  =========================== */

  const downloadClick = ({ type, page }) => trackName({ name: `Reporting/${capitalize(page)}: Clicked ${type} download` });

  const passthroughCalcUpdate = () => trackName({ name: 'Reporting/Pass-through: Changed Pass-through calc number' });

  const inboundTableToggle = ({ label }) => trackName({ name: `Reporting/Inbound-funnel: Toggled to ${label} chart` });

  const lineChartHover = () => trackName({ name: 'Reporting/Gender-report: Hovered over data in Gender Representation by Stage chart' });

  /* ===========================
  Teams
  =========================== */

  const allMembersSelected = ({ selected }) => {
    const action = selected.value ? '\'Select All\'' : '\'Deselect All\'';
    trackName({ name: `Teams page: ${action} clicked` });
  };

  const singleInviteSent = ({ id }) => track({
    name: 'Teams page: Send single invite button clicked',
    params: { invitedUserId: id },
  });

  const singleDatapeopleAnywhereInviteSent = ({ id }) => track({
    name: 'Teams page: Datapeople Anywhere Invite Clicked',
    params: { invitedUserId: id },
  });

  const massInviteSent = ({ selectedCount }) => track({
    name: 'Teams page: Send mass invite button clicked',
    params: { membersInvited: selectedCount },
  });

  const teamsViewUpdated = (event) => {
    const updateOpt = Object.keys(event).find((key) => [
      'searchTerm', 'selectedSortOption', 'page',
    ].includes(key));

    const type = {
      selectedSortOption: 'sort',
      searchTerm: 'search',
      page: 'pagination',
    }[updateOpt];

    let info;
    if (type === 'search') {
      info = event[updateOpt] === '' ? 'clear search' : event[updateOpt];
    }
    if (type === 'pagination') {
      info = event[updateOpt];
    }
    if (type === 'sort') {
      info = event.descending ? `${event[updateOpt]} descending` : `${event[updateOpt]} ascending`;
      trackName({ name: `Teams page: Update Teams view using '${type}: ${info}'` });
    } else {
      const key = (type === 'search') ? 'searchTerm' : 'page';
      track({
        name: `Teams page: Update Teams view using ${type}`,
        params: { [key]: info },
      });
    }
  };

  /* ===========================
  Editor
  =========================== */

  const editorPage = ({ id }) => track({ name: 'landed on single job page', params: { Id: Number(id) } });

  const editorLoaded = ({ job, props: { duration } }) => trackJob({
    name: 'job page loaded',
    job,
    props: { Duration: duration },
  });

  const editorRescore = ({ job, props: { duration } }) => trackJob({
    name: 'Revision Scored',
    job,
    props: { Duration: duration },
  });

  const editorAction = ({ action }) => trackName({ name: `${action} in editor` });

  /* ===========================
  Editor v5
  ============================ */

  const editorToolbarClick = ({ id }) => {
    const buttonIdToTrackMessage = {
      bold: 'Clicked Format Text: Bold',
      italic: 'Clicked Format Text: Italic',
      link: 'Clicked Format Text: URL',
      clear: 'Clicked Format Text: Remove Formatting',
      undo: 'Clicked Undo',
      redo: 'Clicked Redo',
      indent: 'Clicked Indent List',
      unindent: 'Clicked Unindent List',
      list: 'Clicked Format Text: List',
      paragraph: 'Clicked Format Text: Paragraph',
      heading: 'Clicked Format Text: Header',
      h1: 'Clicked Header Type: H1',
      h2: 'Clicked Header Type: H2',
      h3: 'Clicked Header Type: H3',
      orderedList: 'Clicked Ordered List',
      bulletList: 'Clicked Bullet List',
    };

    const message = buttonIdToTrackMessage[id] || `Clicked toolbar button: ${id}`;
    trackName({ name: message });
  };

  const editorViewReqReportClicked = (reqReportUrl) => track({ name: 'Editor: Clicked View Req Report', params: { reqUrl: reqReportUrl } });

  /* ===========================
  Editor Submenu
  =========================== */

  const editorLinkClick = ({ label }) => trackName({ name: `clicked ${label} link in editor submenu` });

  const editorDropdownHover = ({ label }) => trackName({
    name: `hovered over ${label} dropdown in editor submenu`,
  });

  const hoveredJob = ({ job }) => trackJob({ name: 'HoveredJobReport', job });

  const jobReportClick = ({ job }) => trackJob({ name: 'Clicked on Single Job Report', job });

  /* ===========================
  Apply Template feature
  =========================== */

  const expandedTemplateOptions = () => trackName({ name: 'expanded template options in modal' });

  const applyTemplateModalClick = ({ id, revisionId }) => track({
    name: 'clicked Apply Template button in modal',
    params: {
      Id: Number(id),
      RevisionId: Number(revisionId),
    },
  });

  const revertRevision = ({ id, revisionId }) => track({
    name: 'clicked cancel on template revision page',
    params: {
      Id: Number(id),
      RevisionId: Number(revisionId),
    },
  });

  const acceptRevision = ({ id, revisionId }) => track({
    name: 'clicked apply on template revision page',
    params: {
      Id: Number(id),
      RevisionId: Number(revisionId),
    },
  });

  /* ===========================
  Side-By-Side Editor
  =========================== */

  const sideBySidePage = ({ id, revisionId, type }) => track({
    name: `landed on side-by-side ${type} page`,
    params: {
      Id: Number(id),
      RevisionId: Number(revisionId),
    },
  });

  const sideBySideCopyButtonClicked = ({ id, revisionId, type }) => track({
    name: `clicked copy to clipboard button on side-by-side ${type} page`,
    params: {
      Id: Number(id),
      RevisionId: Number(revisionId),
    },
  });

  const sideBySideCopyKeyboard = ({
    id, revisionId, revisionColumn, type,
  }) => track({
    name: `used keyboard shortcut to copy to clipboard on side-by-side ${type} page`,
    params: {
      Id: Number(id),
      RevisionId: Number(revisionId),
      RevisionColumn: revisionColumn,
    },
  });

  const sideBySidePaste = ({
    id, revisionId, revisionColumn, type,
  }) => track({
    name: `pasted content on side-by-side ${type} page`,
    params: {
      Id: Number(id),
      RevisionId: Number(revisionId),
      RevisionColumn: revisionColumn,
    },
  });

  /* ===========================
  Clipboard Tracking
  =========================== */

  const copyAllFields = ({ job }) => trackJob({ name: 'Copy All Clicked', job });

  const copyTitle = ({ job }) => trackJob({ name: 'Copy Title Clicked', job });

  const copyContent = ({ job, props: { field } }) => trackJob({
    name: 'Copy Content Clicked',
    job,
    props: { Field: field },
  });

  /* ===========================
  Revision History
  =========================== */

  const viewJobRevisions = ({ job }) => trackJob({ name: 'Viewed Revision History', job });

  const clickJobRevision = ({ job, props: { revisionId } }) => trackJob({
    name: 'Clicked on historical revision',
    job,
    props: { 'Revision Id': revisionId },
  });

  const viewJobRevisionsInModal = ({ job, props: { revisionId } }) => trackJob({
    name: 'Viewed Revision History in revision modal',
    job,
    props: { 'Revision Id': revisionId },
  });

  const clickJobRevisionInModal = ({
    job, props: { revisionId, newRevision },
  }) => trackJob({
    name: 'Clicked on historical revision in revision modal',
    job,
    props: {
      'Revision Id': revisionId,
      'New Revision Id': newRevision,
    },
  });

  const notify = ({ job, props: { name, type } }) => trackJob({
    name: 'Notify',
    job,
    props: {
      Name: name,
      Type: type,
    },
  });

  const shareJob = ({ job, props: { firstName, lastName, email } }) => trackJob({
    name: 'Job Shared',
    job,
    props: {
      'First Name': firstName,
      'Last Name': lastName,
      Email: email,
    },
  });

  /* ===========================
  Similar Jobs
  =========================== */

  const viewSimilarJobs = ({ job }) => trackJob({ name: 'Viewed Similar Jobs', job });

  const clickSimilarJob = ({ job, props: { id, title, company } }) => trackJob({
    name: 'Clicked on Similar Job',
    job,
    props: {
      'Similar Id': id,
      'Similar Title': title,
      'Similar Company': company,
    },
  });

  /* ===========================
  Sync Job
  =========================== */

  const syncToAts = ({ job, props: { atsName, integrationTypeKey } }) => trackJob({
    name: 'Sync to ATS Button Clicked',
    job,
    props: {
      Integration: integrationTypeKey,
      'Ats Name': atsName,
    },
  });

  const syncModalOpen = ({ job, props: { atsName, integrationTypeKey } }) => trackJob({
    name: 'Sync Modal Opened',
    job,
    props: {
      Integration: integrationTypeKey,
      'Ats Name': atsName,
    },
  });

  /* ===========================
  Highlights
  =========================== */

  const highlightActionTitle = {
    replace: 'Replace',
    delete: 'Delete',
    warn: 'Warn',
    replace_or_delete: 'Replace or delete',
    insert_before_hl: 'Insert',
    insert_after_hl: 'Insert',
  };

  const viewHighlight = ({ job, props }) => {
    const {
      action, issue, ruleId, description, tip,
    } = props;

    trackJob({
      name: 'Viewed Actionable Highlight',
      job,
      props: {
        'Action Type': highlightActionTitle[action] || action,
        Issue: issue,
        'Rule ID': ruleId,
        Description: description,
        Tip: tip,
      },
    });
  };

  const applyHighlight = ({
    job, props: {
      action, replacement, removedText, color,
    },
  }) => trackJob({
    name: 'Applied Actionable Highlight',
    job,
    props: {
      'Action Type': highlightActionTitle[action] || action,
      'Inserted Text': replacement,
      'Removed Text': removedText,
      Color: color,
    },
  });

  const viewNudge = ({ job, props }) => trackJob({
    name: 'Viewed Nudge', job, props,
  });

  const applyNudge = ({ job, props }) => trackJob({
    name: 'Applied Nudge', job, props,
  });

  const readMoreLinkClicked = ({
    job, props: {
      link, action, replacement, removedText,
    },
  }) => trackJob({
    name: 'Read More Link Clicked',
    job,
    props: {
      Link: link,
      'Action Type': highlightActionTitle[action] || action,
      'Inserted Text': replacement,
      'Removed Text': removedText,
    },
  });

  /* ===========================
  Insights
  =========================== */

  const insightsButtonClick = ({ type, page }) => trackName({ name: `Insights/${capitalize(page)}: Clicked ${type} button` });

  const insightsShareButtonClick = ({ jobs, users, page }) => track({
    name: `Insights/${capitalize(page)}: Shared Selected Jobs`,
    params: { jobs, users },
  });

  const insightsShareModalUpdated = ({ type, page }) => trackName({ name: `Insights/${capitalize(page)}: Updated ${type} in Share Modal` });

  const insightsTrackWidgetLinkClick = (linkName, routeName) => trackName({ name: `Insights/Clicked ${linkName} link in ${routeName} widget` });

  const insightsSharePage = ({ page }) => trackName({ name: `Insights/${capitalize(page)} Shared` });

  /* ===========================
  Requisitions
  =========================== */

  const requisitionsButtonClick = ({ type, page }) => trackName({ name: `Requisitions/${capitalize(page)}: Clicked ${type} button` });

  const requisitionsShareButtonClick = ({ requisition, users, page }) => track({
    name: `Requisitions/${capitalize(page)}: Shared Selected Requisition`,
    params: { requisition, users },
  });

  const requisitionsShareModalUpdated = ({ type, page }) => trackName({ name: `Requisitions/${capitalize(page)}: Updated ${type} in Share Modal` });

  const requisitionOpenReqReport = (reqUuid) => track({ name: 'Requisition/Library: Opened Requisition Report', params: { Id: reqUuid } });

  const requisitionReportLanded = (reqUuid) => track({ name: 'Landed on Requisition Report', params: { Id: reqUuid } });

  const requisitionJobPostClicked = (jobId) => track({ name: 'Requisition Report: Clicked Job Post', params: { Id: jobId } });

  /* ===========================
  Home
  =========================== */

  const homeLogoClicked = () => trackName({ name: 'Clicked Homepage (Logo)' });

  const homeClick = (label) => trackName({ name: `Home/Clicked ${label}` });

  const homeHover = (label) => trackName({ name: `Home/Hovered over ${label}` });

  const homeLearnHover = ({ label, url }) => track({ name: `Home/Hovered over Learn article/video: ${label}`, params: { Url: url } });

  const homeLearnClick = ({ label, url }) => track({ name: `Home/Clicked Learn article/video: ${label}`, params: { Url: url } });

  return {
    // actions
    login,
    trackPageLoad,
    trackName,
    track,
    editorAction,
    editorDropdownHover,
    editorLinkClick,
    editorLoaded,
    editorPage,
    editorRescore,
    editorToolbarClick,
    editorViewReqReportClicked,
    hoveredJob,
    jobReportClick,
    applyHighlight,
    viewHighlight,
    viewNudge,
    applyNudge,
    syncModalOpen,
    syncToAts,
    shareJob,
    notify,
    clickSimilarJob,
    viewSimilarJobs,
    clickJobRevision,
    clickJobRevisionInModal,
    viewJobRevisions,
    viewJobRevisionsInModal,
    copyAllFields,
    copyContent,
    copyTitle,
    sideBySideCopyButtonClicked,
    sideBySideCopyKeyboard,
    sideBySidePage,
    sideBySidePaste,
    acceptRevision,
    applyTemplateModalClick,
    expandedTemplateOptions,
    revertRevision,
    search,
    navLinkClick,
    navDropdownHover,
    breadCrumbLinkClick,
    allMembersSelected,
    singleInviteSent,
    singleDatapeopleAnywhereInviteSent,
    massInviteSent,
    teamsViewUpdated,
    downloadClick,
    passthroughCalcUpdate,
    inboundTableToggle,
    lineChartHover,
    filterApply,
    filterClear,
    filterSubMenuClick,
    filterMyJobsClick,
    filterDateRange,
    librarySidebarClick,
    libraryReqReportLinkClick,
    readMoreLinkClicked,
    insightsButtonClick,
    insightsShareModalUpdated,
    insightsShareButtonClick,
    insightsTrackWidgetLinkClick,
    insightsSharePage,
    requisitionsButtonClick,
    requisitionsShareButtonClick,
    requisitionsShareModalUpdated,
    requisitionOpenReqReport,
    requisitionReportLanded,
    requisitionJobPostClicked,
    homeLogoClicked,
    homeClick,
    homeHover,
    homeLearnHover,
    homeLearnClick,

    appliedFilters,
  };
});
