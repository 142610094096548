import { isEmpty } from 'lodash-es';
import { getTables } from './transformers';

const transformPassthroughDropOffItems = (items) => {
  const newItems = {
    applied: {},
    assessment: {},
    hired: {},
    offer: {},
    screening: {},
  };

  const keyMappings = {
    Application: 'applied',
    Screening: 'screening',
    Assessment: 'assessment',
    Offered: 'offer',
    Hired: 'hired',
  };

  items.forEach((dataPoint) => {
    const key = Object.keys(dataPoint)[0];

    newItems[keyMappings[key]] = dataPoint[key];
  });

  return newItems;
};

export const getPassthroughDropOffTables = (tableData, { tableKeys, tableLabels, totalLabel }) => {
  if (isEmpty(tableData)) return {};

  let finalTableData = [];

  if (totalLabel) {
    const totalIndex = tableData.findIndex(({ label }) => label === totalLabel);
    const totals = tableData[totalIndex];

    if (totalIndex >= 0) tableData.splice(totalIndex, 1);

    finalTableData = transformPassthroughDropOffItems(totals?.items || []);

    finalTableData.label = null;
    finalTableData.items = tableData.map(({ label, items = [] }) => ({
      label,
      ...transformPassthroughDropOffItems(items),
    }));

    finalTableData = [finalTableData];
  } else {
    finalTableData = tableData.map(({ label, items = [] }) => ({
      label,
      ...transformPassthroughDropOffItems(items),
    }));
  }

  return getTables(finalTableData, { tableKeys, tableLabels });
};

export const sortPassthroughDropoffLabelsAndValues = (widgets) => {
  const newWidgets = widgets.map((widget) => {
    const newWidget = widget;
    const sortOrder = ['Application', 'Screening', 'Assessment', 'Offered', 'Hired'];
    const filteredSortOrder = sortOrder.filter((label) => newWidget.labels.includes(label));
    const mappings = newWidget.labels.map((label) => (filteredSortOrder.indexOf(label)));
    const newLabels = [];
    const newValues = [];

    mappings.forEach((sortIndex, index) => {
      newLabels[sortIndex] = newWidget.labels[index];
      newValues[sortIndex] = newWidget.values[index];
    });

    newWidget.labels = newLabels;
    newWidget.values = newValues;

    return newWidget;
  });

  return newWidgets;
};

export const sortTableItems = (items, sortOrder) => {
  const filteredSortOrder = sortOrder
    .filter((label) => items.some(({ label: itemLabel }) => itemLabel === label));
  const filteredItems = items.filter(({ label }) => filteredSortOrder.includes(label));

  filteredItems.sort(({ label: aLabel }, { label: bLabel }) => {
    const aIndex = filteredSortOrder.indexOf(aLabel);
    const bIndex = filteredSortOrder.indexOf(bLabel);

    return aIndex - bIndex;
  });

  return filteredItems;
};

export const sortPassthroughDropoffTables = (tables, sortOrder) => {
  const newTables = {};

  Object.keys(tables).forEach((key) => {
    newTables[key] = {
      ...tables[key],
      data: tables[key].data.map((dataPoint) => ({
        ...dataPoint,
        items: sortTableItems(dataPoint.items, sortOrder),
      })),
    };
  });

  return newTables;
};

const calculateItemPassthroughPercentages = (
  item,
  columns,
  { showTotals, showRows, hasGrandTotals },
) => {
  const newItem = item;

  columns.forEach((column, i) => {
    if (!newItem?.[column.key]) return;

    const newCell = {
      value: newItem[column.key].value,
    };

    const nextColumn = columns[i + 1];

    const showCategoryTotals = showTotals || (showRows && hasGrandTotals);
    if (nextColumn && showCategoryTotals) {
      const newPercentage = newItem[nextColumn.key].value / newItem[column.key].value;

      newCell.percentage = Number.isFinite(newPercentage) ? newPercentage : null;
    }

    newItem[column.key] = newCell;
  });

  if (newItem.items && showRows) {
    const newChildItems = newItem.items
      .map((childItem) => calculateItemPassthroughPercentages(childItem, columns, {
        showTotals: true,
        showRows: true,
        hasGrandTotals: false,
      }));

    newItem.items = newChildItems;
  }

  return newItem;
};

export const calculatePassthroughPercentages = (tableData, { showTotals, showRows }) => {
  const newTableData = {};

  Object.keys(tableData).forEach((key) => {
    const { columns = [] } = tableData[key];

    newTableData[key] = {
      ...tableData[key],
      totals: calculateItemPassthroughPercentages(
        tableData[key].totals || {},
        columns,
        { showTotals: true },
      ),
      data: tableData[key].data?.map((item) => calculateItemPassthroughPercentages(
        item,
        columns,
        { showTotals, showRows, hasGrandTotals: !isEmpty(tableData[key]?.totals || {}) },
      )),
    };
  });

  return newTableData;
};

export const getWidgetAndTableLabels = (widgetConfig, tableLabel = '') => {
  const final = { widgetLabels: [], tableLabel: '' };

  final.widgetLabels = widgetConfig.map((widget) => widget.itemLabel);
  final.tableLabel = tableLabel;

  return final;
};
