<template>
  <div class="datapeople-app">
    <v-app>
      <router-view
        v-slot="{ Component }"
        @track-page-load="trackPageLoad"
      >
        <component :is="Component" />
      </router-view>
    </v-app>
  </div>
</template>
<script>
import { throttle } from 'lodash-es';
import { mapStores, mapActions } from 'pinia';
import { useAppAuthData } from '@/stores/app/authData';
import { useTracking } from '@/stores/tracking/tracking';
import { useModals } from '@/stores/modals/modals';
import * as Sentry from '@sentry/vue';
import { useAppAlerts } from '@/stores/app/alerts';
import semver from 'semver';
import { useAppBase } from '@/stores/app/base';

export default {
  name: 'App',
  head() {
    return { title: this.$route.meta?.title || 'Datapeople' };
  },
  computed: {
    ...mapStores(useAppAuthData),
  },
  watch: {
    '$route.name': {
      handler() {
        this.resetModals();

        this.$nextTick(() => {
          if (
            !this.appAuthDataStore.authData.personaKey
              && !window.sessionStorage.getItem('dismissedPersonaModal')
              && !this.appAuthDataStore.isDatapeopleEmployee
              && this.appAuthDataStore.authData.isLoggedIn
              && this.$route.query?.view !== 'chrome'
          ) {
            useModals().setModal({ field: 'persona', value: true });
          }
        });
      },
    },
    'appAuthDataStore.authData.userId': {
      handler(newValue) {
        if (newValue) {
          Sentry.setUser({ id: newValue });
        } else {
          Sentry.setUser(null);
        }
      },
    },
    'appAuthDataStore.authData.appVersion': {
      handler(newValue) {
        // Handles if the API has higher version than the app and prompts a refresh to break cache
        if (
          newValue
          && newValue !== process.env.VUE_APP_PACKAGE_VERSION
          && semver.gt(newValue, process.env.VUE_APP_PACKAGE_VERSION)
        ) {
          this.addAlert({
            type: 'warning',
            message: 'New app version available! Please refresh your browser to get the latest version.',
          });
        }
      },
    },
  },
  created() {
    // Disable Grammarly
    const callback = () => {
      if (document.getElementsByTagName('GRAMMARLY-EXTENSION')[0]) {
        document.getElementsByTagName('GRAMMARLY-EXTENSION')[0].remove();
      }
    };
    const observer = new MutationObserver(callback);
    observer.observe(document.documentElement, {
      childList: true,
    });

    document.addEventListener('keydown', this.onKeyDown);
    document.addEventListener('keyup', this.onKeyUp);
    document.addEventListener('keypress', this.onKeyPress);
    window.addEventListener('resize', this.onResize, true);
    document.addEventListener('scroll', this.onScroll);
    document.addEventListener('copy', this.checkClipboardPermissions);
    document.addEventListener('paste', this.checkClipboardPermissions);
    window.addEventListener('popstate', this.handleBrowserBackOrForward);
  },

  mounted() {
    this.setWindowWidth({ width: window.innerWidth });
  },

  unmounted() {
    document.removeEventListener('keydown', this.onKeyDown);
    document.removeEventListener('keyup', this.onKeyUp);
    document.removeEventListener('keypress', this.onKeyPress);
    window.removeEventListener('resize', this.onResize);
    document.removeEventListener('scroll', this.onScroll);
    document.removeEventListener('copy', this.checkClipboardPermissions);
    document.removeEventListener('paste', this.checkClipboardPermissions);
    document.removeEventListener('popstate', this.handleBrowserBackOrForward);
  },
  methods: {
    ...mapActions(useModals, ['resetModals']),
    ...mapActions(useTracking, ['trackPageLoad']),
    ...mapActions(useAppAlerts, ['addAlert']),
    ...mapActions(useAppBase, ['keyEvent', 'setScroll', 'setWindowWidth', 'checkClipboardPermissions', 'setBrowserBackOrForwardNavigation']),

    handleBrowserBackOrForward() {
      this.setBrowserBackOrForwardNavigation(true);
    },
    onKeyEvent(type, event) {
      this.keyEvent({ type, event });
    },
    onKeyDown(event) {
      this.onKeyEvent('down', event);
    },
    onKeyUp(event) {
      this.onKeyEvent('up', event);
    },
    onKeyPress(event) {
      this.onKeyEvent('press', event);
    },
    onResize() {
      this.onResizeThrottled();
    },
    onResizeThrottled: throttle(function onResizeThrottledCallback() {
      this.setWindowWidth({ width: window.innerWidth });
    }, 100),
    onScroll(event) {
      this.onScrollThrottled(event);
    },
    onScrollThrottled: throttle(function onScrollThrottledCallback(event) {
      this.setScroll({ scrollY: window.scrollY, event });
    }, 200),
  },
};
</script>
<style lang="less" src="./app.less" />
