import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { weeklySnapshot, weeklySnapshotDownload } from '@/api/insights';
import { useAppLoading } from '@/stores/app/loading';
import { useAppAlerts } from '@/stores/app/alerts';
import { useAppPagination } from '@/stores/app/pagination';
import { mergeFilters } from '@/stores/insights/helpers';
import { useDatePicker } from '@/stores/filters/datePicker';
import { useFilters } from '@/stores/filters/filters';

export const useWeeklySnapshot = defineStore('weeklySnapshot', () => {
  // State
  const documents = ref([]);
  const totals = ref({});
  const sortBy = ref('days_published');
  const sortDesc = ref(false);
  const labelsForLoading = ref({});

  // Getters
  const sort = computed(() => `${sortDesc.value ? '-' : ''}${sortBy.value}` || undefined);
  const emptyData = computed(() => !documents.value?.length);

  // Actions
  const setDocuments = (newDocuments) => {
    documents.value = newDocuments;
  };

  const setTotals = (newTotals) => {
    totals.value = newTotals;
  };

  const setSortDesc = (bool) => {
    sortDesc.value = bool;
  };

  const setSortBy = (by) => {
    sortBy.value = by;
  };

  const setLabelsForLoading = (labels) => {
    labelsForLoading.value = labels;
  };

  const resetSort = () => {
    sortBy.value = 'days_published';
    sortDesc.value = false;
  };

  const loadPage = async ({
    router,
    page,
    routeQuery,
    sort: loadSort = sort.value,
    selectedFilters,
    selectedMyJobs,
    selectedDateWeek,
  }) => {
    try {
      const skeletonLoadersTimeout = setTimeout(() => {
        setLabelsForLoading({
          widgetLabels: [], tableLabel: 'Published Jobs Snapshot',
        });
        // add intermediate loading state to filter bar
        useAppLoading().setLoading({ halfTopBarLoading: false });
      }, 4000);

      useAppLoading().setLoading({
        pageLoading: true, filtersLoading: true, listLoading: true, halfTopBarLoading: true,
      });

      const parameters = {
        params: routeQuery,
        owned: selectedMyJobs,
        week: selectedDateWeek,
        selectedFilters,
        page,
        sort: loadSort,
      };
      const {
        filters, dateWeek, owned, documents: respDocuments, pagination, totals: respTotals,
      } = await weeklySnapshot(parameters);

      clearTimeout(skeletonLoadersTimeout);
      setLabelsForLoading({});

      useDatePicker().setDateWeek(dateWeek);

      useAppPagination().updatePagination(pagination);

      useFilters().updateFilters({ filters, myJobsEnabled: owned });

      // Needs to happen right here but needs to wait for updateFilters commits to finish
      setTimeout(() => {
        const { page: respPage, totalPages } = pagination;
        const queryFilters = mergeFilters(filters);

        const query = {
          week: dateWeek?.dateWeek || undefined,
          owned: owned?.value || undefined,
          page: totalPages > 1 ? respPage || 1 : undefined,
          sort: loadSort,
          ...queryFilters,
        };

        router.push({ query })
          .catch((err) => {
            if (err.message.startsWith('Avoided redundant navigation to current location')) {
              return;
            }
            throw err;
          });
      }, 0);

      setTotals(respTotals);
      setDocuments(respDocuments);
    } catch (e) {
      useAppAlerts().addAlert({ message: e.message });
      throw e;
    } finally {
      useAppLoading().setLoading({
        pageLoading: false, filtersLoading: false, listLoading: false, halfTopBarLoading: false,
      });
    }
  };

  const downloadWeeklySnapshot = ({
    document, mergedTempFilters, dateWeekIndex, myJobsEnabled,
  }) => {
    try {
      weeklySnapshotDownload({
        document,
        filters: mergedTempFilters,
        dateWeek: dateWeekIndex,
        myJobsEnabled,
        sort: sort.value,
      });
    } catch (error) {
      useAppAlerts().addAlert({ message: `Weekly Snapshot Download Error: ${error.message}` });
      throw error;
    }
  };

  const updateSort = ({
    router, newSortBy, newSortDesc, sendFilterRequestAction,
  }) => {
    if (newSortBy) setSortBy(newSortBy);
    if (typeof newSortDesc === 'boolean') setSortDesc(newSortDesc);

    sendFilterRequestAction({ router });
  };

  return {
    // State
    documents,
    totals,
    sortBy,
    sortDesc,
    labelsForLoading,

    // Getters
    sort,
    emptyData,

    // Actions
    setDocuments,
    setTotals,
    setSortDesc,
    setSortBy,
    resetSort,
    loadPage,
    downloadWeeklySnapshot,
    updateSort,
  };
});
