import { startCase } from 'lodash-es';

const formatValue = (value, format) => {
  if (format === 'percent' || format === 'absolutePercent') {
    return value * 100;
  }

  return value;
};

const getFormattedData = (data, labels, isTimestamp, isFlippedAxis, format) => {
  if (isTimestamp) {
    if (isFlippedAxis) {
      return data.map((value, i) => ({ y: labels[i], x: formatValue(value, format) }));
    }
    return data.map((value, i) => ({ x: labels[i], y: formatValue(value, format) }));
  }

  return data.map((value) => formatValue(value, format));
};

/**
 * handleStackedDatasets
 * organizes API data into Chart JS structure that allows
 * for singlular or multiple stacked datasets
 *
 * @param datasets
 * @param fields
 * @param labels
 * @param isTimestamp
 * @param isFlippedAxis
 * @returns {Array}
 * [[{ label: 'Dataset 1', data: [1, 2, 3] }, { label: 'Dataset 2', data: [4, 5, 6] }]]
 *
 *
 * or for time series:
 *
 *
 * [[{ label: 'Dataset 1', data: [{ x: '2022-01-01', y: 1 }, { x: '2022-01-02', y: 2 }] }]]
 */
const handleStackedDatasets = (
  datasets,
  fields,
  labels,
  isTimestamp,
  isFlippedAxis,
) => fields.value
  .map((keyArr) => keyArr
    .map((key, j) => ({
      label: fields.datasetLabels[j],
      data: getFormattedData(datasets[key], labels, isTimestamp, isFlippedAxis, fields.labelFormat),
    })));

/**
 * handleMultipleDatasets
 * organizes API data into a Chart JS structure that allows for multiple datasets
 *
 * @param datasets
 * @param fields
 * @param labels
 * @param isTimestamp
 * @param isFlippedAxis
 * @returns {Array}
 * [{ label: 'Dataset 1', data: [1, 2, 3] }, { label: 'Dataset 2', data: [4, 5, 6] }]
 *
 *
 * or for time series:
 *
 *
 * [{ label: 'Dataset 1', data: [{ x: '2022-01-01', y: 1 }, { x: '2022-01-02', y: 2 }] }]
 */
const handleMultipleDatasets = (
  datasets,
  fields,
  labels,
  isTimestamp,
  isFlippedAxis,
) => fields.value
  .map((key, i) => ({
    label: fields.datasetLabels[i],
    data: getFormattedData(datasets[key], labels, isTimestamp, isFlippedAxis, fields.labelFormat),
  }));

/**
 * getValues converts API data to Chart JS object: { values, labels }
 *
 * @param datasets
 * @param fields
 * @param labels
 * @param widgetType
 * @param isTimestamp
 * @returns {object}
 */
export const getValues = (datasets, fields, labels, widgetType, isTimestamp) => {
  const isMultipleDatasets = Array.isArray(fields.value);
  const isStackedDatasets = Array.isArray(fields.value[0]);
  const isFlippedAxis = ['horizontalBarChart', 'horizontalStackedBarChart'].includes(widgetType);

  if (isMultipleDatasets) {
    if (isStackedDatasets) {
      return handleStackedDatasets(datasets, fields, labels, isTimestamp, isFlippedAxis);
    }

    return handleMultipleDatasets(datasets, fields, labels, isTimestamp, isFlippedAxis);
  }

  return getFormattedData(
    datasets[fields.value],
    labels,
    isTimestamp,
    isFlippedAxis,
    fields.labelFormat,
  );
};

const buildTableColumns = (data) => data.map((item) => ({
  key: item,
  label: startCase(item),
}));

const getTableTotals = ((data, keys) => {
  const totals = {};

  let totalValue = 0;

  data.forEach((instance) => {
    keys.forEach((key) => {
      if (!instance?.[key]?.value) return;

      totals[key] = {
        value: totals?.[key]?.value
          ? totals[key].value + instance[key].value
          : instance[key].value,
      };

      totalValue += instance[key].value;
    });
  });

  keys.forEach((key) => {
    const total = totals[key] || {};

    totals[key] = { ...total, percentage: total.value / totalValue };
  });

  return totals;
});

// Breaks longer labels into multiple lines depending on the size
// of the dataset and then number of words in the label
const getMultilineLabels = (labels) => (labels.map((label) => {
  const splitLabel = label.split(' ');
  const numberOfWordsPerRow = labels.length < 5 && splitLabel.length < 4 ? 1 : 2;
  const iterations = Math.ceil(splitLabel.length / numberOfWordsPerRow);

  if (iterations > 1) {
    const labelLines = [];

    for (let i = 0; i < iterations; i += 1) {
      const start = i * numberOfWordsPerRow;
      const end = i * numberOfWordsPerRow + numberOfWordsPerRow > splitLabel.length
        ? splitLabel.length
        : i * numberOfWordsPerRow + numberOfWordsPerRow;

      labelLines.push(splitLabel.slice(start, end).join(' '));
    }

    return labelLines;
  }

  return label;
}));

/**
 * Handles getting the aggregated total of non top 5 datasets
 *
 * @param topValues
 * @param otherValues
 * @param isObjectArray
 * @returns
 */
const getAggregatedOther = (topValues, otherValues, isObjectArray) => {
  if (isObjectArray) {
    let total = 0;

    otherValues.forEach((value) => {
      total += value;
    });

    return [...topValues, total];
  }
  return topValues.map((dataset, i) => {
    let total = 0;

    otherValues[i].data.forEach((value) => {
      total += value;
    });

    return {
      ...dataset,
      data: [...dataset.data, total],
    };
  });
};

/**
 * Handles getting the top 5 datasets and labels for a chart
 * or top 5 plus an "Other" category of aggregated data
 *
 * @param {*} values
 * @param {*} aggregatedOtherLabel
 * @param {*} multilineLabels
 * @returns
 */
const getTopValuesAndLabels = (values, aggregatedOtherLabel, multilineLabels) => {
  const isObjectArray = typeof values[0] !== 'object';

  const includeAggregatedOther = isObjectArray
    ? values.length > 5
    : values.some((dataset) => (dataset?.data || []).length > 5);

  let topValues = isObjectArray
    ? values.slice(0, 5)
    : values.map((dataset) => ({ ...dataset, data: (dataset?.data || []).slice(0, 5) }));

  const otherValues = isObjectArray
    ? values.slice(5)
    : values.map((dataset) => ({ ...dataset, data: (dataset?.data || []).slice(5) }));

  let topLabels = multilineLabels.slice(0, 5);

  if (includeAggregatedOther) {
    topValues = getAggregatedOther(topValues, otherValues, isObjectArray);
    topLabels = [...topLabels, aggregatedOtherLabel];
  }

  return { topValues, topLabels };
};

export const getWidgets = (
  chartData,
  {
    defaultWidgetConfig,
    fieldMappings,
    showSymmetricalNegativeValues = false,
    aggregatedOtherLabel = 'Other',
    lowestMinMaxValue,
  },
) => fieldMappings.map((fields, i) => {
  const defaultConfig = defaultWidgetConfig[i];
  const { convertToPercent = true } = defaultConfig;
  if (
    (Array.isArray(chartData) && !chartData?.length)
  ) {
    return defaultConfig;
  }
  const { labels, datasets } = chartData[defaultConfig.id];
  const isTimestamp = fields.labelFormat.startsWith('timestamp');
  const values = getValues(datasets, fields, labels, defaultConfig.type, isTimestamp);
  const multilineLabels = getMultilineLabels(labels);
  const {
    topValues,
    topLabels,
  } = getTopValuesAndLabels(values, aggregatedOtherLabel, multilineLabels);

  let totalValue = 0;

  values.forEach((value) => {
    totalValue += value;
  });

  const valuesPercent = topValues.map((value) => (value / totalValue) * 100);

  return {
    ...defaultConfig,
    isTimestamp,
    showSymmetricalNegativeValues,
    lowestMinMaxValue,
    labels: topLabels,
    values: convertToPercent ? valuesPercent : topValues,
  };
});

const sortFunc = ({ applied: aApplied }, { applied: bApplied }) => bApplied.value - aApplied.value;

export const transformCategoryLabel = (tables, newTables = {}, categoryLabels = {}) => {
  const tablesByKeys = Object.keys(tables);
  const updatedTables = { ...newTables };

  tablesByKeys.forEach((table) => {
    const itemsInTable = tables[table]?.data;
    let data = [];
    if (itemsInTable?.length) {
      data = itemsInTable.map((instance) => ({
        ...instance,
        label: categoryLabels[instance.label] || instance.label,
      }));
    }
    updatedTables[table] = { ...tables[table], data };
  });

  return updatedTables;
};

// Sorts parent and child rows
const sortTableData = (data) => {
  data.sort(sortFunc);

  return data.map((instance) => {
    if (instance?.items?.length) {
      return {
        ...instance,
        items: instance.items.sort(sortFunc),
      };
    }

    return instance;
  });
};

export const getTables = (tableData, {
  tableKeys, tableLabels, calcTotals = false, tableDataKeys = [],
}) => {
  const tables = {};
  const tableKeyMappings = ['firstTable'];
  const isMultipleTables = Boolean(tableDataKeys.length);

  if (isMultipleTables) {
    tableKeyMappings.push('secondTable');
  }

  tableKeyMappings.forEach((key, i) => {
    const isEmpty = isMultipleTables
      ? tableDataKeys.every((tableDataKey) => !tableData?.[tableDataKey]?.length)
      : !tableData.length;

    if (isEmpty) return;

    const tableDataToUse = isMultipleTables
      ? tableData[tableDataKeys[i]]
      : tableData;

    tables[key] = {
      columns: buildTableColumns(tableKeys),
      label: tableLabels[i] || '',
      data: sortTableData(tableDataToUse) || [],
      totals: calcTotals ? getTableTotals(tableDataToUse, tableKeys) : {},
      metrics: {},
    };
  });

  return tables;
};
