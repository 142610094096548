import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useAppAlerts = defineStore('appAlerts', () => {
  /**
   * @type {{value: {
   *   message: string,
   *   type: string,
   *   params: object,
   *   timeout: number,
   *   timeoutId: number,
   *   thisPageOnly: boolean,
   *   onlyOnceId: string,
   * }[]}}
   */
  const alerts = ref([]);

  /** @type {{value: string[]}} */
  const onlyOnceAlertIds = ref([]);

  /** @type {{value: number}} */
  const nextAlertId = ref(1);

  const resetAlertId = () => {
    nextAlertId.value = 1;
  };

  const removeAlert = (id) => {
    const alert = alerts.value.find(({ id: alertId }) => alertId === id);

    if (!alert) {
      return;
    }

    if (alert.timeoutId) {
      clearTimeout(alert.timeoutId);
    }

    alerts.value = alerts.value.filter(({ id: alertId }) => alertId !== id);
  };

  const addAlert = ({
    message, type = 'error', params, timeout = 0, thisPageOnly = false, onlyOnceId, id = null,
  }) => {
    if (!message) {
      return;
    }

    if (onlyOnceId && onlyOnceAlertIds.value.includes(onlyOnceId)) {
      return;
    }

    let alertId;
    if (id) {
      alertId = id;
    } else {
      alertId = nextAlertId.value;
      nextAlertId.value += 1;
    }

    let timeoutId = 0;
    if (timeout) {
      timeoutId = setTimeout(() => removeAlert(id), timeout);
    }

    if (onlyOnceId) {
      onlyOnceAlertIds.value.push(onlyOnceId);
    }

    alerts.value.push({
      id: alertId, message, type, params, timeout, timeoutId, thisPageOnly, onlyOnceId,
    });
  };

  return {
    alerts, onlyOnceAlertIds, addAlert, removeAlert, resetAlertId,
  };
});
