import { createRouter, createWebHistory } from 'vue-router';
import { isInternetExplorer, isMobileBrowser } from '@/helpers/browserDetection';
import { useAppAllowMobile } from '@/stores/app/allowMobile';
import { useAppAuthData } from '@/stores/app/authData';
import { useAppGate } from '@/stores/app/gate';
import { useAppRoute } from '@/stores/app/route';
import { getRouterConfig } from '@/routes/routes';
import { addRouterHooks } from '@/routes/hooks';

export const initRouter = ({ pinia, app }) => {
  const allowMobileStore = useAppAllowMobile(pinia);
  const authDataStore = useAppAuthData(pinia);
  const gateStore = useAppGate(pinia);
  const routeStore = useAppRoute(pinia);

  const router = createRouter({
    history: createWebHistory('/app'),
    routes: getRouterConfig({ authDataStore }),
  });

  addRouterHooks(router, {
    allowMobileStore,
    authDataStore,
    gateStore,
    isInternetExplorer,
    isMobileBrowser,
    routeStore,
  });

  app.use(router);

  return router;
};
