import {
  startOfWeek,
  setISOWeek,
  parseISO,
  format,
  getISOWeekYear,
  addDays,
  getISOWeek,
} from 'date-fns';
import { toArray } from 'lodash-es';

/**
 * Gets the starting day of the week and the first date of the first week of the year.
 *
 * @param {number} year - The year.
 * @returns {object} An object containing the starting day of the week
 * and the first date of the first week.
 * - weekStartsOn: The starting day of the week (1 for Monday, 0 for Sunday).
 * - firstWeekFirstDate: The first date of the first week of the year.
 */
function getWeekStartAndFirstDate(year) {
  const firstDayOfYear = new Date(year, 0, 1);
  const weekStartsOn = 1; // Set Monday as the starting day of the week

  // Get the day of the week for the first day of the year
  const dayOfWeek = firstDayOfYear.getDay();

  // Calculate the offset to the desired starting day of the week
  const offset = (weekStartsOn - dayOfWeek + 7) % 7;

  // Adjust the first day of the year by adding the offset
  const firstWeekFirstDate = new Date(firstDayOfYear);
  firstWeekFirstDate.setDate(firstDayOfYear.getDate() + offset);

  // If the week starts on Saturday or Sunday, the first week is the week of the previous year
  if (offset > 4) {
    firstWeekFirstDate.setFullYear(year - 1);
  }

  return {
    weekStartsOn,
    firstWeekFirstDate,
  };
}

/**
 * Converts a date week in the format "yyyy-Www" to a range of dates.
 *
 * @param {string} dateWeek - The date week in the format "yyyy-Www".
 * @returns {object} An object containing the start and end dates of the week range.
 */
export function dateWeekToRange(dateWeek) {
  const [year, weekIndex] = dateWeek.split('-W');
  const { weekStartsOn, firstWeekFirstDate } = getWeekStartAndFirstDate(parseInt(year, 10));

  const options = {
    weekStartsOn,
    firstWeekContainsDate: firstWeekFirstDate.getDate(),
  };

  const startDate = setISOWeek(parseISO(`${year}-W${weekIndex}`), parseInt(weekIndex, 10), options);
  const startOfWeekVar = startOfWeek(startDate);
  const endOfWeek = new Date(startOfWeekVar);

  endOfWeek.setDate(startOfWeekVar.getDate() + 6);

  return {
    from: format(startOfWeekVar, 'yyyy-MM-dd'),
    to: format(endOfWeek, 'yyyy-MM-dd'),
  };
}

export const removeEmptyParams = (params) => {
  if (typeof params !== 'object') return params;

  const newParams = {};

  Object.keys(params).forEach((key) => {
    if (params[key]) newParams[key] = params[key];
  });

  return newParams;
};

/**
 * Converts a given date to the week format "yyyy-Www".
 *
 * @param {Date} date - The date to be converted.
 * @returns {string} The date in the week format "yyyy-Www".
 */
const convertToWeekFormat = (date) => {
  const year = getISOWeekYear(date);
  const weekStartsOn = 1; // Set Monday as the starting day of the week

  // Calculate the day offset based on the starting day of the week
  const dayOffset = (date.getDay() - weekStartsOn + 7) % 7;

  const adjustedDate = addDays(date, -dayOffset);
  const weekNumber = getISOWeek(adjustedDate);

  // Format the week number as a two-digit string with leading zeros if necessary
  const weekStr = weekNumber.toString().padStart(2, '0');

  return `${year}-W${weekStr}`;
};

export const getDateWeekIndex = (datePickerVariant, sortedDateRange) => {
  if (datePickerVariant === 'week' && sortedDateRange[1]) {
    return convertToWeekFormat(new Date(sortedDateRange[1]));
  }

  return '';
};

// Uses stack method for greater performance
export const flattenNestedOptions = (arr, labelKey, idKey) => {
  const result = [];
  let stack = [...arr];

  while (stack.length > 0) {
    const current = stack.pop();
    result.push({ [idKey]: current[idKey], [labelKey]: current[labelKey] });

    if (current.children && current.children.length > 0) {
      stack = stack.concat(current.children);
    }
  }

  return result;
};

// Using Map to improve lookup performance
export const createSearchIndex = (options, labelKey) => {
  const index = new Map();

  options.forEach((option) => {
    const labels = option[labelKey].toLowerCase().split(' ');

    labels.forEach((label) => {
      if (!index.has(label)) {
        index.set(label, []);
      }

      index.get(label).push(option);
    });
  });

  return index;
};

export const lookupOptions = (searchQuery, searchIndex) => {
  const query = searchQuery.toLowerCase();
  const words = query.split(/\s+/).filter(Boolean);
  const matchingOptions = new Set();

  searchIndex.forEach((indexOptions, label) => {
    if (words.some((word) => label.includes(word))) {
      indexOptions.forEach((option) => matchingOptions.add(option));
    }
  });

  return toArray(matchingOptions);
};
