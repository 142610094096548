import { getRequest } from '@/api/request';
import { storeToRefs } from 'pinia';
import { useAppAuthData } from '@/stores/app/authData';

export const fetchAdminHome = async () => {
  const requests = [
    { path: '/homepage/admin-post-summary', defaulValue: {} },
    { path: '/homepage/admin-user-summary', defaultValue: {} },
    { path: '/homepage/post-summary', defaultValue: {} },
  ];

  const { authData } = storeToRefs(useAppAuthData());

  if (authData.value.requisitionPagesEnabled) {
    requests.push({ path: '/requisition/req-summary', defaultValue: {} });
  }

  return Promise.allSettled(requests
    .map(({ path }) => getRequest({ url: path })));
};

export const fetchNonAdminHome = async () => {
  const requests = [
    { path: '/homepage/post-summary', defaultValue: {} },
  ];

  const { authData } = storeToRefs(useAppAuthData());

  if (authData.value.requisitionPagesEnabled) {
    requests.push({ path: '/requisition/req-summary', defaultValue: {} });
  }

  return Promise.all(requests
    .map(({ path }) => getRequest({ url: path })));
};
