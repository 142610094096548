import ChartDataLabels from 'chartjs-plugin-datalabels';
import { snakeCase } from 'lodash-es';
import { roundFloat, formatTooltipValue } from '@/helpers/formatters';
import { emitter } from '@/helpers/emitter';
import theme from '@/configs/vuetify';
import { getWidgets, getTables } from './transformers';
import {
  calculatePassthroughPercentages,
  getWidgetAndTableLabels,
} from './common';

const {
  flameOrange, primaryColor, zirconGrey, valenciaRed, secondaryColor, pureWhite,
} = theme.theme.themes.light.colors;
const lowestMinMaxValue = 10;

const defaultWidgetConfig = [
  {
    id: 'gender-representation-by-stage',
    tooltipKey: 'gender-by-stage',
    itemLabel: 'What is the gender representation by stage?',
    groupLabel: '',
    type: 'lineChart',
    colors: [
      { color: flameOrange },
      { color: primaryColor },
      { color: zirconGrey },
    ],
    labels: [],
    values: [],
    optionsOverride: {
      'scales.y.ticks.callback': function yTickCallback(value) {
        return `${value}%`;
      },
      'plugins.legend': {
        display: true,
        position: 'right',
        align: 'start',
        maxWidth: 200,
        onClick: () => {},
        labels: {
          boxWidth: 12,
          boxHeight: 12,
          generateLabels: (chart) => chart.data.datasets.map((dataset) => ({
            text: dataset.label,
            fillStyle: dataset.borderColor,
            strokeStyle: dataset.borderColor,
            borderRadius: 4,
          })),
        },
      },
    },
    toolTipConfig: {
      /*
        toolTipConfig sent as prop from DashboardPage > Widget > LineChartWidget (via common props)
      */
      onHover() {
        emitter.emit('track-chart-hover');
      },
    },
    convertToPercent: false,
  },
  {
    id: 'female-vs-male-passthrough-rate',
    tooltipKey: 'gender-male-vs-female',
    itemLabel: 'What is the difference between male and female pass-through rates?',
    groupLabel: '',
    type: 'horizontalBarChart',
    colorPerDataset: true,
    labels: [],
    values: [],
    datasetsOverride: {
      backgroundColor: function backgroundColorCallback(dataset) {
        return dataset.data
          .map((value) => (Math.abs(value) > 5 ? valenciaRed : secondaryColor));
      },
    },
    optionsOverride: {
      'scales.x.ticks.callback': function xTickCallback(value) {
        return `${Math.round(Math.abs(value * 10)) / 10}%`;
      },
      'scales.x.ticks.padding': 16,
      'plugins.datalabels.color': function colorCallback(context) {
        const max = Math.max(...context.dataset.data.filter((value) => Math.abs(value)));
        const trueMax = max < lowestMinMaxValue ? lowestMinMaxValue : max;
        const value = Math.abs(context.dataset.data[context.dataIndex]);
        const percentOfSpace = (value / trueMax) * 100;

        return percentOfSpace < 30 ? secondaryColor : pureWhite;
      },
      'plugins.datalabels.anchor': function anchorCallback(context) {
        const value = context.dataset.data[context.dataIndex];

        return value < 0 ? 'start' : 'end';
      },
      'plugins.datalabels.align': function alignCallback(context) {
        const max = Math.max(...context.dataset.data.filter((value) => Math.abs(value)));
        const trueMax = max < lowestMinMaxValue ? lowestMinMaxValue : max;
        const value = context.dataset.data[context.dataIndex];
        const absValue = Math.abs(value);
        const percentOfSpace = Number.isNaN((absValue / trueMax) * 100)
          ? 0 : (absValue / trueMax) * 100;
        const isNegative = value < 0;

        return (percentOfSpace < 30 && !isNegative) || (isNegative && percentOfSpace >= 30) ? 'end' : 'start';
      },
      'plugins.datalabels.formatter': function formatterCallback(value) {
        if (Number.isNaN(value)) return '';
        return formatTooltipValue(Math.abs(value), 'percent');
      },
    },
    pluginsOverride: [ChartDataLabels],
    convertToPercent: false,
  },
];

const fieldMappings = [
  {
    value: ['female', 'male', 'unknown'],
    label: 'count',
    labelFormat: 'percent',
    datasetLabels: ['Female', 'Male', 'Unknown'],
  },
  {
    value: 'difference',
    label: 'count',
    labelFormat: 'absolutePercent',
  },
];

const tableLabelForLoading = 'Gender Report';
const tableKeys = ['applied', 'screening', 'assessment', 'offer', 'hired'];
const tableLabels = ['Gender Report'];
const metricsToUse = ['Female', 'Male'];
const displayOptions = {
  passthroughPercentages: {
    showTotals: true,
    showRows: true,
  },
};

const getGenderMetrics = (data) => {
  const totals = {
    applied: data?.applied?.value || 0,
    hired: data?.hired?.value || 0,
  };
  const metrics = {};

  (data?.items || []).forEach((instance) => {
    if (metricsToUse.includes(instance.label)) {
      const metricKeys = ['applied', 'hired'];

      metricKeys.forEach((metricKey) => {
        const snakeCaseKey = snakeCase(`${instance.label}_${metricKey}`);

        metrics[snakeCaseKey] = roundFloat(
          (instance[metricKey].value / totals[metricKey]) * 100,
        );
        if (Number.isNaN(metrics[snakeCaseKey])) {
          metrics[snakeCaseKey] = 0;
        }
      });
    }
  });

  return metrics;
};

const getGenderTables = (tableData) => {
  const tables = getTables(tableData, { tableKeys, tableLabels, metricsToUse });
  const tablesWithPassthroughPercentages = calculatePassthroughPercentages(
    tables,
    displayOptions.passthroughPercentages,
  );

  return tablesWithPassthroughPercentages;
};

export const getGenderReportData = ({ chartData, tableData }) => {
  const widgets = getWidgets(
    chartData,
    {
      defaultWidgetConfig,
      fieldMappings,
      showSymmetricalNegativeValues: true,
      lowestMinMaxValue,
    },
  );
  const tables = getGenderTables(tableData);
  const metrics = getGenderMetrics(tableData[0]);
  const labelFormats = fieldMappings.map(({ labelFormat }) => labelFormat);

  return {
    widgets, totals: metrics, labelFormats, tables, displayOptions,
  };
};

export const getGenderReportLabelsForLoading = getWidgetAndTableLabels(
  defaultWidgetConfig,
  tableLabelForLoading,
);
