/* eslint-disable */

import mp from 'mixpanel-browser';

export const initMixpanel = ({ mixpanel }) => {
  mp.init(mixpanel.token, {
    cross_subdomain_cookie: false,
    ignore_dnt: true,
    secure_cookie: true,
  });

  mixpanelIdentify({ mixpanel });
};

export const mixpanelIdentify = ({ mixpanel }) => {
  const { userId, createdAt, projectId, isGuest, companyName } = mixpanel;

  /* Identify Mixpanel user */
  if (!isGuest) {
    try {
      mp.identify(userId);
      mp.people.set({
        'Created at': createdAt,
        'Company': companyName,
        'project_id': projectId,
      });
    } catch (e) {}
  }
};
