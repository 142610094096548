import { computed, ref } from 'vue';
import { errorMessage } from './validation';

/**
 * This function generates pinia store definition, one store per form.
 *
 * @param {object} form object containing form information
 * @param {object[]} form.fields list of form field configurations
 * @returns {{
 *   values: ref<object>,
 *   errors: ref<object>,
 *   errorMessages: ref<object>,
 *   isFormValid: ref<boolean>,
 *   setFormValues: Function,
 *   setIsValidated: Function,
 *   setBackendErrors: Function,
 * }} store members
 */
export const formModule = ({ fields }) => {
  /**
   * Example fields:
   *   {
   *     lastName: {
   *       value: 'initial',
   *       rules: [{ name: 'required' }, { name: 'length', params: { lessThan: 250 } }],
   *     },
   *     email: {},
   *   }
   */

  /**
   * `fieldNames` is ['lastName', 'email']
   */
  const fieldNames = Object.keys(fields);

  /**
   * `fieldsRef` is ref({
   *   lastName: {
   *     value: 'initial',
   *     rules: [{ name: 'required' }, { name: 'length', params: { lessThan: 250 } }],
   *     isValidated: false,
   *     backendError: '',
   *   },
   *   email: { value: '', rules: [], isValidated: false, backendError: '' }
   * })
   */
  const fieldsRef = ref(fieldNames.reduce((res, name) => ({
    ...res,
    [name]: {
      value: fields[name].value || '',
      rules: fields[name].rules || [],
      label: fields[name].label || '',
      isValidated: false,
      backendError: '',
    },
  }), {}));

  /**
   * All values: { lastName: 'initial', email: '' }
   */
  const values = computed(() => fieldNames.reduce((res, name) => ({
    ...res,
    [name]: fieldsRef.value[name].value || '',
  }), {}));

  /**
   * All labels: { label: 'Last Name' }
   */
  const labels = computed(() => fieldNames.reduce((res, name) => ({
    ...res,
    [name]: fieldsRef.value[name].label || '',
  }), {}));

  /**
   * All errors: { lastName: false, email: true }
   *
   * Each error flag indicates the validation error should be displayed.
   * In some cases a field may have a validation error (i.e. `errorMessage`), but
   * the message should not be displayed yet. In this case `errorMessage` is not empty, but
   * the error flag is false.
   */
  const errors = computed(() => fieldNames.reduce((res, name) => {
    const {
      backendError, isValidated, value, rules,
    } = fieldsRef.value[name];

    const frontendError = isValidated && errorMessage(value, rules);

    return { ...res, [name]: Boolean(backendError || frontendError) };
  }, {}));

  /**
   * All error messages: { lastName: 'The field is required', email: '' }
   */
  const errorMessages = computed(() => fieldNames.reduce((res, name) => {
    const { value, rules } = fieldsRef.value[name];

    return {
      ...res,
      [name]: fieldsRef.value[name].backendError || errorMessage(value, rules),
    };
  }, {}));

  /**
   * A form is not valid if it has a field with an `errorMessage`.
   * Submit button should be disabled in the case.
   */
  const isFormValid = computed(() => !fieldNames.some((name) => errorMessages.value[name]));

  const definedOnly = (data) => fieldNames.filter((name) => data[name] !== undefined);

  /**
   * Update field values. Usually, the function should be called on `@input` event.
   * newValues: { lastName: 'John', email: '' }
   *
   * @param {object} newValues New form values
   */
  const setFormValues = (newValues = {}) => {
    definedOnly(newValues).forEach((name) => {
      fieldsRef.value[name].value = newValues[name];
      fieldsRef.value[name].backendError = '';
    });
  };

  /**
   * Update `isValidated` values. If a field has a validation error,
   * but `isValidated` is not set yet, the error will not be displayed.
   * Usually, the function should be called on `@change` event.
   *
   * @param {object} newValues New form values
   */
  const setIsValidated = (newValues) => {
    definedOnly(newValues).forEach((name) => {
      fieldsRef.value[name].isValidated = newValues[name];
    });
  };

  /**
   * Set backend validation error messages.
   *
   * @param {object} newValues New form values
   */
  const setBackendErrors = (newValues) => {
    definedOnly(newValues).forEach((name) => {
      fieldsRef.value[name].backendError = newValues[name];
    });
  };

  /**
   * Reset form values, backend errors, and validation status.
   *
   */
  const resetForm = () => {
    fieldNames.forEach((name) => {
      fieldsRef.value[name].value = '';
      fieldsRef.value[name].backendError = '';
      fieldsRef.value[name].isValidated = false;
    });
  };

  return {
    values,
    errors,
    errorMessages,
    labels,
    isFormValid,
    setFormValues,
    setIsValidated,
    setBackendErrors,
    resetForm,
  };
};
