import { getAcceptableParams } from '@/helpers/formatters';
import { getHiringDynamicsData, getHiringDynamicsLabelsForLoading } from './hiringDynamics';
import { getRequisitionEfficiencyData, getRequisitionEfficiencyLabelsForLoading } from './requisitionEfficiency';
import { getCandidatePoolMetricsData, getCandidatePoolMetricsLabelsForLoading } from './candidatePoolMetrics';
import { getOverallReportData, getOverallReportLabelsForLoading } from './overallReport';
import { getInboundReportData, getInboundReportLabelsForLoading } from './inboundReport';
import { getOutboundReportData, getOutboundReportLabelsForLoading } from './outboundReport';
import { getGenderReportData, getGenderReportLabelsForLoading } from './genderReport';
import { getDropOffData, getDropOffLabelsForLoading } from './dropoff';
import { getPassthroughData, getPassthroughLabelsForLoading } from './passthrough';
import { getHiringReportData, getHiringReportLabelsForLoading } from './hiringReport';

const pageParsers = {
  'hiring-dynamics': getHiringDynamicsData,
  'requisition-efficiency': getRequisitionEfficiencyData,
  'candidate-pool-metrics': getCandidatePoolMetricsData,
  'overall-report': getOverallReportData,
  'inbound-report': getInboundReportData,
  'outbound-report': getOutboundReportData,
  'gender-report': getGenderReportData,
  'drop-off': getDropOffData,
  'pass-through': getPassthroughData,
  'hiring-report': getHiringReportData,
};

const dateRange = ['from', 'to'];
const dateWeek = ['week'];

export const getIgnorableUrlParams = (routeName) => ({
  'weekly-snapshot': [...dateRange, 'internal'],
  'content-snapshot': [...dateRange, 'internal'],
  'language-snapshot': [...dateRange, 'internal'],
  'overall-report': ['internal'],
  'inbound-report': ['internal'],
  'outbound-report': ['internal'],
  'drop-off': ['internal'],
  'pass-through': ['internal'],
  'hiring-report': ['internal'],
  'gender-report': ['internal'],
  'executive-insights': ['internal'],
  'recruiting-performance': ['internal'],
})[routeName] || dateWeek;

export const getWidgets = (data, page) => pageParsers[page](data);

export const dashboardRequests = {
  'overall-report': '/analytics/funnel-overall',
  'inbound-report': '/analytics/funnel-inbound',
  'outbound-report': '/analytics/funnel-outbound',
  'gender-report': '/analytics/gender',
  'drop-off': '/analytics/drop-off-passthrough',
  'pass-through': '/analytics/drop-off-passthrough',
  'hiring-report': '/analytics/hiring-report',
};

export const getReportingQueryParam = (route, routeName) => {
  const { page, sort, ...params } = route.query;
  const ignoreParams = getIgnorableUrlParams(routeName);

  // Keep reporting and analytics in case of redirects
  if (route.path.includes('/analytics/') || route.path.includes('/reporting/') || route.path.includes('/insights/')) {
    return getAcceptableParams({ params, ignoreParams });
  }

  return {};
};

export const getPageLabelsForLoading = (page) => ({
  'hiring-dynamics': getHiringDynamicsLabelsForLoading,
  'requisition-efficiency': getRequisitionEfficiencyLabelsForLoading,
  'candidate-pool-metrics': getCandidatePoolMetricsLabelsForLoading,
  'overall-report': getOverallReportLabelsForLoading,
  'inbound-report': getInboundReportLabelsForLoading,
  'outbound-report': getOutboundReportLabelsForLoading,
  'gender-report': getGenderReportLabelsForLoading,
  'drop-off': getDropOffLabelsForLoading,
  'pass-through': getPassthroughLabelsForLoading,
  'hiring-report': getHiringReportLabelsForLoading,
}[page]);
