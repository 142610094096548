export const numberWithCommas = (number, decimalPlaces = 0) => {
  if (typeof number === 'number') {
    const parts = number.toFixed(decimalPlaces).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }
  return number;
};

/*
If multiple params for the same filter exist, reduce them into an array for sending to backend.
  returns {
    key1: [values],
    key2: [values],
    etc...
  }
*/

export const paramCleaner = (params) => Object.keys(params)
  .reduce((acc, key) => {
    acc[key] = Array.isArray(params[key])
      ? params[key]
      : [params[key]];
    return acc;
  }, {});

export const getAcceptableParams = ({ params = {}, ignoreParams = [] }) => {
  const newParams = {};

  Object.keys(params).forEach((paramKey) => {
    if (!ignoreParams.includes(paramKey)) {
      newParams[paramKey] = params[paramKey];
    }
  });

  return newParams;
};

/* Used for job score animation and display */

export const colorMap = (score, colorsToMap) => {
  const formattedScore = parseInt(score, 10);

  if (formattedScore === 0) {
    return colorsToMap.secondaryColorLighten90.value;
  }
  if (formattedScore > 0 && formattedScore <= 49) {
    return colorsToMap.valenciaRed.value;
  }
  if (formattedScore >= 50 && formattedScore <= 69) {
    return colorsToMap.newOrange.value;
  }
  if (formattedScore >= 70 && formattedScore <= 84) {
    return colorsToMap.pacificBlue.value;
  }
  if (formattedScore >= 85) {
    return colorsToMap.caribbeanGreen.value;
  }
  return colorsToMap.secondaryColorLighten90.value;
};

export const roundFloat = (number, { numberOfDecimalPlaces = 1, isPercent } = {}) => {
  const multiplier = isPercent ? 100 : 1;

  return parseFloat((number * multiplier).toFixed(numberOfDecimalPlaces));
};

export const formatTooltipValue = (value, labelFormat) => {
  const number = parseFloat(value, 10);
  if (labelFormat === 'percent') {
    const roundedNumber = roundFloat(number);
    const roundedStr = roundedNumber.toString();
    const splitNumber = roundedStr.includes('.') ? roundedStr.split('.') : null;
    const decimalPlaces = splitNumber ? splitNumber[splitNumber.length - 1].length : 0;
    const prettyText = numberWithCommas(roundedNumber, decimalPlaces);

    return `${prettyText}%`;
  }

  if (labelFormat === 'absolutePercent') {
    const roundedNumber = roundFloat(Math.abs(number));
    const roundedStr = roundedNumber.toString();
    const splitNumber = roundedStr.includes('.') ? roundedStr.split('.') : null;
    const decimalPlaces = splitNumber ? splitNumber[splitNumber.length - 1].length : 0;
    const prettyText = numberWithCommas(roundedNumber, decimalPlaces);

    return `${prettyText}%`;
  }

  return Number.isNaN(number) ? value : roundFloat(number);
};

export const convertValidUsers = (users) => users
  // issues-tracker/issues/1378
  // test environment may send fake emails like "user@not-integrated-customer-3.domain.tld"
  .filter(({ email }) => !email.endsWith('.domain.tld'))
  .map((user) => ({
    id: user.email,
    label: `${user.name} ${user.email}`,
    name: user.name,
    email: user.email,
  }));
