import { useInsights } from '@/stores/insights/insights';
import { hiringReportToOtherReport, otherReportToHiringReport } from './constants/reportingWarnings';

export const routingFromHiringReport = ({ from, to }) => {
  if (from.name === 'hiring-report' && to.name !== 'recruiting-performance') {
    useInsights().setReportingMessage(hiringReportToOtherReport);
  } else {
    useInsights().setReportingMessage({ header: '', message: '', beaconArticleId: '' });
  }
};

export const newReportsMiddleware = (authDataStore) => (to, from, next) => {
  routingFromHiringReport({ to, from });
  if (
    authDataStore.authData.isApplicationReportingEnabled
  ) {
    next();
  } else {
    next({ name: 'recruiting-performance' });
  }
};

export const executiveInsightsMiddleware = (authDataStore) => (to, from, next) => {
  if (
    authDataStore.authData.isSuperuser
      || (authDataStore.authData.isApplicationReportingEnabled
          && authDataStore.authData.executiveInsightsEnabled)
  ) {
    next();
  } else {
    next({ name: 'recruiting-performance' });
  }
};

export const requisitionsMiddleware = (authDataStore) => (to, from, next) => {
  if (
    authDataStore.authData.requisitionPagesEnabled && authDataStore.integrated
  ) {
    next();
  } else {
    next({ name: 'root' });
  }
};

export const hiringReportMiddleware = (authDataStore) => (to, from, next) => {
  if (
    authDataStore.authData.isSuperuser
     || authDataStore.authData.isApplicationReportingEnabled
  ) {
    if (
      from.path.startsWith('/insights')
      && ![
        'language-snapshot',
        'usage',
        'content-snapshot',
        'weekly-snapshot',
        'compliance-report',
        'easy-wins-report',
        'confidence-gap-report',
      ].includes(from.name)
    ) {
      useInsights().setReportingMessage(otherReportToHiringReport);
    } else {
      useInsights().setReportingMessage({ header: '', message: '', beaconArticleId: '' });
    }

    next();
  } else {
    next({ name: 'recruiting-performance' });
  }
};
