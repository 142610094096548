import { defineStore } from 'pinia';
import { ref } from 'vue';
import { contentSnapshot } from '@/api/insights';
import { useAppLoading } from '@/stores/app/loading';
import { useAppAlerts } from '@/stores/app/alerts';
import { useInsightsProject } from '@/stores/insights/insightsProject';
import { mergeFilters } from '@/stores/insights/helpers';
import { useDatePicker } from '@/stores/filters/datePicker';
import { useFilters } from '@/stores/filters/filters';

export const useContentSnapshot = defineStore('contentSnapshot', () => {
  // State
  const contentStats = ref({});
  const totals = ref({});

  // Actions
  const setContentStats = (newContentStats) => {
    contentStats.value = newContentStats;
  };

  const setTotals = (newTotals) => {
    totals.value = newTotals;
  };

  const loadPage = async ({
    router,
    routeQuery,
    selectedFilters,
    selectedMyJobs,
    selectedDateWeek,
  }) => {
    try {
      useAppLoading().setLoading({ pageLoading: true, filtersLoading: true, listLoading: true });

      const parameters = {
        params: routeQuery,
        selectedMyJobs,
        selectedDateWeek,
        selectedFilters,
      };

      const {
        filters,
        dateWeek,
        owned,
        contentStats: respContentStats,
        totals: respTotals,
        projectImageUrl,
        projectName,
      } = await contentSnapshot(parameters);

      useDatePicker().setDateWeek(dateWeek);

      useFilters().updateFilters({ filters, myJobsEnabled: owned });

      setTimeout(() => {
        const queryFilters = mergeFilters(filters);
        const query = {
          week: dateWeek?.dateWeek || undefined,
          owned: owned?.value || undefined,
          ...queryFilters || {},
        };

        router.push({ query })
          .catch((err) => {
            if (err.message.startsWith('Avoided redundant navigation to current location')) {
              return;
            }
            throw err;
          });
      }, 0);

      setContentStats(respContentStats);
      useInsightsProject().setSharedProjectInfo({
        newProjectImageUrl: projectImageUrl,
        newProjectName: projectName,
      });
      setTotals(respTotals);
    } catch (e) {
      useAppAlerts().addAlert({ message: e.message });
      throw e;
    } finally {
      useAppLoading().setLoading({ pageLoading: false, filtersLoading: false, listLoading: false });
    }
  };

  return {
    // State
    contentStats,
    totals,

    // Actions
    setContentStats,
    setTotals,
    loadPage,
  };
});
