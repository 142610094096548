import {
  getPreviousDays,
  getPreviousWeek,
  getPreviousMonth,
  getQuarter,
  getQuarterName,
  getYear,
} from '@/helpers/date';

export const presets = {
  standard: [
    {
      label: 'Last 7 Days',
      range: getPreviousDays({ rangeStartOffset: 7 }),
    },
    {
      label: 'Last Week',
      range: getPreviousWeek(),
    },
    {
      label: 'Last Month',
      range: getPreviousMonth(),
    },
    {
      label: 'This Quarter',
      range: getQuarter(0),
    },
    {
      label: 'Last Quarter',
      range: getQuarter(1),
    },
  ],
  insights: [
    {
      label: 'Last 4 Weeks',
      range: getPreviousDays({ rangeStartOffset: 28 }),
    },
    {
      label: 'Last Month',
      range: getPreviousMonth(),
    },
    {
      label: 'This Quarter',
      range: getQuarter(0),
    },
    {
      label: 'Last Quarter',
      range: getQuarter(1),
    },
    {
      label: 'Two Quarters Ago',
      range: getQuarter(2),
    },
    {
      label: 'This Year',
      range: getYear(0),
    },
    {
      label: 'Last Year',
      range: getYear(1),
    },
  ],
  week: [
    {
      label: 'Last Week',
      range: getPreviousWeek(),
    },
  ],
  quarters: [
    {
      label: getQuarterName(1),
      range: getQuarter(1),
    },
    {
      label: getQuarterName(2),
      range: getQuarter(2),
    },
    {
      label: getQuarterName(3),
      range: getQuarter(3),
    },
    {
      label: getQuarterName(4),
      range: getQuarter(4),
    },
  ],
};
