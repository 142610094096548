export const fixNoHtmlBlocks = ({ fields = [] } = {}) => {
  fields.forEach((field) => {
    // eslint-disable-next-line no-param-reassign
    field.formatBlacklist = field.formatBlacklist || [];

    const sections = field.sections || [];
    sections.forEach(({ blocks }) => {
      blocks.forEach((block) => {
        // eslint-disable-next-line no-param-reassign
        block.html = block.html || '<p></p>';
      });
    });
  });
  return { fields };
};
