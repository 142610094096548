import theme from '@/configs/vuetify';

const {
  primaryColor,
  jaffaOrange,
  violetPurple,
  valenciaRed,
  pacificBlue,
  zirconGrey,
  energyYellow,
  royalFuscia,
  caribbeanGreen,
  flameOrange,
  secondaryColorLighten80,
  royalBlue,
} = theme.theme.themes.light.colors;

export const insightsColorMap = () => ({
  Inbound: violetPurple,
  Outbound: valenciaRed,
  Internal: pacificBlue,
  Referrals: energyYellow,
  Referral: energyYellow,
  Other: zirconGrey,
  Application: primaryColor,
  Screening: royalFuscia,
  Assessment: jaffaOrange,
  Offered: caribbeanGreen,
  Hired: royalBlue,
  Male: primaryColor,
  Female: flameOrange,
  Unknown: secondaryColorLighten80,
});
