import { defineStore } from 'pinia';
import { formModule } from '@/stores/form';

export const useInsightsShareForm = defineStore(
  'insightsShareForm',
  () => formModule({
    fields: {
      shareLink: {},
      note: {},
      userIds: {
        rules: [{
          name: 'required',
          errorMessage: 'Please select one or more users',
        }],
      },
    },
  }),
);
