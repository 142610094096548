import { defineStore, storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import { initReporting } from '@/api/insights';
import { useAppLoading } from '@/stores/app/loading';
import { useAppAlerts } from '@/stores/app/alerts';

export const useInsightsPermissions = defineStore('insightsPermissions', () => {
  const analyticsPremium = ref('pretrial');
  const analyticsPremiumSecondsLeft = ref(0);
  const genderReport = ref(true);
  const customerSuccessCalendarUrl = ref(null);
  const accountExecutiveEmail = ref('');

  const isInReportingPreTrial = computed(() => analyticsPremium.value === 'pretrial');
  const isInReportingTrial = computed(() => analyticsPremium.value === 'trial'
      && analyticsPremiumSecondsLeft.value > 0);
  const isInExpiredTrial = computed(() => analyticsPremium.value === 'expired');

  const gatePremiumInsights = computed(() => {
    const appLoadingStore = useAppLoading();
    const { pageLoading, sidebarLoading } = storeToRefs(appLoadingStore);

    return !pageLoading.value
      && !sidebarLoading.value
      && (isInReportingPreTrial.value || isInExpiredTrial.value);
  });

  const setReportingStatus = ({
    newAnalyticsPremium = analyticsPremium.value,
    newAnalyticsPremiumSecondsLeft = analyticsPremiumSecondsLeft.value,
    newGenderReport = genderReport.value,
    newCustomerSuccessCalendarUrl = customerSuccessCalendarUrl.value,
    newAccountExecutiveEmail = accountExecutiveEmail.value,
  }) => {
    analyticsPremium.value = newAnalyticsPremium;
    analyticsPremiumSecondsLeft.value = newAnalyticsPremiumSecondsLeft;
    genderReport.value = newGenderReport;
    customerSuccessCalendarUrl.value = newCustomerSuccessCalendarUrl;
    accountExecutiveEmail.value = newAccountExecutiveEmail;
  };

  const loadBreadCrumbs = async () => {
    try {
      const {
        analyticsPremium: newAnalyticsPremium,
        analyticsPremiumSecondsLeft: newAnalyticsPremiumSecondsLeft,
        genderReport: newGenderReport,
        customerSuccessCalendarUrl: newCustomerSuccessCalendarUrl,
        accountExecutiveEmail: newAccountExecutiveEmail,
      } = await initReporting();

      setReportingStatus({
        newAnalyticsPremium,
        newAnalyticsPremiumSecondsLeft,
        newGenderReport,
        newCustomerSuccessCalendarUrl,
        newAccountExecutiveEmail,
      });
    } catch (error) {
      useAppAlerts().addAlert({
        message: `Initialize reporting breadcrumbs - ${error.message}`,
        thisPageOnly: true,
      });
      throw error;
    }
  };

  return {
    analyticsPremium,
    analyticsPremiumSecondsLeft,
    genderReport,
    customerSuccessCalendarUrl,
    accountExecutiveEmail,
    isInReportingPreTrial,
    isInReportingTrial,
    isInExpiredTrial,
    gatePremiumInsights,
    setReportingStatus,
    loadBreadCrumbs,
  };
});
