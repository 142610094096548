export const gatesAndLogin = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "wizardframe" */ '@/components/shared/WizardFrame.vue'),
    children: [
      {
        path: '/signup',
        name: 'signup',
        component: () => import(/* webpackChunkName: "signup" */ '@/views/Signup.vue'),
        meta: { authStateRequired: 'unauthenticated', title: 'Create a New Account' },
      },
      {
        path: '/provide-work-email',
        name: 'provide-work-email',
        props: true,
        component: () => import(/* webpackChunkName: "provideworkemail" */ '@/views/ProvideWorkEmail.vue'),
        meta: { authStateRequired: 'unauthenticated', title: 'Provide Your Work Email' },
      },
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
        meta: { authStateRequired: 'unauthenticated', title: 'Log Into Your Account' },
      },
      {
        path: '/join-dashboard',
        name: 'join-dashboard',
        component: () => import(/* webpackChunkName: "joindashboard" */ '@/views/JoinDashboard.vue'),
        meta: { authStateRequired: 'unauthenticated', title: 'Create a New Account' },
      },
      {
        path: '/ie-deprecated',
        name: 'ie-deprecated',
        component: () => import(/* webpackChunkName: "iedeprecated" */ '@/views/IEDeprecated.vue'),
        meta: { authStateRequired: 'either', title: 'Internet Explorer Deprecated' },
      },
      {
        path: '',
        name: 'signup-gate',
        component: () => import('@/views/SignupGate.vue'),
        children: [
          {
            path: '/verify-email',
            name: 'verify-email',
            component: () => import(/* webpackChunkName: "verifyemail" */ '@/views/VerifyEmail.vue'),
            meta: { title: 'Verify Email' },
          },
          {
            path: '/sso-locked',
            name: 'sso-locked',
            component: () => import(/* webpackChunkName: "sso-locked" */ '@/views/SsoLocked.vue'),
            meta: { title: 'SSO Locked' },
          },
          {
            path: '/terminated-generic',
            name: 'terminated-generic',
            component: () => import(/* webpackChunkName: "terminated-generic" */ '@/views/TerminatedGeneric.vue'),
            meta: { title: 'Ineligible' },
          },
          {
            path: '/terminated-hr-tech',
            name: 'terminated-hr-tech',
            component: () => import(/* webpackChunkName: "terminatedhrtech" */ '@/views/TerminatedHrTech.vue'),
            meta: { title: 'Ineligible (HR Tech)' },
          },
          {
            path: '/terminated-no-ats',
            name: 'terminated-no-ats',
            component: () => import(/* webpackChunkName: "terminatednoats" */ '@/views/TerminatedNoAts.vue'),
            meta: { title: 'Ineligible (No ATS)' },
          },
          {
            path: '/terminated-suspended',
            name: 'terminated-suspended',
            component: () => import(/* webpackChunkName: "terminatedsuspended" */ '@/views/TerminatedSuspended.vue'),
            meta: { title: 'Ineligible (Suspended)' },
          },
          {
            path: '/trial-paused',
            name: 'trial-paused',
            component: () => import(/* webpackChunkName: "trialpaused" */ '@/views/TrialPaused.vue'),
            meta: { title: 'Trial Paused' },
          },
          {
            path: '/trial-expired',
            name: 'trial-expired',
            component: () => import(/* webpackChunkName: "trialexpired" */ '@/views/TrialExpired.vue'),
            meta: { title: 'Trial Expired' },
          },
          {
            path: '/trial-renew',
            name: 'trial-renew',
            component: () => import(/* webpackChunkName: "trialrenew" */ '@/views/TrialRenew.vue'),
            meta: { title: 'Trial Renew' },
          },
          {
            path: '/billing-expired',
            name: 'billing-expired',
            component: () => import(/* webpackChunkName: "billingexpired" */ '@/views/BillingExpired.vue'),
            meta: { title: 'Billing Expired' },
          },
          {
            path: '/account-on-hold',
            name: 'account-on-hold',
            component: () => import(/* webpackChunkName: "saleshold" */ '@/views/SalesHold.vue'),
            meta: { title: 'Sales Hold' },
          },
          {
            path: '/technical-hold',
            name: 'technical-hold',
            component: () => import(/* webpackChunkName: "technicalhold" */ '@/views/TechnicalHold.vue'),
            meta: { title: 'Technical Hold' },
          },
          {
            path: '/account-being-setup',
            name: 'account-being-setup',
            component: () => import(/* webpackChunkName: "accountbeingsetup" */ '@/views/AccountBeingSetup.vue'),
            meta: { title: 'Account Being Setup' },
          },
          {
            path: '/demo-request-hold',
            name: 'demo-request-hold',
            component: () => import(/* webpackChunkName: "demorequesthold" */ '@/views/DemoRequestHold.vue'),
            meta: { title: 'Demo Request Hold' },
          },
          {
            path: '/maintenance',
            name: 'maintenance',
            component: () => import(/* webpackChunkName: "maintenance" */ '@/views/Maintenance.vue'),
            meta: { authStateRequired: 'either', title: 'App Maintenance' },
          },
        ],
      },
      {
        path: '/create-password',
        name: 'create-password',
        props: true,
        component: () => import(/* webpackChunkName: "createpassword" */ '@/views/CreatePassword.vue'),
        meta: { authStateRequired: 'either', title: 'Create Password' },
      },
      {
        path: '/password-reset',
        name: 'password-reset',
        component: () => import(/* webpackChunkName: "passwordreset" */ '@/views/PasswordReset.vue'),
        meta: { authStateRequired: 'either', title: 'Password Reset' },
      },
      {
        path: '/password-reset-success',
        name: 'password-reset-success',
        props: true,
        component: () => import(/* webpackChunkName: "passwordresetsuccess" */ '@/views/PasswordResetSuccess.vue'),
        meta: { authStateRequired: 'either', title: 'Password Reset Success' },
      },
      {
        path: '/about-your-company',
        name: 'about-your-company',
        component: () => import(/* webpackChunkName: "aboutyourcompany" */ '@/views/AboutYourCompany.vue'),
        meta: { title: 'About Your Company' },
      },
      {
        path: '/terms-of-service/:id?',
        name: 'terms-of-service',
        component: () => import(/* webpackChunkName: "termsofservice" */ '@/views/TermsOfService.vue'),
        meta: { title: 'Terms of Service' },
      },
      {
        path: '/demo-request',
        name: 'demo-request',
        component: () => import(/* webpackChunkName: "demorequest" */ '@/views/DemoRequest.vue'),
        meta: { authStateRequired: 'unauthenticated', title: 'Demo Request' },
      },
      {
        path: '/demo-provide-your-work-email',
        name: 'demo-provide-your-work-email',
        props: true,
        component: () => import(/* webpackChunkName: "demoprovideyourworkemail" */ '@/views/DemoProvideYourWorkEmail.vue'),
        meta: { title: 'Demo Provide Your Work Email' },
      },
      {
        path: '/demo-about-your-company',
        name: 'demo-about-your-company',
        props: true,
        component: () => import(/* webpackChunkName: "demoaboutyourcompany" */ '@/views/DemoAboutYourCompany.vue'),
        meta: { title: 'Demo About Your Company' },
      },
      {
        path: '/demo-request-received',
        name: 'demo-request-received',
        props: true,
        component: () => import(/* webpackChunkName: "demorequestreceived" */ '@/views/DemoRequestReceived.vue'),
        meta: { authStateRequired: 'unauthenticated', title: 'Demo Request Received' },
      },
      {
        path: '/demo-ready',
        name: 'demo-ready',
        component: () => import(/* webpackChunkName: "demoready" */ '@/views/DemoReady.vue'),
        meta: { authStateRequired: 'either', title: 'Demo Ready' },
      },
    ],
  },
  {
    path: '/mobile-not-supported',
    name: 'mobile-not-supported',
    component: () => import(/* webpackChunkName: "mobilenotsupported" */ '@/views/MobileNotSupported.vue'),
    meta: { authStateRequired: 'either', title: 'Mobile Not Supported' },
  },
];
