import { formatTooltipValue } from '@/helpers/formatters';
import theme from '@/configs/vuetify';

const {
  secondaryColor, pureWhite, energyYellow, zirconGrey,
} = theme.theme.themes.light.colors;

const backgroundTextColorExceptions = {
  [energyYellow]: secondaryColor,
  [zirconGrey]: secondaryColor,
};

export const dataLabelPercentages = {
  'plugins.datalabels.color': function colorCallback(context) {
    const max = Math.max(...context.dataset.data.filter((value) => value));
    const value = context.dataset.data[context.dataIndex];
    const percentOfSpace = (value / max) * 100;
    const backgroundColor = context.dataset.backgroundColor[context.dataIndex];
    const barTextColor = backgroundTextColorExceptions[backgroundColor] || pureWhite;

    return percentOfSpace < 15 ? secondaryColor : barTextColor;
  },
  'plugins.datalabels.anchor': 'end',
  'plugins.datalabels.align': function alignCallback(context) {
    const max = Math.max(...context.dataset.data.filter((value) => value));
    const value = context.dataset.data[context.dataIndex];
    const percentOfSpace = (value / max) * 100;

    if (Number.isNaN(percentOfSpace)) return 'end';

    return percentOfSpace < 15 ? 'end' : 'start';
  },
  'plugins.datalabels.formatter': function formatterCallback(value) {
    if (Number.isNaN(value)) return '';
    return formatTooltipValue(value, 'percent');
  },
};

export const xTickPercentFormat = {
  'scales.x.ticks.callback': function xTickCallback(value) {
    return `${value}%`;
  },
};
