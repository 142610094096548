export const legacy = [
  {
    name: 'email-legacy',
    path: 'email',
    redirect: (to) => ({ name: 'email', query: to.query }),
  },
  {
    name: 'published-legacy',
    path: 'published',
    redirect: (to) => ({ name: 'published', query: to.query }),
  },
  {
    name: 'unpublished-legacy',
    path: 'unpublished',
    redirect: (to) => ({ name: 'unpublished', query: to.query }),
  },
  {
    name: 'draft-legacy',
    path: 'draft',
    redirect: (to) => ({ name: 'draft', query: to.query }),
  },
  {
    name: 'closed-legacy',
    path: 'closed',
    redirect: (to) => ({ name: 'unpublished', query: { ...to.query, closedRedirect: 'true' } }),
  },
  {
    path: '/analytics/',
    name: 'analytics',
    redirect: { name: 'recruiting-performance' },
    children: [
      {
        name: 'legacy-weekly-snapshot',
        path: 'weekly-snapshot',
        redirect: (to) => ({ name: 'weekly-snapshot', query: to.query }),
      },
      {
        name: 'legacy-recruiting-performance',
        path: 'recruiting-performance',
        redirect: (to) => ({ name: 'recruiting-performance', query: to.query }),
      },
      {
        name: 'legacy-source-performance',
        path: 'source-performance',
        redirect: (to) => ({ name: 'overall-report', query: to.query }),
      },
      {
        name: 'legacy-language-snapshot',
        path: 'language-snapshot',
        redirect: (to) => ({ name: 'language-snapshot', query: to.query }),
      },
      {
        name: 'legacy-content-snapshot',
        path: 'content-snapshot',
        redirect: (to) => ({ name: 'content-snapshot', query: to.query }),
      },
      {
        name: 'legacy-usage',
        path: 'usage',
        redirect: (to) => ({ name: 'usage', query: to.query }),
      },
    ],
  },
  {
    path: '/reporting/',
    name: 'reporting',
    redirect: { name: 'recruiting-performance' },
    children: [
      {
        name: 'legacy-hiring-dynamics',
        path: 'hiring-dynamics',
        redirect: (to) => ({ name: 'hiring-dynamics', query: to.query }),
      },
      {
        name: 'legacy-requisition-efficiency',
        path: 'requisition-efficiency',
        redirect: (to) => ({ name: 'requisition-efficiency', query: to.query }),
      },
      {
        name: 'legacy-candidate-pool-metrics',
        path: 'candidate-pool-metrics',
        redirect: (to) => ({ name: 'candidate-pool-metrics', query: to.query }),
      },
      {
        name: 'legacy-overall-report',
        path: 'overall-report',
        redirect: (to) => ({ name: 'overall-report', query: to.query }),
      },
      {
        name: 'legacy-inbound-report',
        path: 'legacy-inbound-report',
        redirect: (to) => ({ name: 'inbound-report', query: to.query }),
      },
      {
        name: 'legacy-outbound-report',
        path: 'outbound-report',
        redirect: (to) => ({ name: 'outbound-report', query: to.query }),
      },
      {
        name: 'legacy-gender-report',
        path: 'gender-report',
        redirect: (to) => ({ name: 'gender-report', query: to.query }),
      },
      {
        name: 'legacy-drop-off',
        path: 'drop-off',
        redirect: (to) => ({ name: 'drop-off', query: to.query }),
      },
      {
        name: 'legacy-pass-through',
        path: 'pass-through',
        redirect: (to) => ({ name: 'pass-through', query: to.query }),
      },
      {
        name: 'legacy-compliance-report',
        path: 'compliance-report',
        redirect: (to) => ({ name: 'compliance-report', query: to.query }),
      },
      {
        name: 'legacy-easy-wins-report',
        path: 'easy-wins-report',
        redirect: (to) => ({ name: 'easy-wins-report', query: to.query }),
      },
      {
        name: 'legacy-hiring-report',
        path: 'hiring-report',
        redirect: (to) => ({ name: 'hiring-report', query: to.query }),
      },
    ],
  },
];
