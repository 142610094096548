import { useAppAuthData } from '@/stores/app/authData';

export const getSafeError = (error) => (error.message.includes('reqdb') ? 'Internal Server Error' : error.message);

export const dashboardsPages = [
  'hiring-dynamics',
  'requisition-efficiency',
  'candidate-pool-metrics',
  'overall-report',
  'inbound-report',
  'drop-off',
  'pass-through',
  'outbound-report',
  'gender-report',
  'hiring-report',
];

export const mergeFilters = (tempFilters) => {
  const filters = {};
  tempFilters.forEach((filter) => {
    if (filter.value.length) {
      filters[filter.id] = filter.value.map((value) => value.id);
    }
  });
  return filters;
};

export const isError = (widget) => {
  if (widget.id === 'gender-distribution-of-hires') {
    return !useAppAuthData().authData.canSeeGenderReport;
  }

  return false;
};
