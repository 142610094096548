import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useAppLoading = defineStore('appLoading', () => {
  const state = {
    filtersLoading: false,
    halfTopBarLoading: false,
    pageLoading: false,
    componentLoading: false,
    sidebarLoading: false,
    listLoading: false,
    modalLoading: false,
  };

  const flags = Object.keys(state)
    .reduce((res, key) => ({ ...res, [key]: ref(state[key]) }), {});

  const setLoading = (loading) => {
    Object.keys(loading).forEach((flag) => {
      flags[flag].value = loading[flag];
    });
  };

  return { ...flags, setLoading };
});
