import {
  getRequest, postRequest, postRequestWithSuccessErrorsResponse, setCsrfToken,
} from './request';
import { fixLeverList } from './helpers/fixLeverList';
import { fixNoHtmlBlocks } from './helpers/fixNoHtmlBlocks';
/* Leaving comment here for future reference
import {
  transformEmailFieldsToEditorFormat, transformEmailFieldsToBaseFormat
} from './helpers/fixEmailEditorFields'; */

export const vueAuthData = async () => {
  const data = await getRequest({ url: '/vue/auth-data' });

  if (data.csrfToken) {
    setCsrfToken(data.csrfToken);
  }

  return {
    atsName: data.atsName,
    atsId: data.atsId,
    integrationTypeKey: data.integrationTypeKey,
    integrationType: data.integrationType,
    isSuperuser: data.isSuperuser,
    isLoggedIn: data.isLoggedIn,
    userType: data.userType,
    userSetupStatus: data.userSetupStatus,
    companyName: data.companyName,
    projectName: data.projectName,
    projectStatus: data.projectStatus,
    projectSetupStatus: data.projectSetupStatus,
    projectMaintenanceMode: data.projectMaintenanceMode,
    projectImageUrl: data.projectImageUrl,
    canTeam: data.canTeam,
    canTeamAdmin: data.canTeamAdmin,
    canEditTemplates: data.canEditTemplates,
    canBilling: data.canBilling,
    canIntegrations: data.canIntegrations,
    canGreenhouseRoles: data.canGreenhouseRoles,
    canCustomFields: data.canCustomFields,
    // no turning back
    editorV5Enabled: true,
    extensionJobsEnabled: data.extensionJobsEnabled,
    extensionEmailsEnabled: data.extensionEmailsEnabled,
    showEmailPage: data.showEmailPage,
    showCampaignsPage: data.showCampaignsPage,
    showIntegrationsPage: data.showIntegrationsPage,
    isTrackingEnabled: data.isTrackingEnabled,
    flashMessages: data.flashMessages,
    loginMethod: data.loginMethod,
    oauthClient: data.oauthClient,
    ssoName: data.ssoName,
    analyticsPremium: data.analyticsPremium,
    executiveInsightsEnabled: data.executiveInsightsEnabled,
    requisitionPagesEnabled: data.requisitionPagesEnabled,
    firstDraftEnabled: data.firstDraftEnabled,
    commentingEnabled: data.commentingEnabled,
    canSeeGenderReport: data.canSeeGenderReport,
    genderReportEnabled: data.genderReportEnabled,
    userId: data.userId,
    appMaintenanceMode: data.appMaintenanceMode,
    isApplicationReportingEnabled: data.isApplicationReportingEnabled,
    showWeeklyReports: data.showWeeklyReports,
    firstName: data.firstName,
    lastName: data.lastName,
    role: data.role,
    email: data.email,
    appVersion: data.appVersion,
    personaKey: data.personaKey,
    isInInsightsTrial: data.isInInsightsTrial,
    hasInstalledDA: data.hasInstalledDA,
  };
};

const getDocumentFields = (data) => ({
  id: data.id,
  detached: data.detached,
  title: data.title,
  company: data.company,
  location: data.location,
  reqReportUrl: data.reqReportUrl,
  description: data.description,
  score: data.score,
  bodyMessages: data.bodyMessages,
  tags: data.tags,
  specificityKey: data.specificityKey,
  specificityData: data.specificityData,
  scales: data.scales,
  jobStatus: data.jobStatus,
  scoreImpact: data.scoreImpact,
  ranking: data.ranking,
  isPrivate: data.isPrivate,
  wordCount: data.wordCount,
  // TODO: fix camel-case
  ats_requisition_id: data.ats_requisition_id,
  flexible_id: data.flexible_id || '',
  type_key: data.type_key,
  description_delta: data.description_delta,
  description_json: fixNoHtmlBlocks(data.description_json),
  estimate_low: data.estimate_low,
  estimate_high: data.estimate_high,
  isSyncable: data.is_syncable,
  isPermissionTogglable: data.is_permission_togglable,
  isHidden: data.is_hidden,
});

export const vueGet = async ({ id, revision }) => {
  const searchParams = new URLSearchParams();

  if (typeof id !== 'undefined') searchParams.append('id', id);
  if (revision && typeof revision !== 'undefined') searchParams.append('revision', revision);
  if (window.sessionStorage.shareJobId === id) {
    const { shareKey } = window.sessionStorage;
    if (shareKey) searchParams.append('key', shareKey);
  }

  const data = await getRequest({ url: `/vue/get?${searchParams}` });
  return {
    ...getDocumentFields(data),
    success: data.success,
    allRevisions: data.allRevisions,
  };
};

export const vueShareLink = async ({
  jobId,
  firstName,
  lastName,
  email,
  emailExisting,
  sendEmail,
}) => {
  const data = await postRequest({
    url: '/vue/share-link',
    body: {
      id: jobId,
      firstname: firstName,
      lastname: lastName,
      email,
      sendemail: sendEmail,
      'email-existing': emailExisting,
    },
  });

  return { type: data.type, url: data.url, users: data.users };
};

export const vueNotify = async ({
  jobId,
  userId,
  notificationType,
}) => postRequestWithSuccessErrorsResponse({
  url: '/vue/notify',
  body: { job_id: jobId, user_id: userId, notification_type: notificationType },
});

export const vueOnboard = async ({
  userId,
}) => postRequestWithSuccessErrorsResponse({
  url: '/vue/onboard',
  body: { user: userId },
});

export const vueSyncWithAts = async ({ jobId, integrationTypeKey }) => {
  const data = await postRequest({
    url: `/vue/sync-with-ats?id=${jobId}&integration_type_key=${integrationTypeKey}`,
  });
  return { status: data.status };
};

export const vueRescore = async ({
  id,
  detached,
  hash,
  key,
  title,
  company,
  location,
  description,
  descriptionDelta,
  descriptionJson,
  flexibleId,
}) => {
  /* If email, transform description JSON back into format backend expects */
  const transformedJson = JSON.stringify(fixLeverList(descriptionJson));

  const data = await postRequest({
    url: '/vue/rescore',
    body: {
      id,
      detached,
      key,
      hash,
      title,
      location,
      company,
      flexible_id: flexibleId,
      description,
      description_delta: descriptionDelta,
      description_json: transformedJson,
    },
  });

  return {
    ...getDocumentFields(data),
    hash: data.hash,
    allRevisions: data.allRevisions,
  };
};

export const vueHubSpotToken = async () => {
  const data = (await getRequest({ url: '/vue/hubspot-token' })) || {};
  return {
    email: data.email,
    token: data.token,
  };
};

export const vueWorkrampLogin = async () => {
  const { loginUrl } = (await getRequest({ url: '/vue/workramp-login' })) || {};
  return { loginUrl };
};

export const getAtsParams = async (atsId) => {
  const { fields, templateList } = await getRequest({
    url: `/vue/get-ats-params?ats_id=${atsId}`,
  });

  return { fields, templateList };
};

export const vueCordToken = async ({ jobId } = {}) => {
  const body = {};

  if (jobId && window.sessionStorage.shareJobId === jobId) {
    body.shareKey = window.sessionStorage.shareKey;
    body.jobId = jobId;
  }

  return postRequest({ url: '/vue/cord-token', body });
};
