import { getRequest } from './request';

export const recruitGetSimilarJobs = async ({ jobId }) => {
  const searchParams = new URLSearchParams();

  if (typeof jobId !== 'undefined') searchParams.append('id', jobId);
  if (window.sessionStorage.shareJobId === jobId) {
    const { shareKey } = window.sessionStorage;
    if (shareKey) searchParams.append('key', shareKey);
  }

  const data = await getRequest({ url: `/recruit/similar-jobs?${searchParams}` });
  const { similar_jobs: similarJobs, similar_jobs_from_project: projectJobs } = data || {};
  return { similarJobs: [...(projectJobs || []), ...(similarJobs || [])] };
};

export const recruitRevert = async ({ jobId, revisionId }) => {
  const searchParams = new URLSearchParams();

  if (typeof jobId !== 'undefined') searchParams.append('id', jobId);
  if (typeof revisionId !== 'undefined') searchParams.append('revision', revisionId);
  if (window.sessionStorage.shareJobId === jobId) {
    const { shareKey } = window.sessionStorage;
    if (shareKey) searchParams.append('key', shareKey);
  }

  const data = await getRequest({ url: `/recruit/revert?${searchParams}` });
  return { success: data.success, errors: data.errors };
};

export const recruitInitEditor = async ({ id }) => {
  const searchParams = new URLSearchParams();

  if (typeof id !== 'undefined') searchParams.append('id', id);
  if (window.sessionStorage.shareJobId === id) {
    const { shareKey } = window.sessionStorage;
    if (shareKey) searchParams.append('key', shareKey);
  }

  const data = await getRequest({ url: `/recruit/init-editor?${searchParams}` });
  return {
    success: data.success,
    body: {
      jobId: String(data.body.jobId),
      atsRequisitionId: data.body.atsRequisitionId,
      shareKey: data.body.shareKey,
      showRedBorderAroundTitle: data.body.showRedBorderAroundTitle,
      ownersActive: data.body.ownersActive,
      ownersLead: data.body.ownersLead,
      jobStatus: data.body.jobStatus,
      jobStatusLabel: data.body.jobStatusLabel,
      jobIntegrationType: data.body.jobIntegrationType,
      projectIntegrationType: data.body.projectIntegrationType,
      isWorkdaySyncAllowed: Boolean(data.body.isWorkdaySyncAllowed),
      isSharePage: data.body.isSharePage,
      baseMixpanelParams: JSON.parse(data.body.baseMixpanelParams || null),
      hasShareUsers: data.body.hasShareUsers,
      companyName: data.body.companyName,
      showInvite: data.body.showInvite,
      atsTotalApplicants: data.body.atsTotalApplicants,
      superuser: data.body.superuser,
      isLinked: data.body.isLinked,
      isHidden: data.body.isHidden,
      projectIntegrationTypeKey: data.body.projectIntegrationTypeKey,
      integrationTypeKey: data.body.integrationTypeKey,
      atsJobId: data.body.atsJobId,
      tapwageId: data.body.tapwageId,
      isOpen: data.body.isOpen,
      isPublished: data.body.isPublished,
      hasIntegration: data.body.hasIntegration,
      atsEditUrl: data.body.atsEditUrl,
      atsName: data.body.atsName,
      atsIcon: data.body.atsIcon,
      shareNames: data.body.shareNames,
      validUsers: data.body.validUsers,
      isApplicationReportingEnabled: data.body.isApplicationReportingEnabled,
      flashMessages: data.body.flashMessages,
      isEditable: data.body.isEditable,
      documentType: data.body.documentType,
      templateList: data.body.templateList,
      // TODO: fix camel-case
      revision_id: data.body.revision_id,
      isExternal: data.body.isExternal,
      isEvergreen: data.body.isEvergreen,
      // these flags duplicated here for shared jobs, as authData unavailable in such case
      editorV5: true,
      firstDraftEnabled: data.body.firstDraftEnabled,
      commentingEnabled: data.body.commentingEnabled,
      thirdPartyConfigs: data.body.thirdPartyConfigs,
    },
  };
};

export const recruitInitNavbar = async ({ jobId }) => {
  const searchParams = new URLSearchParams();

  if (jobId) searchParams.append('id', jobId);
  if (window.sessionStorage.shareJobId === jobId) {
    const { shareKey } = window.sessionStorage;
    if (shareKey) searchParams.append('key', shareKey);
  }

  const data = await getRequest({ url: `/recruit/init-navbar?${searchParams}` });
  return {
    success: data.success,
    data: {
      inviteKey: data.data.inviteKey,
      canSuperuser: data.data.canSuperuser,
      hasProject: data.data.hasProject,
      isSegmentA: data.data.isSegmentA,
      showEmailPage: data.data.showEmailPage,
      showCampaignsPage: data.data.showCampaignsPage,
      showIntegrationsPage: data.data.showIntegrationsPage,
      showWeeklyReports: data.data.showWeeklyReports,
      isApplicationReportingEnabled: data.data.isApplicationReportingEnabled,
      canTeam: data.data.canTeam,
      canEditTemplates: data.data.canEditTemplates,
      canBilling: data.data.canBilling,
      templateList: data.data.templateList,
      campaignsList: data.data.campaignsList,
      isBilling: data.data.isBilling,
      projectIntegrationType: data.data.projectIntegrationType,
      projectIntegrationTypeKey: data.data.projectIntegrationTypeKey,
      atsName: data.data.atsName,
      atsCreateUrl: data.data.atsCreateUrl,
      userId: data.data.userId,
      projectId: data.data.projectId,
      isChromeExtensionEnabled: data.data.isChromeExtensionEnabled,
      isChromeExtensionEmailsEnabled: data.data.isChromeExtensionEmailsEnabled,
    },
  };
};
