import { defineStore } from 'pinia';
import { ref } from 'vue';
import defaultShow from './defaultShow';

export const useModals = defineStore('modals', () => {
  const show = ref(defaultShow());

  const resetModals = () => {
    const newDefault = defaultShow();

    Object.keys(newDefault).forEach((key) => {
      show.value[key] = newDefault[key];
    });
  };

  const setModal = ({ field, value = false }) => {
    show.value[field] = value;
  };

  return { show, resetModals, setModal };
});
