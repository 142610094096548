import theme from '@/configs/vuetify';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { snakeCase } from 'lodash-es';
import { roundFloat } from '@/helpers/formatters';
import { dataLabelPercentages, xTickPercentFormat } from '@/api/helpers/insights/commonChartConfigs';
import {
  getWidgets, getTables, transformCategoryLabel,
} from './transformers';
import {
  calculatePassthroughPercentages,
  getWidgetAndTableLabels,
} from './common';

const {
  violetPurple,
  violetPurpleLighten20,
  violetPurpleLighten40,
  violetPurpleLighten60,
  violetPurpleLighten80,
  zirconGrey,
} = theme.theme.themes.light.colors;

/** defaultWidgetConfig manages the frontend config for each api widget */
const defaultWidgetConfig = [
  {
    id: 'top-sources-by-application',
    tooltipKey: 'inbound-source-for-apps',
    itemLabel: 'What inbound sources do our applications come from?',
    groupLabel: '',
    labels: [],
    values: [],
    type: 'horizontalBarChart',
    colors: [[
      violetPurple,
      violetPurpleLighten20,
      violetPurpleLighten40,
      violetPurpleLighten60,
      violetPurpleLighten80,
      zirconGrey,
    ]],
    optionsOverride: {
      ...xTickPercentFormat,
      ...dataLabelPercentages,
    },
    pluginsOverride: [ChartDataLabels],
  },
  {
    id: 'top-sources-by-hire',
    tooltipKey: 'inbound-source-for-hires',
    itemLabel: 'What inbound sources do our hires come from?',
    groupLabel: '',
    labels: [],
    values: [],
    type: 'horizontalBarChart',
    colors: [[
      violetPurple,
      violetPurpleLighten20,
      violetPurpleLighten40,
      violetPurpleLighten60,
      violetPurpleLighten80,
      zirconGrey,
    ]],
    optionsOverride: {
      ...xTickPercentFormat,
      ...dataLabelPercentages,
    },
    pluginsOverride: [ChartDataLabels],
  },
];

/**
 * fieldMappings is used to map the index of a widget from `defaultWidgetConfig`
 * to which values it will need to pull off the api object, labels, and formatting
 */
const fieldMappings = [
  {
    value: 'count',
    label: 'count',
    labelFormat: 'percent',
  },
  {
    value: 'count',
    label: 'count',
    labelFormat: 'percent',
  },
];

const tableLabelForLoading = 'Inbound Report';
const tableKeys = ['applied', 'screening', 'assessment', 'offer', 'hired'];
const tableLabels = ['Paid vs Free', 'General vs Niche'];
const tableDataKeys = ['paidVsFree', 'generalVsNiche'];
const metricsToUse = [
  ['Paid', 'Free'],
  ['Niche', 'General'],
];
const displayOptions = {
  passthroughPercentages: {
    showTotals: true,
    showRows: true,
  },
};

const categoryLabels = {
  General: 'General Job Boards',
  Niche: 'Niche Job Boards',
  Other: 'Other Inbound',
  Paid: 'Paid Job Boards',
  Free: 'Free Job Boards',
};

const inboundTables = {
  firstTable: { label: 'Paid vs Free' },
  secondTable: { label: 'General vs Niche' },
};

const getInboundMetrics = (data, index) => {
  const metrics = {};

  data.forEach((instance) => {
    if (metricsToUse[index].includes(instance.label)) {
      Object.keys(instance.all).forEach((metricKey) => {
        const snakeCaseKey = snakeCase(`${instance.label}_${metricKey}`);

        metrics[snakeCaseKey] = roundFloat(
          instance.all[metricKey].percentage * 100,
        );
      });
    }
  });

  return metrics;
};

const getTablesWithMetrics = (tables, data) => {
  const tablesWithMetrics = {};

  Object.keys(tables).forEach((key, i) => {
    tablesWithMetrics[key] = {
      ...tables[key],
      metrics: getInboundMetrics(data[tableDataKeys[i]], i),
    };
  });

  return tablesWithMetrics;
};

const getInboundTables = (tableData) => {
  const tablesPreformatting = getTables(tableData, {
    tableKeys, tableLabels, calcTotals: true, tableDataKeys,
  });
  const tables = transformCategoryLabel(tablesPreformatting, inboundTables, categoryLabels);
  const tablesWithMetrics = getTablesWithMetrics(tables, tableData);
  const tablesWithPassthroughPercentages = calculatePassthroughPercentages(
    tablesWithMetrics,
    displayOptions.passthroughPercentages,
  );

  return tablesWithPassthroughPercentages;
};

/**
 * getInboundReportData decouples the totals data at index `0`
 * and then handles failed and successful requests. It generates the widget for successful requests
 * @param results
 * @param results.chartData
 * @param results.tableData
 * @returns {object} { widgets, totals }
 */
export const getInboundReportData = ({ chartData, tableData }) => {
  const widgets = getWidgets(chartData, { defaultWidgetConfig, fieldMappings, aggregatedOtherLabel: 'Other Inbound Sources' });
  const labelFormats = fieldMappings.map(({ labelFormat }) => labelFormat);
  const tables = getInboundTables(tableData);

  return {
    widgets, totals: {}, labelFormats, tables, displayOptions,
  };
};

export const getInboundReportLabelsForLoading = getWidgetAndTableLabels(
  defaultWidgetConfig,
  tableLabelForLoading,
);
