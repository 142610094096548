import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useAppAlerts } from '@/stores/app/alerts';

export const useAppBase = defineStore('appBase', () => {
  // State
  const appHeaderHeight = ref(0);
  const windowWidth = ref(1200);
  const scrollY = ref(0);
  const frameScrollTop = ref(0);
  const browserBackOrForwardNavigation = ref(false);
  const pageHasFilters = ref({});
  const pageHasCSVExport = ref({});
  const pageHasActionableInsightCsvExport = ref({});
  const pageHasPNGExport = ref({});
  const pageHidesFilterChips = ref({});
  const pageDisabledFilters = ref({});
  const pageIsShareable = ref({});
  const pageHideDatePicker = ref({});

  // Actions
  const setWindowWidth = ({ width }) => {
    windowWidth.value = width;
  };

  const setScroll = ({ scrollY: newScrollY }) => {
    scrollY.value = newScrollY;
  };

  const setScrollFrame = ({ scrollTop }) => {
    frameScrollTop.value = scrollTop;
  };

  const showFilters = ({ route, value = true }) => {
    pageHasFilters.value = { [route]: value };
  };

  const showCSVExport = ({ route, value = true }) => {
    pageHasCSVExport.value = { [route]: value };
  };

  const showActionalbeInsightsCSVExport = ({ route, value = true }) => {
    pageHasActionableInsightCsvExport.value = { [route]: value };
  };

  const showPNGExport = ({ route, value = true }) => {
    pageHasPNGExport.value = { [route]: value };
  };

  const showShareButton = ({ route, value = true }) => {
    pageIsShareable.value = { [route]: value };
  };

  const hideFilterChips = ({ route, value = true }) => {
    pageHidesFilterChips.value = { [route]: value };
  };

  const disableFilters = ({ route, value = true }) => {
    pageDisabledFilters.value = { [route]: value };
  };

  const hideDatePicker = ({ route, value = true }) => {
    pageHideDatePicker.value = { [route]: value };
  };

  const setAppHeaderHeight = (height) => {
    appHeaderHeight.value = height;
  };

  // does nothing, but other modules could use it
  // to subscribe to key events
  // eslint-disable-next-line no-unused-vars
  const keyEvent = ({ type, event }) => {};

  const checkClipboardPermissions = async () => {
    try {
      const { state: readState } = await navigator.permissions.query({ name: 'clipboard-read' });
      const { state: writeState } = await navigator.permissions.query({ name: 'clipboard-write' });

      if (readState === 'denied' || writeState === 'denied') {
        useAppAlerts().addAlert({
          message: 'Clipboard copying/pasting may not work while your clipboard permissions are disabled for this site.',
          onlyOnceId: 'clipboard_error',
          type: 'info',
        });
      }
      // eslint-disable-next-line no-empty
    } catch (ignore) {}
  };

  const setBrowserBackOrForwardNavigation = (boolean = false) => {
    browserBackOrForwardNavigation.value = boolean;

    if (boolean) {
      setTimeout(() => {
        browserBackOrForwardNavigation.value = false;
      }, 0);
    }
  };

  const showFilterBarSettings = ({
    route, options = [],
  }) => {
    const params = { route };

    showFilters(params);

    showCSVExport({ ...params, value: options.includes('CSVExport') });

    showActionalbeInsightsCSVExport({ ...params, value: options.includes('actionableInsightsCSVExport') });

    showPNGExport({ ...params, value: options.includes('PNGExport') });

    showShareButton({ ...params, value: options.includes('shareable') });

    hideFilterChips({ ...params, value: options.includes('hideFilterChips') });

    disableFilters({ ...params, value: options.includes('disableFilters') });

    hideDatePicker({ ...params, value: options.includes('hideDatePicker') });
  };

  return {
    // State
    appHeaderHeight,
    windowWidth,
    scrollY,
    frameScrollTop,
    browserBackOrForwardNavigation,
    pageHasFilters,
    pageHasCSVExport,
    pageHasActionableInsightCsvExport,
    pageHasPNGExport,
    pageHidesFilterChips,
    pageDisabledFilters,
    pageIsShareable,
    pageHideDatePicker,

    // Actions
    setWindowWidth,
    setScroll,
    setScrollFrame,
    showFilters,
    showCSVExport,
    showActionalbeInsightsCSVExport,
    showPNGExport,
    showShareButton,
    hideFilterChips,
    disableFilters,
    hideDatePicker,
    setAppHeaderHeight,

    keyEvent,
    checkClipboardPermissions,
    setBrowserBackOrForwardNavigation,
    showFilterBarSettings,
  };
});
