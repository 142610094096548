import { requisitionsMiddleware } from '@/helpers/routeMiddleware';
import { startCase } from 'lodash-es';

export const requisitions = (authDataStore) => ([
  {
    path: '/requisitions/',
    name: 'requisition-library',
    component: () => import(/* webpackChunkName: "requisition-library" */ '@/views/RequisitionLibrary.vue'),
    beforeEnter: requisitionsMiddleware(authDataStore),
    meta: { title: 'Library' },
    children: [
      {
        name: 'draft-requisitions',
        path: 'draft-requisitions',
        component: () => import(/* webpackChunkName: "draft-requisitions" */ '@/components/requisitions/pages/Draft.vue'),
        meta: { title: 'Draft Requisitions' },
      },
      {
        name: 'open-requisitions',
        path: 'open-requisitions',
        component: () => import(/* webpackChunkName: "open-requisitions" */ '@/components/requisitions/pages/Open.vue'),
        meta: { title: 'Open Requisitions' },
      },
      {
        name: 'closed-requisitions',
        path: 'closed-requisitions',
        component: () => import(/* webpackChunkName: "closed-requisitions" */ '@/components/requisitions/pages/Closed.vue'),
        meta: { title: 'Closed Requisitions' },
      },
    ],
  },
  {
    path: '/requisitions/:id',
    name: 'requisition-report',
    component: () => import(/* webpackChunkName: "requisition" */ '@/views/RequisitionReport.vue'),
    beforeEnter: requisitionsMiddleware(authDataStore),
    meta: {
      title: ({ title } = {}) => ((typeof title === 'string')
        ? title
        : ''),
      parentTitle: ({ status } = {}) => (typeof status === 'string' && status ? `${startCase(status)} Reqs` : ''),
    },
  },
]);
