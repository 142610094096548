/* eslint-disable */

export const initGoogleAnalytics = function() {
  const measurementId = 'G-CSM90Q9K6L';

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }

  gtag('js', new Date());
  gtag('config', measurementId);

  const gtagScript = document.createElement('script');
  gtagScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${measurementId}`);
  gtagScript.setAttribute('async', true);
  gtagScript.setAttribute('type', 'text/partytown');
  document.head.appendChild(gtagScript);
};