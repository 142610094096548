/* eslint-disable */

import { FullStory, init } from '@fullstory/browser';

export const initFullstory = ({ fullstory, user }) => {
  init({ orgId: 'C4A8Q' });
  fullstoryIdentify({ fullstory, user });
};

export const fullstoryIdentify = ({ fullstory, user }) => {
  const { displayName, email } = fullstory;

  if (!user.isGuest) {
    try {
      FullStory('setIdentity', {
        uid: String(user.userId),
        properties: { displayName, email },
      });
    } catch (e) {}
  }
};
