import { useAppPlatform } from '@/stores/app/platform';

export const adminCards = () => ([
  {
    title: 'Get Datapeople University Certified!',
    description: 'Use our self-guided certifications to become a Datapeople pro.',
    imgSrc: 'articles/datapeople-university-card',
    link: {
      text: 'Read Now',
      svg: 'bookmark-outline',
      href: useAppPlatform().getHelpLinks[1].href || 'https://university.datapeople.io',
    },
  },
  {
    title: 'How TA teams can add value in a hiring freeze',
    description: 'Learn how Talent teams can improve data and processes during a hiring pause.',
    imgSrc: 'articles/light-green-learn-card',
    link: {
      text: 'Read Now',
      svg: 'bookmark-outline',
      href: 'https://datapeople.io/article/how-talent-acquisition-can-add-value-in-a-hiring-freeze',
    },
  },
  {
    title: 'Datapeople Insights 101',
    description: 'Learn what each Datapeople Insights report tracks and how to use that data.',
    imgSrc: 'articles/dark-green-learn-card',
    link: {
      text: 'Read Now',
      svg: 'bookmark-outline',
      href: 'https://help.datapeople.io/article/113-navigate-datapeoples-analytics-suite',
    },
  },
]);

export const editorCards = () => ([
  {
    title: 'Get Datapeople University Certified!',
    description: 'Use our self-guided certifications to become a Datapeople pro.',
    imgSrc: 'articles/datapeople-university-card',
    link: {
      text: 'Read Now',
      svg: 'bookmark-outline',
      href: useAppPlatform().getHelpLinks[1].href || 'https://university.datapeople.io',
    },
  },
  {
    title: '4 tips for writing job posts with generative AI',
    description: 'Learn how to responsibly use AI in your job post writing process.',
    imgSrc: 'articles/blue-learn-card',
    link: {
      text: 'Read Now',
      svg: 'bookmark-outline',
      href: 'https://datapeople.io/article/4-tips-for-writing-job-posts-with-generative-ai',
    },
  },
  {
    title: 'How to Install Datapeople Anywhere',
    description: 'Learn how to install our extension to edit job posts directly in your ATS.',
    imgSrc: 'articles/purple-learn-card',
    link: {
      text: 'Read Now',
      svg: 'bookmark-outline',
      href: 'https://help.datapeople.io/article/137-how-to-install-datapeople-anywhere',
    },
  },
]);
