import theme from '@/configs/vuetify';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { dataLabelPercentages, xTickPercentFormat } from '@/api/helpers/insights/commonChartConfigs';
import { getWidgets } from './transformers';
import {
  getPassthroughDropOffTables,
  sortPassthroughDropoffLabelsAndValues,
  calculatePassthroughPercentages,
  sortPassthroughDropoffTables,
  getWidgetAndTableLabels,
} from './common';

const {
  royalFuscia, jaffaOrange, caribbeanGreen, royalBlue,
} = theme.theme.themes.light.colors;

/** defaultWidgetConfig manages the frontend config for each api widget */
const defaultWidgetConfig = [
  {
    id: 'passthrough-by-stage',
    tooltipKey: 'passthrough-by-stage',
    itemLabel: 'What is our pass-through rate for each stage?',
    groupLabel: '',
    labels: [],
    values: [],
    type: 'horizontalBarChart',
    colors: [[
      royalFuscia,
      jaffaOrange,
      caribbeanGreen,
      royalBlue,
    ]],
    optionsOverride: {
      ...xTickPercentFormat,
      ...dataLabelPercentages,
    },
    pluginsOverride: [ChartDataLabels],
    convertToPercent: false,
  },
];

/**
 * fieldMappings is used to map the index of a widget from `defaultWidgetConfig`
 * to which values it will need to pull off the api object, labels, and formatting
 */
const fieldMappings = [
  {
    value: 'count',
    label: 'count',
    labelFormat: 'percent',
  },
];

const tableLabelForLoading = 'Pass-through Report';
const tableKeys = ['applied', 'screening', 'assessment', 'offer', 'hired'];
const tableLabels = ['Pass-through Report'];
const displayOptions = {
  passthroughPercentages: {
    showTotals: true,
    showRows: true,
  },
};

const formatPassthroughRate = (tables) => {
  const newTables = {};

  Object.keys(tables).forEach((key) => {
    newTables[key] = {
      ...tables[key],
      data: (tables[key]?.data || []).map((dataPoint) => {
        const newDataPoint = dataPoint;

        if (dataPoint.label === 'Net Total Applications') {
          newDataPoint.label = 'Net Applications';
        }

        return newDataPoint;
      }),
    };
  });

  if (newTables.firstTable) {
    newTables.firstTable.data = [{
      label: null,
      items: tables.firstTable.data,
    }];
  }

  return newTables;
};

const addActiveCandidates = (tables) => {
  const newTables = {};

  Object.keys(tables).forEach((key) => {
    newTables[key] = {
      ...tables[key],
      data: (tables[key]?.data || []).map((dataPoint) => {
        const totalApps = (dataPoint?.items || []).find(({ label }) => label === 'Total Applications');
        const netApps = (dataPoint?.items || []).find(({ label }) => label === 'Net Applications');

        const activeApplied = totalApps?.applied?.value - netApps?.applied?.value;
        const activeAssessed = totalApps?.assessment?.value - netApps?.assessment?.value;
        const activeHired = totalApps?.hired?.value - netApps?.hired?.value;
        const activeOffered = totalApps?.offer?.value - netApps?.offer?.value;
        const activeScreened = totalApps?.screening?.value - netApps?.screening?.value;

        if ([
          activeApplied,
          activeAssessed,
          activeHired,
          activeOffered,
          activeScreened]
          .some((value) => Number.isNaN(value))
        ) return dataPoint;

        const activeCandidates = {
          label: 'Active Candidates',
          applied: { value: activeApplied },
          assessment: { value: activeAssessed },
          hired: { value: activeHired },
          offer: { value: activeOffered },
          screening: { value: activeScreened },
        };

        return {
          ...dataPoint,
          items: [totalApps, activeCandidates, netApps],
        };
      }),
    };
  });

  return newTables;
};

const getPassthroughTables = (tableData) => {
  const tables = getPassthroughDropOffTables(tableData, { tableKeys, tableLabels });
  const passthroughFormattedTables = formatPassthroughRate(tables);
  const sortedTables = sortPassthroughDropoffTables(passthroughFormattedTables, ['Total Applications', 'Active Candidates', 'Net Applications']);

  const tablesWithPassthroughPercentages = calculatePassthroughPercentages(
    sortedTables,
    displayOptions.passthroughPercentages,
  );

  const tablesWithActiveCandidates = addActiveCandidates(tablesWithPassthroughPercentages);

  return tablesWithActiveCandidates;
};

const getPassthroughWidgets = (chartData) => {
  const widgets = getWidgets(chartData, {
    defaultWidgetConfig,
    fieldMappings,
  });
  const sortedWidgets = sortPassthroughDropoffLabelsAndValues(widgets);

  return sortedWidgets;
};

/**
 * getPassthroughData decouples the totals data at index `0`
 * and then handles failed and successful requests. It generates the widget for successful requests
 * @param results
 * @param results.chartData
 * @param results.tableData
 * @param results.metrics
 * @returns {object} { widgets, totals }
 */
export const getPassthroughData = ({ chartData, tableData, metrics }) => {
  const widgets = getPassthroughWidgets(chartData);
  const tables = getPassthroughTables(tableData);
  const labelFormats = fieldMappings.map(({ labelFormat }) => labelFormat);

  return {
    widgets, totals: metrics, labelFormats, tables, displayOptions,
  };
};

export const getPassthroughLabelsForLoading = getWidgetAndTableLabels(
  defaultWidgetConfig,
  tableLabelForLoading,
);
