/* eslint-disable consistent-return */
export default {
  isEmpty(value) {
    if (typeof value === 'string') {
      return value.trim() === '';
    }
    return value === null
      || value === undefined
      || (Array.isArray(value) && value.length === 0);
  },
  isValidName(value) {
    const trimmed = value.trim();
    const pattern = new RegExp(/^[a-zA-Z](.*[a-zA-Z])?$/, 'i');
    return trimmed.length > 1 && pattern.test(trimmed);
  },
  emailFormat(value, messages, passedInOptions) {
    const pattern = new RegExp("^[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9]"
    + '(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$');
    const fullPattern = new RegExp("^[^@]*<[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+)*@"
    + '(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?>$');
    const defaultOptions = {
      pattern,
      fullPattern,
    };
    const options = { ...defaultOptions, ...passedInOptions };
    if (options.skipOnEmpty && this.isEmpty(value)) {
      return true;
    }

    let valid = true;
    const regexp = /^((?:"?([^"]*)"?\s)?)(?:\s+)?(?:(<?)((.+)@([^>]+))(>?))$/;
    const matches = regexp.exec(value);

    if (matches === null) {
      valid = false;
    } else {
      const localPart = matches[5];
      const domain = matches[6];

      if (localPart.length > 64) {
        valid = false;
      } else if ((`${localPart}@${domain}`).length > 254) {
        valid = false;
      } else {
        valid = options.pattern.test(value)
        || (options.allowName && options.fullPattern.test(value));
      }
    }
    return valid;
  },
  url(value, messages, passedInOptions) {
    // eslint-disable-next-line no-unused-vars
    /**
     *
     * @param validSchemes
     */
    // eslint-disable-next-line no-unused-vars
    function getPattern(validSchemes = ['http', 'https']) {
      const pattern = '^(([A-Z0-9][A-Z0-9_-]*)(\\.[A-Z0-9][A-Z0-9_-]*)+)(?::\\d{1,5})?(?:$|[?\\/#])';

      return new RegExp(pattern, 'i');
    }

    const defaultOptions = {
      pattern: getPattern(),
      message: 'Invalid value.',
    };

    const options = { ...defaultOptions, ...passedInOptions };
    if (options.skipOnEmpty && this.isEmpty(value)) {
      return true;
    }
    let internalValue = value;
    if (internalValue.substring(0, 7).toLowerCase() === 'http://') {
      internalValue = internalValue.substring(8);
    }
    if (internalValue.substring(0, 8).toLowerCase() === 'https://') {
      internalValue = internalValue.substring(9);
    }
    if (options.defaultScheme && !/:\/\//.test(internalValue)) {
      internalValue = `${options.defaultScheme}://${internalValue}`;
    }

    let valid = true;
    /*
    if (options.enableIDN) {
      const matches = /^([^:]+):\/\/([^\/]+)(.*)$/.exec(value);
      if (matches === null) {
        valid = false;
      } else {
        value = `${matches[1]}://${punycode.toASCII(matches[2])}${matches[3]}`;
      }
    }
    */
    if (!valid || !options.pattern.test(internalValue)) {
      valid = false;
    }
    return valid;
  },
  emailBlacklist(value) {
    if (!value) return;
    const blacklist = [
      'gmail.com',
      'yahoo.com',
      'facebook.com',
      'hotmail.com',
      'hotmail.co.uk',
      'aol.com',
      'hotmail.co.uk',
      'hotmail.fr',
      'msn.com',
      'yahoo.fr',
      'wanadoo.fr',
      'orange.fr',
      'comcast.net',
      'yahoo.co.uk',
      'yahoo.com.br',
      'yahoo.co.in',
      'live.com',
      'rediffmail.com',
      'free.fr',
      'gmx.de',
      'web.de',
      'yandex.ru',
      'ymail.com',
      'libero.it',
      'outlook.com',
      'uol.com.br',
      'bol.com.br',
      'mail.ru',
      'cox.net',
      'hotmail.it',
      'sbcglobal.net',
      'sfr.fr',
      'live.fr',
      'verizon.net',
      'live.co.uk',
      'googlemail.com',
      'yahoo.es',
      'ig.com.br',
      'live.nl',
      'bigpond.com',
      'terra.com.br',
      'yahoo.it',
      'neuf.fr',
      'yahoo.de',
      'alice.it',
      'rocketmail.com',
      'att.net',
      'laposte.net',
      'facebook.com',
      'bellsouth.net',
      'yahoo.in',
      'hotmail.es',
      'charter.net',
      'yahoo.ca',
      'yahoo.com.au',
      'rambler.ru',
      'hotmail.de',
      'tiscali.it',
      'shaw.ca',
      'yahoo.co.jp',
      'sky.com',
      'earthlink.net',
      'optonline.net',
      'freenet.de',
      't-online.de',
      'aliceadsl.fr',
      'virgilio.it',
      'home.nl',
      'qq.com',
      'telenet.be',
      'me.com',
      'yahoo.com.ar',
      'tiscali.co.uk',
      'yahoo.com.mx',
      'voila.fr',
      'gmx.net',
      'mail.com',
      'planet.nl',
      'tin.it',
      'live.it',
      'ntlworld.com',
      'arcor.de',
      'yahoo.co.id',
      'frontiernet.net',
      'hetnet.nl',
      'live.com.au',
      'yahoo.com.sg',
      'zonnet.nl',
      'club-internet.fr',
      'juno.com',
      'optusnet.com.au',
      'blueyonder.co.uk',
      'bluewin.ch',
      'skynet.be',
      'sympatico.ca',
      'windstream.net',
      'mac.com',
      'centurytel.net',
      'chello.nl',
      'live.ca',
      'aim.com',
      'bigpond.net.au',
      'goglemail.com',
      'live.cn',
      'protonmail.com',
      'hey.com',
      'googlemail.com',
      'pm.me',
      'comcast.net',
      'rr.com',
      'me.com',
      'fastmail.com',
      'example.com',
    ];
    const checkBlacklist = (val) => !blacklist.includes(val.substring(val.lastIndexOf('@') + 1).toLowerCase());
    if (Array.isArray(value)) return value.every((val) => checkBlacklist(val));
    if (typeof value === 'string') return checkBlacklist(value);
  },
  roleEmailBlacklist(value) {
    if (!value) return;
    const blacklist = [
      'abuse',
      'accounting',
      'accounts',
      'adm',
      'admin',
      'administration',
      'administrator',
      'admissions',
      'ads',
      'all',
      'answers',
      'anti-spam',
      'antispam',
      'asdf',
      'billing',
      'careers',
      'ceo',
      'comments',
      'compliance',
      'contact',
      'contactus',
      'customer',
      'customercare',
      'customerservice',
      'database',
      'decline',
      'declined',
      'denied',
      'designer',
      'devnull',
      'director',
      'dns',
      'email',
      'employment',
      'enquiries',
      'everyone',
      'fbl',
      'feedback',
      'finance',
      'ftp',
      'general',
      'hello',
      'help',
      'helpdesk',
      'hi',
      'home',
      'hostmaster',
      'hr',
      'info',
      'information',
      'inoc',
      'investorrelations',
      'ispfeedback',
      'ispsupport',
      'jobs',
      'lawyer',
      'lawyers',
      'legal',
      'list',
      'list-request',
      'mail',
      'mailbox',
      'mail-daemon',
      'maildaemon',
      'mail-deamon',
      'manager',
      'managers',
      'marketing',
      'me',
      'media',
      'mediarelations',
      'mkt',
      'news',
      'noc',
      'noreplies',
      'no-reply',
      'noreply',
      'noemail',
      'nospam',
      'nothanks',
      'null',
      'office',
      'operations',
      'orders',
      'payments',
      'phish',
      'phishing',
      'post',
      'postbox',
      'postmaster',
      'prepress',
      'president',
      'press',
      'privacy',
      'purchasing',
      'reception',
      'refuse',
      'refused',
      'registrar',
      'remove',
      'request',
      'reservations',
      'returns',
      'root',
      'sales',
      'secretary',
      'security',
      'service',
      'services',
      'shop',
      'spam',
      'staff',
      'studio',
      'subscribe',
      'support',
      'sysadmin',
      'tech',
      'undisclosed-recipients',
      'unsubscribe',
      'usenet',
      'users',
      'uucp',
      'web',
      'webmaster',
      'welcome',
      'www',
    ];
    const checkBlacklist = (val) => !blacklist.includes(val.substring(0, val.indexOf('@')));
    if (Array.isArray(value)) return value.every((val) => checkBlacklist(val));
    if (typeof value === 'string') return checkBlacklist(value);
  },
  passwordStrength(value) {
    // One uppercase, one lowercase, one number, one symbol and at least 8 characters
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+\-=[\]{};'\\:"|,./<>?])[A-Za-z\d~!@#$%^&*()_+\-=[\]{};'\\:"|,./<>?]{8,}$/.test(value);
  },
  jobboardUrlBlacklist(value) {
    const blacklist = [
      'indeed.com',
      'linkedin.com',
      'monster.com',
      'seek.com.au',
      'seek.com',
      'angel.co',
    ];
    let valid = true;
    const valueLowerCase = value.toLowerCase();
    blacklist.forEach((domain) => {
      if (valueLowerCase.indexOf(domain) !== -1) {
        valid = false;
      }
    });
    if (valueLowerCase.indexOf('google.com') !== -1 && valueLowerCase.indexOf('withgoogle.com') === -1) {
      valid = false;
    }
    return valid;
  },
  hasSubdomainOrPath(value) {
    let valid = false;
    const numberOfPeriodSeperatedSections = value.split('.').length;
    const numberOfSlashSeperatedSections = value.split('/').length;
    if (value.indexOf('www.') === -1) {
      if (!valid && numberOfPeriodSeperatedSections > 2) {
        valid = true;
      }
    } else if (!valid && numberOfPeriodSeperatedSections > 3) {
      valid = true;
    }
    if (value.indexOf('://') === -1) {
      if (!valid && numberOfSlashSeperatedSections > 1) {
        valid = true;
      }
    } else if (!valid && numberOfSlashSeperatedSections > 3) {
      valid = true;
    }
    return valid;
  },
  trimSpaces(email) {
    return email.trim();
  },
  firstNameRules() {
    return [
      (v) => !this.isEmpty(v) || 'First name is required',
      (v) => (v && v.length <= 250) || 'First name is too long',
    ];
  },
  lastNameRules() {
    return [
      (v) => !this.isEmpty(v) || 'Last name is required',
      (v) => (v && v.length <= 250) || 'Last name is too long',
    ];
  },
  emailRules() {
    return [
      (v) => !this.isEmpty(v) || 'Email is required',
      (v) => this.emailFormat(v) || 'Email format is invalid',
      (v) => this.emailBlacklist(v) || 'Please provide your work email address',
      (v) => this.roleEmailBlacklist(v) || `Role-based email addresses are not valid.
      Please use your individual company email address.`,
      (v) => (v && v.length <= 250) || 'Email is too long',
    ];
  },
  titleRules() {
    return [
      (v) => !this.isEmpty(v) || 'Title is required',
      (v) => (v && v.length <= 250) || 'Title is too long',
    ];
  },
  locationRules() {
    return [
      (v) => !this.isEmpty(v) || 'Location is required',
      (v) => (v && v.length <= 250) || 'Location is too long',
    ];
  },
  optionalPasswordRules() {
    return [
      (v) => (!v || v.length <= 250) || 'Password is too long',
      (v) => !v || this.passwordStrength(v) || `Password must be at least 8 characters long and include at least one of each of the following:
      • Capitalized letter
      • Lowercase letter
      • Number
      • Symbol: ~ : ; , . ' " ! ? & @ # $ % ^ * _ + - = ( ) [ ] { } | / < >`,
    ];
  },
  passwordRules() {
    return [
      (v) => !this.isEmpty(v) || 'Password is required',
      ...this.optionalPasswordRules(),
    ];
  },
};
