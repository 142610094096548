import { useAppThirdPartyPlugins } from '@/stores/app/thirdParty';

export const initBeamer = ({ beamer }) => {
  const {
    productId, firstName, lastName, email, userId,
  } = beamer;

  window.beamer_config = {
    product_id: productId, // DO NOT CHANGE: This is your product code on Beamer
    selector: 'beamerButton', /* Optional: Id, class (or list of both) of the HTML element to use as a button */
    button: false,
    user_firstname: firstName, /* Optional */
    user_lastname: lastName, /* Optional */
    user_email: email, /* Optional */
    user_id: userId, /* Optional */
  };

  const beamerLoadScript = document.createElement('script');
  beamerLoadScript.setAttribute('defer', true);
  beamerLoadScript.setAttribute('type', 'text/javascript');
  beamerLoadScript.setAttribute('src', 'https://app.getbeamer.com/js/beamer-embed.js');

  // beamer_config variable must be initialized before load script
  document.body.appendChild(beamerLoadScript);

  useAppThirdPartyPlugins().setBeamer(true);
};
