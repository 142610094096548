import { createVuetify } from 'vuetify';
import 'vuetify/styles'; // eslint-disable-line import/extensions
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
import vuetifyConfig from '@/configs/vuetify';
import icons from '@/init/icons';

export const initVuetify = (app) => {
  const vuetify = createVuetify({
    icons: {
      defaultSet: 'mdi',
      aliases: {
        ...aliases,
        ...icons,
      },
      sets: {
        mdi,
      },
    },
    ...vuetifyConfig,
  });

  app.use(vuetify);
};
