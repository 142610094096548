// TODO: split authData into user, project, session (or whatever makes sense)

import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { vueAuthData } from '@/api/vue';
import { useAppAlerts } from '@/stores/app/alerts';
import { TYPE_TAPWAGE } from '@/helpers/constants/integrationTypes';

export const useAppAuthData = defineStore('appAuthData', () => {
  const appAlerts = useAppAlerts();

  const authData = ref({
    appMaintenanceMode: false,
    atsName: '',
    atsId: '',
    integrationTypeKey: 0,
    integrationType: '',
    isSuperuser: false,
    isLoggedIn: false,
    userType: null,
    userSetupStatus: null,
    companyName: null,
    projectName: null,
    projectStatus: null,
    projectSetupStatus: null,
    projectMaintenanceMode: false,
    projectImageUrl: null,
    canTeam: false,
    canTeamAdmin: false,
    canEditTemplates: false,
    canBilling: false,
    canIntegrations: false,
    canGreenhouseRoles: false,
    canCustomFields: false,
    editorV5Enabled: false,
    extensionJobsEnabled: false,
    extensionEmailsEnabled: false,
    showEmailPage: false,
    showCampaignsPage: false,
    showIntegrationsPage: false,
    isTrackingEnabled: false,
    loginMethod: '',
    oauthClient: '',
    ssoName: '',
    analyticsPremium: false,
    executiveInsightsEnabled: false,
    requisitionPagesEnabled: false,
    firstDraftEnabled: false,
    commentingEnabled: false,
    canSeeGenderReport: true,
    genderReportEnabled: true,
    userId: null,
    showWeeklyReports: false,
    isApplicationReportingEnabled: false,
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    appVersion: '',
    personaKey: null,
    isInInsightsTrial: false,
    hasInstalledDA: false,
  });

  const integrated = computed(() => authData.value.integrationTypeKey !== TYPE_TAPWAGE);
  const integrationName = computed(() => (integrated.value ? authData.value.integrationType : ''));
  const isDatapeopleEmployee = computed(() => [
    'datapeople.io',
    'datapeople.fun',
    'taprecruit.me',
    'taprecruit.co',
    'tapwage.com',
  ].some((domain) => authData.value.email?.endsWith(domain)) || authData.value.isSuperuser);

  const setAuthData = (data) => {
    const validData = {};

    // Only write data that we are expecting
    Object.keys(data).forEach((key) => {
      if (authData.value[key] !== undefined) {
        validData[key] = data[key];
      }
    });

    authData.value = validData;
  };

  const updatePersona = (personaKey) => {
    authData.value.personaKey = personaKey;
  };

  const addFlashMessages = (flashMessages) => {
    Object.keys(flashMessages || {}).forEach((type) => {
      flashMessages[type].forEach((message) => {
        appAlerts.addAlert({ type, message });
      });
    });
  };

  const fetchAuthData = async () => {
    try {
      const { flashMessages, ...responseJSON } = await vueAuthData();
      setAuthData(responseJSON);
      addFlashMessages(flashMessages);
    } catch (e) {
      // TODO: show proper error message
      // app initialization failed, `alert` is the only way to report the error
      if (
        e.message.endsWith('not found in appAuthData state')
        && ['app.datapeople.io', 'sandbox.datapeople.io'].includes(window.location.origin)
      ) {
        // eslint-disable-next-line no-console
        console.error(e.message);
        return;
      }

      window.alert(`AuthData: ${e}`);
      throw e;
    }
  };

  return {
    authData,
    integrated,
    integrationName,
    fetchAuthData,
    setAuthData,
    updatePersona,
    isDatapeopleEmployee,
  };
});
