import { partytownSnippet } from '@builder.io/partytown/integration';

export const initPartytown = () => {
  window.partytown = {
    lib: '/app/~partytown/',
    forward: [
      'dataLayer.push',
      'gtag',
    ],
  };

  const snippetText = partytownSnippet();

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerHTML = snippetText;
  document.head.appendChild(script);
};
