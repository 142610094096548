import validation from '@/helpers/validation';

const requiredValidation = (value, { errorMessage }) => {
  const defaultErrorMessage = 'This field is required';
  const isValid = !validation.isEmpty(value);
  return isValid ? '' : (errorMessage || defaultErrorMessage);
};

const urlValidation = (value, { errorMessage }) => {
  const defaultErrorMessage = 'Bad URL format';
  const isValid = validation.url(value);
  return isValid ? '' : (errorMessage || defaultErrorMessage);
};

const functionValidation = (value, { errorMessage, fn }) => {
  const isValid = fn(value);
  return isValid ? '' : errorMessage;
};

const ruleErrorMessage = (value, rule) => {
  if (rule.name === 'required') {
    return requiredValidation(value, rule);
  }
  if (rule.name === 'url') {
    return urlValidation(value, rule);
  }
  if (rule.name === 'function') {
    return functionValidation(value, rule);
  }

  /* istanbul ignore next */
  return '';
};

export const errorMessage = (value, rules) => rules
  .map((rule) => ruleErrorMessage(value, rule))
  .find(Boolean);
