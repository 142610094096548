import { home } from '@/routes/home';
import { documents } from '@/routes/documents';
import { legacy } from '@/routes/legacy';
import { requisitions } from '@/routes/requisitions';
import { insights } from '@/routes/insights';
import { settings } from '@/routes/settings';
import { editor } from '@/routes/editor';
import { gatesAndLogin } from '@/routes/gatesAndLogin';

export const getRouterConfig = ({ authDataStore }) => ([
  {
    path: '/',
    component: () => import(/* webpackChunkName: "datapeopleframe" */ '@/views/DatapeopleFrame.vue'),
    children: [
      ...home(authDataStore),
      ...documents,
      ...legacy,
      ...requisitions(authDataStore),
      ...insights(authDataStore),
      ...settings(authDataStore),
      ...editor(authDataStore),
    ],
  },
  ...gatesAndLogin,
  {
    path: '/datapeople-anywhere-login-success',
    name: 'datapeople-anywhere-login-success',
    component: () => import(/* webpackChunkName: "datapeopleanywhereloginsuccess" */ '@/views/DatapeopleAnywhereLoginSuccess.vue'),
  },
  {
    path: '/root',
    name: 'root',
    redirect: () => {
      //   update canary scripts and remove this check
      if (window.navigator.userAgent.endsWith('AWS-Canary-Noscript')) {
        return { name: 'published' };
      }
      return { name: 'home' };
    },
  },
  {
    path: '/:pathMatch(.*)',
    redirect: { name: 'root' },
  },
]);
