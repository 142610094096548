export const STATUS_TRIAL_EXPIRED = 1;
export const STATUS_TRIAL = 2;
export const STATUS_BILLING_MANUAL = 3;
export const STATUS_BILLING_AUTOMATIC = 4;
export const STATUS_PRETRIAL = 5;
export const STATUS_BILLING_EXPIRED = 6;
export const STATUS_TERMINATED_GENERIC = 7;
export const STATUS_TRIAL_PAUSED = 8;
export const STATUS_TRIAL_FORCE_PAY = 9;
export const STATUS_TRIAL_RENEWED = 10;
export const STATUS_TERMINATED_NO_ATS = 11;
export const STATUS_TERMINATED_SUSPENDED = 12;
export const STATUS_BILLING_PILOT = 13;
export const STATUS_TERMINATED_HR_TECH = 14;
export const STATUS_SALES_HOLD = 15;
export const STATUS_INTERNAL = 16;
