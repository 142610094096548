import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { presets as datePresets } from '@/components/filter/dateFilters/presets';
import { getDateWeekIndex, dateWeekToRange } from '@/stores/filters/helpers';
import { startOfWeek, lastDayOfWeek, format } from 'date-fns';

export const useDatePicker = defineStore('datePicker', () => {
  // State
  const dateRange = ref({});
  const tempDateRange = ref([]);
  const startDate = ref(null);
  const endDate = ref(null);
  const datePickerVariant = ref('standard');

  // Getters
  const sortedRange = computed(() => [new Date(`${dateRange.value.from}T00:00:00`), new Date(`${dateRange.value.to}T00:00:00`)]);

  const bothSelected = computed(() => sortedRange.value.length === 2);

  const presets = computed(() => datePresets[datePickerVariant.value || 'standard']);

  const dateWeekIndex = computed(() => getDateWeekIndex(
    datePickerVariant.value,
    tempDateRange.value,
  ));

  // Actions
  const setDateRange = (range) => {
    dateRange.value = { ...range };
    // datepicker component uses array for v-model
    tempDateRange.value = [new Date(`${range.from}T00:00:00`), new Date(`${range.to}T00:00:00`)];
    startDate.value = range.startDate;
    endDate.value = range.endDate;
  };

  const resetDateRange = () => {
    dateRange.value = {};
    tempDateRange.value = [];
    startDate.value = null;
    endDate.value = null;
  };

  const setTempDateRange = (value) => {
    if (datePickerVariant.value === 'week') {
      if (!value) return;
      const firstday = format(startOfWeek(value), 'yyyy-MM-dd');
      const lastday = format(lastDayOfWeek(value), 'yyyy-MM-dd');

      tempDateRange.value = [new Date(`${firstday}T00:00:00`), new Date(`${lastday}T00:00:00`)];
    } else {
      tempDateRange.value = (value || []).sort((a, b) => a - b);
    }
  };

  const setDatePickerVariant = ({ variant = 'standard', resetDateRange: shouldResetDateRange = false } = {}) => {
    datePickerVariant.value = variant;
    if (shouldResetDateRange) {
      resetDateRange();
    }
  };

  const setDateWeek = ({ dateWeek, endWeek, startWeek }) => {
    if (!dateWeek) return;

    const startWeekRange = dateWeekToRange(startWeek) || {};
    const endWeekRange = dateWeekToRange(endWeek) || {};

    setDateRange({
      ...dateWeekToRange(dateWeek),
      startDate: startWeekRange?.from,
      endDate: endWeekRange?.to,
    });
  };

  return {
    // State
    dateRange,
    tempDateRange,
    startDate,
    endDate,
    datePickerVariant,

    // Getters
    sortedRange,
    bothSelected,
    presets,
    dateWeekIndex,

    // Actions
    setDateRange,
    resetDateRange,
    setTempDateRange,
    setDatePickerVariant,
    setDateWeek,
  };
});
