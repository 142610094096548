import { defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';
import { reportingUsage, reportingDownloadUsage } from '@/api/insights';
import { useAppPagination } from '@/stores/app/pagination';
import { useAppAlerts } from '@/stores/app/alerts';
import { useAppRoute } from '@/stores/app/route';
import { removeEmptyParams } from '@/stores/filters/helpers';
import { isEqual } from 'lodash-es';
import { mergeFilters } from '@/stores/insights/helpers';
import { useFilters } from '@/stores/filters/filters';

export const useUsage = defineStore('usage', () => {
  // State
  const engagementUsers = ref([]);
  const summary = ref({
    avgScore: 0,
    avgScoreChange: 0,
    totalJobPosts: 0,
    totalRevisions: 0,
    totalUsers: 0,
  });
  const postRevisionsScore = ref([]);
  const postRevisionsDate = ref([]);
  const jobs = ref([]);

  // Synchronous Actions
  const setPlatformUsage = (usageData) => {
    engagementUsers.value = usageData?.engagementUsers || [];
    summary.value = usageData?.totals || {
      avgScore: 0,
      avgScoreChange: 0,
      totalJobPosts: 0,
      totalRevisions: 0,
      totalUsers: 0,
    };
    postRevisionsScore.value = usageData?.scoreMatrix || [];
    postRevisionsDate.value = usageData?.dateChart || [];
    jobs.value = usageData?.documents || [];
  };

  // Asynchronous Actions
  const getPlatformUsage = async ({
    routeQuery,
    selectedDateRange,
    selectedFilters,
    page,
    router,
    selectedMyJobs,
  }) => {
    const appRouteStore = useAppRoute();
    const { route } = storeToRefs(appRouteStore);
    const fallbackDateRange = selectedDateRange || {};
    const date = routeQuery?.from && routeQuery?.to
      ? { from: routeQuery.from, to: routeQuery.to }
      : fallbackDateRange;
    const params = routeQuery || {};

    params.page = page || undefined;

    const {
      filters, dateRange, pagination, owned, ...usageData
    } = await reportingUsage({
      params,
      selectedMyJobs,
      selectedDateRange: date,
      selectedFilters,
    });

    /* Set alert if date range contains less than 3 months of jobs */
    if (dateRange.incomplete) {
      useAppAlerts().addAlert({
        message: 'Reporting may be incomplete for your account until enough data is available',
        thisPageOnly: true,
      });
    }

    /* Send relevant updates to filters */
    useFilters().updateFilters({
      filters,
      dateRange,
      myJobsEnabled: owned,
    });

    setPlatformUsage(usageData);
    useAppPagination().updatePagination(pagination);

    const prunedRootStateQuery = removeEmptyParams(route?.query);
    const prunedRouteQuery = removeEmptyParams(routeQuery);
    const dateAvailable = !prunedRootStateQuery?.to
        && !prunedRootStateQuery?.from
        && !prunedRouteQuery?.to
        && !prunedRouteQuery?.from
        && dateRange?.from
        && dateRange?.to;

    /* update URL with shareable filter link using router.replace */
    if (
      router
        && (!isEqual(prunedRootStateQuery, prunedRouteQuery) || dateAvailable)
    ) {
      const queryFilters = mergeFilters(filters);

      const newQuery = {
        ...params,
        ...queryFilters || {},
        from: dateRange?.from,
        to: dateRange?.to,
        legacy: undefined,
      };

      if (selectedMyJobs) {
        newQuery.owned = selectedMyJobs;
      }

      router.push({
        query: newQuery,
      }).catch(() => true); // ignore duplicate navigation error
    }
  };

  const downloadPlatformUsage = ({
    document, dateRange, myJobsEnabled, mergedTempFilters,
  }) => {
    reportingDownloadUsage({
      document, myJobsEnabled, dateRange, filters: mergedTempFilters,
    });
  };

  return {
    engagementUsers,
    summary,
    postRevisionsScore,
    postRevisionsDate,
    jobs,
    setPlatformUsage,
    getPlatformUsage,
    downloadPlatformUsage,
  };
});
