import ChartDataLabels from 'chartjs-plugin-datalabels';
import { formatTooltipValue } from '@/helpers/formatters';
import { dataLabelPercentages, xTickPercentFormat } from '@/api/helpers/insights/commonChartConfigs';
import { LegendSpacingPlugin } from '@/api/helpers/insights/chartPlugins';
import theme from '@/configs/vuetify';
import { insightsColorMap } from '@/api/helpers/insights/colorMap';
import { getWidgets, getTables } from './transformers';
import { getWidgetAndTableLabels } from './common';

const {
  primaryColor,
  chromeYellow,
  pureWhite,
  secondaryColor,
  flameOrange,
  secondaryColorLighten80,
} = theme.theme.themes.light.colors;

const TIME_TO_HIRE_BENCHMARK_MIN = 30;
const TIME_TO_HIRE_BENCHMARK_MAX = 50;
const OFFER_ACCEPTANCE_RATE_MAX = 100;
const OFFER_ACCEPTANCE_RATE_BENCHMARK_MIN = 75;
const OFFER_ACCEPTANCE_RATE_BENCHMARK_MAX = 85;

const getTimeToHireMax = (values) => {
  const max = Math.max(...values);
  const maxValueToNextFifty = Math.ceil(max / 50) * 50;

  return maxValueToNextFifty > TIME_TO_HIRE_BENCHMARK_MAX
    ? maxValueToNextFifty : TIME_TO_HIRE_BENCHMARK_MAX;
};

/** defaultWidgetConfig manages the frontend config for each api widget */
const defaultWidgetConfig = [
  {
    id: 'offer-acceptance-rate-vs-benchmark',
    tooltipKey: 'hiring-offer-acceptance-vs-benchmark',
    itemLabel: 'Offer Acceptance Rate vs Benchmark',
    groupLabel: '',
    labels: [],
    values: [],
    type: 'horizontalBenchmarkChart',
    colors: [[
      primaryColor,
      chromeYellow,
    ]],
    optionsOverride: {
      ...xTickPercentFormat,
      'plugins.datalabels.color': function colorCallback(context) {
        const max = OFFER_ACCEPTANCE_RATE_MAX;
        const value = context.dataset.data[context.dataIndex];
        const percentOfSpace = (value / max) * 100;

        return percentOfSpace < 15 ? secondaryColor : pureWhite;
      },
      'plugins.datalabels.anchor': 'end',
      'plugins.datalabels.align': function alignCallback(context) {
        const max = OFFER_ACCEPTANCE_RATE_MAX;
        const value = context.dataset.data[context.dataIndex];
        const percentOfSpace = (value / max) * 100;

        if (Number.isNaN(percentOfSpace)) return 'end';

        return percentOfSpace < 15 ? 'end' : 'start';
      },
      'plugins.datalabels.formatter': function formatterCallback(value) {
        if (Number.isNaN(value)) return '';
        return formatTooltipValue(value, 'percent');
      },
      'scales.x.max': OFFER_ACCEPTANCE_RATE_MAX,
    },
    pluginsOverride: [ChartDataLabels],
    convertToPercent: false,
    benchmarkOptions: {
      min: OFFER_ACCEPTANCE_RATE_BENCHMARK_MIN,
      max: OFFER_ACCEPTANCE_RATE_BENCHMARK_MAX,
      leftOffset: 33, // offset for percentages
      rightOffset: 40,
    },
  },
  {
    id: 'time-to-hire-vs-benchmark',
    tooltipKey: 'hiring-time-to-hire-vs-benchmark',
    itemLabel: 'Time to Hire vs Benchmark',
    groupLabel: '',
    labels: [],
    values: [],
    type: 'horizontalBenchmarkChart',
    colors: [[
      primaryColor,
      chromeYellow,
    ]],
    optionsOverride: {
      'plugins.datalabels.color': function colorCallback(context) {
        const max = getTimeToHireMax(context.dataset.data.filter((value) => value));
        const value = context.dataset.data[context.dataIndex];
        const percentOfSpace = (value / max) * 100;

        return percentOfSpace < 15 ? secondaryColor : pureWhite;
      },
      'plugins.datalabels.anchor': 'end',
      'plugins.datalabels.align': function alignCallback(context) {
        const max = getTimeToHireMax(context.dataset.data.filter((value) => value));
        const value = context.dataset.data[context.dataIndex];
        const percentOfSpace = (value / max) * 100;

        if (Number.isNaN(percentOfSpace)) return 'end';

        return percentOfSpace < 15 ? 'end' : 'start';
      },
      'plugins.datalabels.formatter': function formatterCallback(value) {
        if (Number.isNaN(value)) return '';
        return `${value} days`;
      },
      'scales.x.max': (chartJsObject) => {
        const chart = chartJsObject.chart || chartJsObject.scale.chart;
        // eslint-disable-next-line no-underscore-dangle
        const data = chart?.config?._config?.data?.datasets?.[0]?.data || [];

        return getTimeToHireMax(data);
      },
    },
    pluginsOverride: [ChartDataLabels],
    convertToPercent: false,
    benchmarkOptions: {
      min: TIME_TO_HIRE_BENCHMARK_MIN,
      max: TIME_TO_HIRE_BENCHMARK_MAX,
      leftOffset: 26, // offset for numeric values
      rightOffset: 30,
    },
  },
  {
    id: 'gender-distribution-of-hires',
    tooltipKey: 'hiring-gender-distribution',
    itemLabel: 'What is the gender distribution of our hires?',
    groupLabel: '',
    labels: [],
    values: [],
    type: 'doughnutChart',
    colors: [
      primaryColor,
      flameOrange,
      secondaryColorLighten80,
    ],
    pluginsOverride: [
      LegendSpacingPlugin,
    ],
  },
  {
    id: 'hire-source-category',
    tooltipKey: 'hiring-source-categories',
    itemLabel: 'What source categories do our hires come from?',
    groupLabel: '',
    labels: [],
    values: [],
    type: 'horizontalBarChart',
    colors: null,
    optionsOverride: {
      ...xTickPercentFormat,
      ...dataLabelPercentages,
    },
    pluginsOverride: [ChartDataLabels],
  },
];

/**
 * fieldMappings is used to map the index of a widget from `defaultWidgetConfig`
 * to which values it will need to pull off the api object, labels, and formatting
 */
const fieldMappings = [
  {
    value: 'count',
    label: 'count',
    labelFormat: 'percent',
  },
  {
    value: 'count',
    label: 'count',
    labelFormat: 'number',
  },
  {
    value: 'count',
    label: 'count',
    labelFormat: 'percent',
  },
  {
    value: 'count',
    label: 'count',
    labelFormat: 'percent',
  },
];

const tableLabelForLoading = 'Hiring Report';
const tableKeys = ['resolved', 'accepted', 'declined', 'rescinded', 'currentOpen'];
const tableLabels = ['Offer Report'];
const displayOptions = {
  passthroughPercentages: {
    showTotals: false,
    showRows: false,
  },
};

const restructureTableData = (data = {}) => {
  if (!Object.keys(data).length) return [];
  const resolved = data.rescinded + data.hired + data.declined || undefined;

  return [
    {
      resolved: { value: resolved, percentage: typeof resolved !== 'number' ? undefined : 1 },
      accepted: { value: data.hired, percentage: data.hired / resolved || undefined },
      declined: { value: data.declined, percentage: data.declined / resolved || undefined },
      rescinded: { value: data.rescinded, percentage: data.rescinded / resolved || undefined },
      currentOpen: { value: data.current_open_offers },
    },
  ];
};

const getHiringTables = (tableData) => {
  const newTableData = restructureTableData(tableData);
  const tables = getTables(newTableData, { tableKeys, tableLabels });

  return tables;
};

const createUsableChartData = (chartData) => {
  const finalData = {};
  const offerAcceptanceRate = chartData.acceptanceRate;
  const { timeToHire } = chartData;
  const genderDistribution = chartData.gender;
  const hireSourceCategory = chartData.sources;

  if (typeof offerAcceptanceRate === 'number') {
    finalData['offer-acceptance-rate-vs-benchmark'] = {
      labels: ['Offer Acceptance Rate'],
      datasets: {
        count: offerAcceptanceRate ? [offerAcceptanceRate / 100] : [],
      },
    };
  } else {
    finalData['offer-acceptance-rate-vs-benchmark'] = {
      labels: [],
      datasets: {
        count: [],
      },
    };
  }

  if (typeof timeToHire === 'number') {
    finalData['time-to-hire-vs-benchmark'] = {
      labels: ['Time to Hire'],
      datasets: {
        count: timeToHire ? [timeToHire] : [],
      },
    };
  } else {
    finalData['time-to-hire-vs-benchmark'] = {
      labels: [],
      datasets: {
        count: [],
      },
    };
  }

  if (genderDistribution) {
    const labels = [];
    const values = [];
    const { male } = genderDistribution;
    const { female } = genderDistribution;
    const unknown = genderDistribution.undetermined;

    if (typeof male === 'number') {
      labels.push('Male');
      values.push(male);
    }

    if (typeof female === 'number') {
      labels.push('Female');
      values.push(female);
    }

    if (typeof unknown === 'number') {
      labels.push('Unknown');
      values.push(unknown);
    }

    finalData['gender-distribution-of-hires'] = {
      labels,
      datasets: {
        count: values,
      },
    };
  } else {
    finalData['gender-distribution-of-hires'] = {
      labels: [],
      datasets: {
        count: [],
      },
    };
  }

  if (hireSourceCategory) {
    // Sort the hireSourceCategory values highest to lowest and keep the keys in sync
    const sortedHireSourceCategory = Object.keys(hireSourceCategory)
      .sort((a, b) => hireSourceCategory[b] - hireSourceCategory[a])
      .reduce((acc, key) => {
        acc[key] = hireSourceCategory[key];
        return acc;
      }, {});

    finalData['hire-source-category'] = {
      labels: Object.keys(sortedHireSourceCategory),
      datasets: {
        count: Object.values(sortedHireSourceCategory),
      },
    };
  } else {
    finalData['hire-source-category'] = {
      labels: [],
      datasets: {
        count: [],
      },
    };
  }
  return finalData;
};

/**
 * getHiringReportData decouples the totals data at index `0`
 * and then handles failed and successful requests. It generates the widget for successful requests
 * @param results
 * @param results.chartData
 * @param results.tableData
 * @param results.metrics
 * @returns {object} { widgets, totals }
 */
export const getHiringReportData = ({ chartData, tableData, metrics }) => {
  const usableChartData = createUsableChartData(chartData);
  const widgets = getWidgets(usableChartData, { defaultWidgetConfig, fieldMappings })
    .map((widget) => {
      if (widget.id === 'hire-source-category') {
        // eslint-disable-next-line no-param-reassign
        widget.colors = [widget.labels.map((label) => insightsColorMap()[label])];

        return widget;
      }

      return widget;
    });

  const tables = getHiringTables(tableData);
  const labelFormats = fieldMappings.map(({ labelFormat }) => labelFormat);

  return {
    widgets, totals: metrics, labelFormats, tables, displayOptions,
  };
};

export const getHiringReportLabelsForLoading = getWidgetAndTableLabels(
  defaultWidgetConfig,
  tableLabelForLoading,
);
