import theme from '@/configs/vuetify';
import { getBenchmarkMaxMinValues } from '@/api/helpers/insights/benchmark';

const { chromeYellowLighten60, pureWhite, secondaryColor } = theme.theme.themes.light.colors;
const newestArgs = {};

/**
 * CanvasDrawBenchmarkPlugin is used to draw a benchmark on a chart
 * @param root0
 * @param root0.chartId
 * @param root0.benchmarkOptions
 * @param root0.scaleMax
 */
export const CanvasDrawBenchmarkPlugin = ({ benchmarkOptions, scaleMax, chartId }) => {
  newestArgs[chartId] = { benchmarkOptions, scaleMax };

  return {
    id: 'canvasDrawBenchmarkPlugin',
    beforeDraw: (chart) => {
      const { benchmarkOptions: options, scaleMax: max } = newestArgs[chartId];
      const { newestMinX, newestMaxX } = getBenchmarkMaxMinValues(options, max, chartId);

      if (!newestMinX || !newestMaxX) return;

      const { ctx } = chart;
      const x1 = newestMinX;
      const width = newestMaxX - x1;
      const textMaxWidth = width - 4;

      // Create gradient rectangle and set its position based on benchmark min and max
      const gradient = ctx.createLinearGradient(0, 0, 0, chart.height - 50);

      gradient.addColorStop(0, chromeYellowLighten60);
      gradient.addColorStop(1, pureWhite);

      ctx.fillStyle = gradient;
      ctx.fillRect(x1, 0, width, chart.height - 50);
      let fontSize = 8;

      if (width < 45) fontSize = 6;
      if (width < 30) fontSize = 5;

      // Create benchmark text and set its position and maxWidth based on benchmark min and max
      ctx.font = `${fontSize}px Roboto`;
      ctx.textAlign = 'center';
      ctx.fillStyle = secondaryColor;

      ctx.fillText('Benchmark', x1 + (width / 2), 14, textMaxWidth);
    },
  };
};

export const LegendSpacingPlugin = {
  id: 'increase-legend-spacing',
  beforeInit(chart) {
    // Get reference to the original fit function
    const originalFit = chart.legend.fit;

    // Override the fit function
    chart.legend.fit = function fit() { // eslint-disable-line no-param-reassign
      // Call original function and bind scope in order to use `this` correctly inside it
      originalFit.bind(chart.legend)();
      // Change width
      this.width += 60;
    };
  },
};
