import * as Sentry from '@sentry/vue';

let fetchFn = null;
/**
 * Setup alternative fetch function. Used by chrome extension components
 * @param fn - a function that behaves like window.fetch, i.e. returns a promise
 * that resolves to response object that has json function that returns a promise of JSON data
 */
export const setFetchFn = (fn) => { fetchFn = fn; };

export const getBaseUrl = () => process.env.VUE_APP_BASE_API_PATH || '';

const fetchJson = async (url, options) => {
  const finalUrl = url.includes('http') ? url : getBaseUrl() + url;
  const response = await (fetchFn || window.fetch)(finalUrl, options);
  let responseJSON;

  try {
    responseJSON = await response.json();
  } catch (ignore) {
    responseJSON = { status: response.status, message: response.statusText };
  }

  if (!response.ok) {
    const message = responseJSON.message || responseJSON.code || response.statusText;
    const error = new Error(message);
    const endpoint = finalUrl.split('?')[0];

    error.response = { ...responseJSON, status: response.status, statusText: response.statusText };
    if (message !== 'We are unable to determine requisition counts for this user.') {
      Sentry.withScope((scope) => {
        scope.setFingerprint([endpoint, message]);
        Sentry.captureException(error);

        throw error;
      });
    }
  }

  return responseJSON;
};

const fetchCsv = async (url, options) => {
  const finalUrl = url.includes('http') ? url : getBaseUrl() + url;
  const response = await (fetchFn || window.fetch)(finalUrl, options);
  let responseCSV;

  try {
    responseCSV = await response.text();
  } catch (ignore) {
    responseCSV = { status: response.status, message: response.statusText };
  }

  if (!response.ok) {
    const responseJson = JSON.parse(responseCSV);
    const message = responseJson.message || responseJson.code || response.statusText;
    const error = new Error(message);
    const endpoint = finalUrl.split('?')[0];

    error.response = { status: response.status, statusText: response.statusText };
    Sentry.withScope((scope) => {
      scope.setFingerprint([endpoint, message]);
      Sentry.captureException(error);

      throw error;
    });
  }

  return responseCSV;
};

export const fetchWithTimeout = async (url, options, timeout = 30000) => {
  let timeoutId;
  const timeoutPromise = new Promise((resolve, reject) => {
    timeoutId = setTimeout(() => reject(new Error('timeout')), timeout);
  });

  let fetchPromise;

  if (options.isCsv) {
    fetchPromise = fetchCsv(url, options);
  } else {
    fetchPromise = fetchJson(url, options);
  }

  const response = await Promise.race([timeoutPromise, fetchPromise]);
  clearTimeout(timeoutId);
  return response;
};

export const getRequest = async ({ url, timeout }) => fetchWithTimeout(url, {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
}, timeout);

let csrf = '';

export const setCsrfToken = (token) => {
  csrf = token;
};

export const postRequest = async ({
  url, body, timeout, isCsv,
}) => fetchWithTimeout(url, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': csrf,
  },
  isCsv,
  body: JSON.stringify(body),
}, timeout);

export const postRequestWithSuccessErrorsResponse = async ({ url, body, timeout }) => {
  const data = await postRequest({ url, body, timeout });
  return { success: data.success, errors: data.errors };
};

export const formSubmitRequest = (document, url, data = {}, method = 'post') => {
  const form = document.createElement('form');
  form.action = getBaseUrl() + url;
  form.method = method;

  [
    ['params', JSON.stringify(data)],
    ['_csrf', csrf],
  ].forEach(([name, value]) => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = value;

    form.appendChild(input);
  });

  document.body.appendChild(form);
  form.submit();
};
