import theme from '@/configs/vuetify';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { dataLabelPercentages, xTickPercentFormat } from '@/api/helpers/insights/commonChartConfigs';
import { insightsColorMap } from '@/api/helpers/insights/colorMap';
import { getWidgets, getTables } from './transformers';
import {
  calculatePassthroughPercentages,
  getWidgetAndTableLabels,
} from './common';

const {
  darkOrchid, vividPink, zirconGrey, watermelonRed, tomatoOrange,
} = theme.theme.themes.light.colors;

/** defaultWidgetConfig manages the frontend config for each api widget */
const defaultWidgetConfig = [
  {
    id: 'top-sources-by-application',
    tooltipKey: 'overall-source-categories-for-apps',
    itemLabel: 'What source categories do our applications come from?',
    groupLabel: '',
    labels: [],
    values: [],
    type: 'horizontalBarChart',
    colors: [[
      darkOrchid,
      vividPink,
      watermelonRed,
      tomatoOrange,
      zirconGrey,
    ]],
    optionsOverride: {
      ...xTickPercentFormat,
      ...dataLabelPercentages,
    },
    pluginsOverride: [ChartDataLabels],
  },
  {
    id: 'top-sources-by-hire',
    tooltipKey: 'overall-source-categories-for-hires',
    itemLabel: 'What source categories do our hires come from?',
    groupLabel: '',
    labels: [],
    values: [],
    type: 'horizontalBarChart',
    colors: [[
      darkOrchid,
      vividPink,
      watermelonRed,
      tomatoOrange,
      zirconGrey,
    ]],
    optionsOverride: {
      ...xTickPercentFormat,
      ...dataLabelPercentages,
    },
    pluginsOverride: [ChartDataLabels],
  },
];

/**
 * fieldMappings is used to map the index of a widget from `defaultWidgetConfig`
 * to which values it will need to pull off the api object, labels, and formatting
 */
const fieldMappings = [
  {
    value: 'count',
    label: 'count',
    labelFormat: 'percent',
  },
  {
    value: 'count',
    label: 'count',
    labelFormat: 'percent',
  },
];

const tableLabelForLoading = 'Overall Report';
const tableKeys = ['applied', 'screening', 'assessment', 'offer', 'hired'];
const tableLabels = ['Overall Report'];
const displayOptions = {
  passthroughPercentages: {
    showTotals: true,
    showRows: true,
  },
};

const restructureTableData = (data = []) => {
  if (!data[0]) return [];

  const newTable = data[0];

  data.slice(1).forEach((instance) => {
    if (instance.items.length) newTable.items.push(instance.items[0]);
  });

  return [newTable];
};

const getOverallTables = (tableData) => {
  const newTableData = restructureTableData(tableData);
  const tables = getTables(newTableData, { tableKeys, tableLabels });
  const tablesWithPassthroughPercentages = calculatePassthroughPercentages(
    tables,
    displayOptions.passthroughPercentages,
  );

  return tablesWithPassthroughPercentages;
};

/**
 * getOverallReportData decouples the totals data at index `0`
 * and then handles failed and successful requests. It generates the widget for successful requests
 * @param results
 * @param results.chartData
 * @param results.tableData
 * @returns {object} { widgets, totals }
 */
export const getOverallReportData = ({ chartData, tableData }) => {
  const widgets = getWidgets(chartData, { defaultWidgetConfig, fieldMappings }).map((widget) => {
    // eslint-disable-next-line no-param-reassign
    widget.colors = [widget.labels.map((label) => insightsColorMap()[label])];

    return widget;
  });

  const tables = getOverallTables(tableData);
  const labelFormats = fieldMappings.map(({ labelFormat }) => labelFormat);

  return {
    widgets, totals: {}, labelFormats, tables, displayOptions,
  };
};

export const getOverallReportLabelsForLoading = getWidgetAndTableLabels(
  defaultWidgetConfig,
  tableLabelForLoading,
);
