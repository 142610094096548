import {
  format,
  parseISO,
  subDays,
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfWeek,
  endOfWeek,
  subWeeks,
  startOfQuarter,
  subQuarters,
  subYears,
  lastDayOfQuarter,
  startOfYear,
  lastDayOfYear,
} from 'date-fns';

export const ISO_FORMAT = 'yyyy-MM-dd';
export const DEFAULT_DATE = format(new Date(), ISO_FORMAT);

export const parseDate = (date) => parseISO(date, new Date());

export const formatDate = (date, fmt) => format(parseDate(date), fmt);

export const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const getDay = (date) => {
  const i = new Date(date).getUTCDay();
  return daysOfWeek[i];
};

export const getYear = (year) => {
  const rangeStart = startOfYear(subYears(new Date(), year));
  const rangeEnd = (year === 0)
    ? new Date()
    : lastDayOfYear(subYears(new Date(), year));
  return [rangeStart, rangeEnd];
};

export const getMonth = (date) => {
  const i = new Date(date).getMonth();
  return months[i];
};

export const getPreviousDays = ({
  rangeStartOffset,
  rangeEndOffset = 1,
}) => {
  const rangeStart = subDays(new Date(), rangeStartOffset);
  const rangeEnd = subDays(new Date(), rangeEndOffset);
  return [rangeStart, rangeEnd];
};

export const getPreviousWeek = () => {
  const rangeStart = startOfWeek(subWeeks(new Date(), 1));
  const rangeEnd = endOfWeek(subWeeks(new Date(), 1));
  return [rangeStart, rangeEnd];
};

export const getPreviousMonth = () => {
  const rangeStart = startOfMonth(subMonths(new Date(), 1));
  const rangeEnd = endOfMonth(subMonths(new Date(), 1));
  return [rangeStart, rangeEnd];
};

export const getQuarter = (quarter) => {
  const rangeStart = startOfQuarter(subQuarters(new Date(), quarter));
  const rangeEnd = (quarter === 0)
    ? new Date()
    : lastDayOfQuarter(subQuarters(new Date(), quarter));
  return [rangeStart, rangeEnd];
};

export const getQuarterName = (quarterNum) => {
  const dateStr = (quarterNum === 0)
    ? DEFAULT_DATE
    : format(lastDayOfQuarter(subQuarters(new Date(), quarterNum)), ISO_FORMAT);
  const selectedDate = new Date(dateStr);
  const quarter = Math.floor((selectedDate.getMonth() + 3) / 3);
  const year = selectedDate.getFullYear();

  return quarter && year ? `Q${quarter} ${year}` : 'Date Range';
};

export const getLastThirtyDays = () => {
  const thirtyDaysAgo = format(subDays(new Date(), 30), ISO_FORMAT);
  return [thirtyDaysAgo, DEFAULT_DATE];
};

export const isValidDate = (dateObj) => dateObj instanceof Date && !Number.isNaN(dateObj.valueOf());
