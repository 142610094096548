export const getLocalStorageObject = ({ key, remove = false }) => {
  const itemStr = localStorage?.getItem(key);

  if (!itemStr) {
    return null;
  }

  try {
    const item = JSON.parse(itemStr);

    if (remove) {
      localStorage?.removeItem(key);
    }

    return item;
  } catch (err) {
    return null;
  }
};
