export const documents = [{
  path: '/documents/',
  name: 'document-library',
  component: () => import(/* webpackChunkName: "library" */ '@/views/DocumentLibrary.vue'),
  meta: { title: 'Library' },
  children: [
    {
      name: 'email',
      path: 'email',
      component: () => import(/* webpackChunkName: "emails" */ '@/components/library/pages/Email.vue'),
      meta: { title: 'Emails' },
    },
    {
      name: 'published',
      path: 'published',
      component: () => import(/* webpackChunkName: "published" */ '@/components/library/pages/Published.vue'),
      meta: { title: 'Published Posts' },
    },
    {
      name: 'unpublished',
      path: 'unpublished',
      component: () => import(/* webpackChunkName: "unpublished" */ '@/components/library/pages/Unpublished.vue'),
      meta: { title: 'Unpublished Posts' },
    },
    {
      name: 'draft',
      path: 'draft',
      component: () => import(/* webpackChunkName: "drafts" */ '@/components/library/pages/Draft.vue'),
      meta: { title: 'Draft Posts' },
    },
    {
      name: 'closed',
      path: 'closed',
      redirect: (to) => ({ name: 'unpublished', query: { ...to.query, closedRedirect: 'true' } }),
    },
  ],
}];
