/**
 * @constant {number}
 * @memberof Constants
 */
export const TYPE_TAPWAGE = 1;

/**
 * @constant {number}
 * @memberof Constants
 */
export const TYPE_SMARTRECRUITERS = 3;

/**
 * @constant {number}
 * @memberof Constants
 */
export const TYPE_GR8PEOPLE = 4;

/**
 * @constant {number}
 * @memberof Constants
 */
export const TYPE_WORKDAY = 5;

/**
 * @constant {number}
 * @memberof Constants
 */
export const TYPE_LEVER = 6;

/**
 * @constant {number}
 * @memberof Constants
 */
export const TYPE_PEOPLESOFT = 7;

/**
 * @constant {number}
 * @memberof Constants
 */
export const TYPE_GREENHOUSE = 8;

/**
 * @constant {number}
 * @memberof Constants
 */
export const TYPE_ICIMS = 9;

/**
 * @constant {number}
 * @memberof Constants
 */
export const TYPE_UMICH = 10;

/**
 * @constant {number}
 * @memberof Constants
 */
export const TYPE_SUCCESSFACTORS = 11;

/**
 * @constant {number}
 * @memberof Constants
 */
export const TYPE_CORNERSTONE = 12;

/**
 * @constant {number}
 * @memberof Constants
 */
export const TYPE_TALEO = 13;

/**
 * @constant {number}
 * @memberof Constants
 */
export const TYPE_AVATURE = 14;
