import { getRequest } from './request';

export const getAsyncSearchResults = async ({ query, urlObj }) => {
  const { params, path } = urlObj;

  const searchParams = new URLSearchParams();

  if (typeof query !== 'undefined') searchParams.append('q', query);

  /* loop through params provided by backend and add them as params to new async request */
  if (params) {
    Object.entries(params).forEach((entry) => {
      if (typeof entry[1] !== 'undefined') {
        searchParams.append(entry[0], entry[1]);
      }
    });
  }

  const data = await getRequest({ url: `${path}?${searchParams}` });
  return data;
};
