import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useInsightsProject = defineStore('insightsProject', () => {
  const projectName = ref('');
  const projectImageUrl = ref('');

  const setSharedProjectInfo = ({ newProjectImageUrl, newProjectName }) => {
    projectImageUrl.value = newProjectImageUrl;
    projectName.value = newProjectName;
  };

  return {
    // State
    projectName,
    projectImageUrl,

    // Actions
    setSharedProjectInfo,
  };
});
