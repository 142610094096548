import { defineStore, storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import {
  TYPE_SMARTRECRUITERS, TYPE_WORKDAY,
} from '@/helpers/constants/integrationTypes';
import { useAppAlerts } from '@/stores/app/alerts';
import { useAppAuthData } from '@/stores/app/authData';
import { useAppThirdPartyPlugins } from '@/stores/app/thirdParty';
import { recruitInitNavbar } from '@/api/recruit';
import { vueWorkrampLogin } from '@/api/vue';
import * as Sentry from '@sentry/vue';
import { getEnvironment } from '@/helpers/environment';

export const useAppPlatform = defineStore('appPlatform', () => {
  const { addAlert } = useAppAlerts();

  const platform = ref({});
  const currentJobId = ref('');
  const platformInitialized = ref(false);
  const helpLinkAuthToken = ref('');

  /* ==============
  computed
  ============== */

  const getHelpLinks = computed(() => [
    {
      href: useAppThirdPartyPlugins().helpscoutSiteUrl,
      title: 'Read Help Articles',
    },
    {
      href: `https://university.datapeople.io${helpLinkAuthToken.value}`,
      title: 'Datapeople University',
    },
  ]);

  const dashboardNavLinks = computed(() => {
    const authDataStore = useAppAuthData();
    const { authData, integrated } = storeToRefs(authDataStore);
    const { requisitionPagesEnabled, integrationTypeKey } = authData.value;

    return [
      {
        show: true,
        to: 'draft',
        title: 'Draft Posts',
      },
      {
        show: true,
        to: 'published',
        title: 'Published Posts',
      },
      {
        show: true,
        to: 'unpublished',
        title: 'Unpublished Posts',
        divider: requisitionPagesEnabled && integrated.value,
      },
      {
        show: requisitionPagesEnabled
          && integrated.value
          && ![TYPE_SMARTRECRUITERS, TYPE_WORKDAY].includes(integrationTypeKey),
        to: 'draft-requisitions',
        title: 'Draft Requisitions',
      },
      {
        show: requisitionPagesEnabled && integrated.value,
        to: 'open-requisitions',
        title: 'Open Requisitions',
      },
      {
        show: requisitionPagesEnabled && integrated.value,
        to: 'closed-requisitions',
        title: 'Closed Requisitions',
        divider: platform.value.showEmailPage,
      },
      {
        show: platform.value.showEmailPage,
        to: 'email',
        title: 'Emails',
      },
    ];
  });

  const settingsNavLinks = computed(() => {
    const {
      canTeamAdmin,
      canIntegrations,
    } = useAppAuthData().authData;
    const {
      canTeam,
      showIntegrationsPage,
      canEditTemplates,
      showCampaignsPage,
      isChromeExtensionEmailsEnabled,
      isChromeExtensionEnabled,
      canSuperuser,
      userId,
      projectId,
      hasProject,
      atsName,
      inviteKey,
    } = platform.value;

    const isProductionEnv = ['production', 'test-app'].includes(getEnvironment());

    return [
      {
        to: 'account',
        show: true,
        title: 'My Account',
      },
      {
        to: 'integrations',
        show: showIntegrationsPage && canIntegrations,
        divider: true,
        title: `Connect ${atsName}`,
      },
      {
        to: 'team',
        show: canTeam,
        title: 'Manage Team',
      },
      {
        to: 'style-guide',
        show: canTeamAdmin,
        title: 'Style Guide',
      },
      {
        to: 'policy-guide',
        show: canTeamAdmin,
        title: 'Policy Guide',
      },
      {
        to: 'templates',
        show: canEditTemplates,
        title: 'Templates',
      },
      {
        to: 'campaigns',
        show: showCampaignsPage,
        title: 'Campaigns',
      },
      {
        to: 'organization-settings',
        show: canTeamAdmin,
        title: 'Organization Settings',
      },
      {
        to: false,
        show: Boolean(isChromeExtensionEnabled || isChromeExtensionEmailsEnabled),
        href: 'https://chromewebstore.google.com/detail/datapeople-anywhere/hhnemhalmbhdldblamkefkjnalpmadhm?hl=en&authuser=0',
        title: 'Datapeople Anywhere',
      },
      {
        to: false,
        show: true,
        divider: true,
        href: '/logout',
        title: 'Log Out',
      },
      {
        to: false,
        show: canSuperuser,
        href: '/admin/project',
        title: 'Datapeople Admin',
      },
      {
        to: false,
        show: Boolean(canSuperuser && userId),
        href: `/admin/user/update/${userId}`,
        title: 'User Settings',
      },
      {
        to: false,
        show: Boolean(canSuperuser && projectId),
        href: `/admin/project/update/${projectId}`,
        title: 'Project Settings',
      },
      {
        to: 'content-snapshot',
        show: canSuperuser && hasProject,
        title: 'Public Content Report',
        inviteKey,
      },
      {
        to: 'language-snapshot',
        show: canSuperuser && hasProject,
        divider: true,
        title: 'Public Language Report',
        inviteKey,
      },
      {
        to: false,
        show: canSuperuser && isProductionEnv,
        href: '/admin/project/switch/7660',
        title: 'Switch me to Sales Demo',
      },
      {
        to: false,
        show: canSuperuser && isProductionEnv,
        href: '/admin/project/switch/787',
        title: 'Switch me to Greenhouse Demo',
      },
      {
        to: false,
        show: canSuperuser && isProductionEnv,
        href: '/admin/project/switch/20450',
        title: 'Switch me to SmartRecruiters Demo',
      },
      {
        to: false,
        show: canSuperuser && isProductionEnv,
        href: '/admin/project/switch/3972',
        title: 'Switch me to Workday Demo',
      },
      {
        to: false,
        show: canSuperuser && isProductionEnv,
        href: '/admin/project/switch/4428',
        title: 'Switch me to Lever Demo',
      },
      {
        to: false,
        show: canSuperuser && isProductionEnv,
        href: '/admin/project/switch/13840',
        title: 'Switch me to Workable Demo',
      },
      {
        to: false,
        show: Boolean(currentJobId.value && canSuperuser),
        href: `/admin/job?JobSearch[id]=${currentJobId.value}`,
        title: 'Job Admin',
      },
    ];
  });

  const settingsBreadCrumbsLinks = computed(() => {
    const {
      canTeamAdmin,
      canIntegrations,
    } = useAppAuthData().authData;
    const {
      canTeam,
      showIntegrationsPage,
      canEditTemplates,
      showCampaignsPage,
      isChromeExtensionEmailsEnabled,
      isChromeExtensionEnabled,
      canSuperuser,
      userId,
      projectId,
      hasProject,
      atsName,
      inviteKey,
    } = platform.value;

    return [
      {
        to: 'account',
        show: true,
        name: 'My Account',
        sectionName: 'user',
      },
      {
        to: 'integrations',
        show: showIntegrationsPage && canIntegrations,
        name: `Connect ${atsName}`,
        sectionName: 'organization',
      },
      {
        to: 'team',
        show: canTeam,
        name: 'Manage Team',
        sectionName: 'organization',
      },
      {
        to: 'style-guide',
        show: canTeamAdmin,
        name: 'Style Guide',
        sectionName: 'organization',
      },
      {
        to: 'policy-guide',
        show: canTeamAdmin,
        name: 'Policy Guide',
        sectionName: 'organization',
      },
      {
        to: 'templates',
        show: canEditTemplates,
        name: 'Templates',
        sectionName: 'organization',
      },
      {
        to: 'campaigns',
        show: showCampaignsPage,
        name: 'Campaigns',
        sectionName: 'organization',
      },
      {
        to: 'organization-settings',
        show: canTeamAdmin,
        name: 'Organization Settings',
        sectionName: 'organization',
      },
      {
        to: false,
        show: Boolean(isChromeExtensionEnabled || isChromeExtensionEmailsEnabled),
        href: 'https://chrome.google.com/webstore/detail/taprecruit/hhnemhalmbhdldblamkefkjnalpmadhm?hl=en&authuser=0',
        name: 'Datapeople Anywhere',
        sectionName: 'user',
      },
      {
        to: false,
        show: true,
        href: '/logout',
        name: 'Log Out',
        sectionName: 'user',
      },
      {
        to: false,
        show: canSuperuser,
        href: '/admin/project',
        name: 'Datapeople Admin',
        sectionName: 'datapeople',
      },
      {
        to: false,
        show: Boolean(canSuperuser && userId),
        href: `/admin/user/update/${userId}`,
        name: 'User Settings',
        sectionName: 'datapeople',
      },
      {
        to: false,
        show: Boolean(canSuperuser && projectId),
        href: `/admin/project/update/${projectId}`,
        name: 'Project Settings',
        sectionName: 'datapeople',
      },
      {
        to: 'content-snapshot',
        show: canSuperuser && hasProject,
        name: 'Public Content Report',
        inviteKey,
        sectionName: 'datapeople',
      },
      {
        to: 'language-snapshot',
        show: canSuperuser && hasProject,
        name: 'Public Language Report',
        inviteKey,
        sectionName: 'datapeople',
      },
    ];
  });

  const reportingNavLinks = computed(() => {
    const {
      isApplicationReportingEnabled,
      showWeeklyReports,
      executiveInsightsEnabled,
    } = useAppAuthData().authData;

    const sections = [];

    if (isApplicationReportingEnabled) {
      sections.push(
        {
          showSection: isApplicationReportingEnabled,
          name: 'PROCESS INSIGHTS',
          links: [
            {
              show: executiveInsightsEnabled,
              to: 'executive-insights',
              title: 'Executive Insights',
            },
            {
              show: isApplicationReportingEnabled,
              to: 'pass-through',
              title: 'Pass-through Report',
            },
            {
              show: isApplicationReportingEnabled,
              to: 'gender-report',
              title: 'Gender Report',
            },
            {
              show: isApplicationReportingEnabled,
              to: 'drop-off',
              title: 'Drop-off Report',
            },
            {
              show: isApplicationReportingEnabled,
              to: 'hiring-report',
              title: 'Hiring Report',
            },
          ],
        },
        {
          showSection: isApplicationReportingEnabled,
          name: 'SOURCE INSIGHTS',
          links: [
            {
              show: isApplicationReportingEnabled,
              to: 'overall-report',
              title: 'Overall Report',
            },
            {
              show: isApplicationReportingEnabled,
              to: 'inbound-report',
              title: 'Inbound Report',
            },
            {
              show: isApplicationReportingEnabled,
              to: 'outbound-report',
              title: 'Outbound Report',
            },
          ],
        },
      );
    }

    sections.push({
      showSection: true,
      name: 'ACTIONABLE INSIGHTS',
      links: [{
        show: true,
        to: 'easy-wins-report',
        title: 'Easy Wins Report',
      },
      {
        show: true,
        to: 'compliance-report',
        title: 'Compliance Report',
      },
      {
        show: true,
        to: 'confidence-gap-report',
        title: 'Confidence Gap Report',
      }],
    });

    sections.push({
      showSection: true,
      name: 'OPERATIONAL INSIGHTS',
      links: [{
        show: true,
        to: 'recruiting-performance',
        title: 'Recruiting Performance',
      },
      {
        show: true,
        to: 'usage',
        title: 'Usage Report',
      },
      {
        show: showWeeklyReports,
        to: 'weekly-snapshot',
        title: 'Weekly Snapshot',
      },
      {
        show: showWeeklyReports,
        to: 'content-snapshot',
        title: 'Content Snapshot',
      },
      {
        show: showWeeklyReports,
        to: 'language-snapshot',
        title: 'Language Snapshot',
      }],
    });

    return sections;
  });

  /* ==============
    actions
  ============== */

  const setPlatformCurrentJobId = (id) => {
    currentJobId.value = id;
  };

  const getAutoLoginHelpLinks = async () => {
    try {
      const { loginUrl } = await vueWorkrampLogin();
      helpLinkAuthToken.value = loginUrl;
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const initPlatform = async (jobId, { setInitFieldsAction }) => {
    try {
      const responseJSON = await recruitInitNavbar({ jobId });

      if (responseJSON.success) {
        const {
          isApplicationReportingEnabled,
          templateList,
          showWeeklyReports,
        } = responseJSON.data || {};

        setInitFieldsAction({
          isApplicationReportingEnabled, templateList, showWeeklyReports,
        });

        // these fields have been moved to editor vuex module
        ['isApplicationReportingEnabled', 'templateList', 'showWeeklyReports'].forEach((field) => {
          delete responseJSON.data[field];
        });

        platform.value = { ...responseJSON.data };
        platformInitialized.value = true;
        setPlatformCurrentJobId(jobId);
      }
    } catch (error) {
      if (error?.response.code === 'project_not_found') {
        addAlert({ type: 'error', message: 'Project not found' });
      }
    }
  };

  return {
    /* state */
    platform,
    getHelpLinks,
    platformInitialized,
    currentJobId,

    /* computed */
    dashboardNavLinks,
    reportingNavLinks,
    settingsNavLinks,
    settingsBreadCrumbsLinks,

    /* actions */
    initPlatform,
    getAutoLoginHelpLinks,
    setPlatformCurrentJobId,
  };
});
