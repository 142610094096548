import { createPinia } from 'pinia';
import { useAppThirdPartyPlugins } from '@/stores/app/thirdParty';

export const initPinia = (app) => {
  const pinia = createPinia();
  app.use(pinia);
  const { initHubSpot, initBeacon } = useAppThirdPartyPlugins(pinia);

  initHubSpot();
  initBeacon();

  return pinia;
};
