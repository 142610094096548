import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

export const useSubMenu = defineStore('subMenu', () => {
  // State
  const subMenuOptions = ref([]);
  const selectedSubMenuOption = ref('');
  const subMenuDisabled = ref(false);

  // Getters
  const selectedOption = computed(() => {
    if (subMenuOptions.value.length) {
      const selected = subMenuOptions.value.find((
        option,
      ) => option.id === selectedSubMenuOption.value);
      return selected?.value;
    }
    return null;
  });

  // Actions
  const setSubMenuOptions = ({ options }) => {
    subMenuOptions.value = options;
  };

  const setSelectedSubMenuOption = (option) => {
    selectedSubMenuOption.value = option;
  };

  const setSubMenuDisabled = (disabled = false) => {
    subMenuDisabled.value = disabled;
  };

  return {
    // State
    subMenuOptions,
    selectedSubMenuOption,
    subMenuDisabled,

    // Getters
    selectedOption,

    // Actions
    setSubMenuOptions,
    setSelectedSubMenuOption,
    setSubMenuDisabled,
  };
});
