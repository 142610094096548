import * as Sentry from '@sentry/vue';
import { getEnvironment } from '@/helpers/environment';
import { useAppAuthData } from '@/stores/app/authData';
import { isNotTestAccount } from '@/helpers/thirdParty/verifyTestAccount';

const sentryEnvironment = getEnvironment();

/**
 * Generates regexp that matches https://*.{domain}/* URL
 * @param domain
 * @returns {RegExp}
 */
const anySubdomainRegexp = (domain) => new RegExp(
  `^https?:\\/\\/[^.]+\\.${domain.replace('.', '\\.')}`,
);

export const initSentry = ({ router, app }) => {
  if (process.env.VUE_APP_NO_SENTRY === 'true') {
    return;
  }

  Sentry.init({
    app,
    dsn: 'https://d2648224f98144b4a64800ba904d7ef3@o1174908.ingest.sentry.io/6318863',
    integrations: [Sentry.browserTracingIntegration({ router })],
    logErrors: true,
    tracePropagationTargets: [
      anySubdomainRegexp('127.0.0.1.xip.io'),
      anySubdomainRegexp('datapeople.io'),
      anySubdomainRegexp('taprecruit.co'),
    ],
    tracesSampler: () => {
      const userId = useAppAuthData().authData?.userId;

      if (!window
        || !isNotTestAccount(userId)
      ) {
        return 0;
      }

      if (sentryEnvironment === 'development') return 0.1;

      return 0.5;
    },
    replaysOnErrorSampleRate: sentryEnvironment === 'development' ? 0 : 0.1,
    environment: sentryEnvironment,
    // False to prevent it from tracking all the Vuetify component tree
    trackComponents: false,
    release: `vue-web-app@${process.env.VUE_APP_PACKAGE_VERSION}`,
    beforeSend(event) {
      if (window.FS) {
        const fullStorySessionUrl = window.FS('getSession');
        // eslint-disable-next-line no-param-reassign
        event.extra = {
          ...event.extra,
          fullStorySessionUrl,
        };
      }

      return event;
    },
  });
};
