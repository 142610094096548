export const hiringReportToOtherReport = {
  header: 'NOTE: The date range for this report is based on candidates\' application date.',
  message: 'You will notice differences in this report compared to the previous report you were viewing, which uses a date range based on when a candidate was hired.',
  beaconArticleId: '64c2faccf3abbf47e505260c',
};

export const otherReportToHiringReport = {
  header: 'NOTE: The date range for this report is based on candidates\' offer resolution date.',
  message: 'You will notice differences in this report compared to the previous report you were viewing, which uses a date range based on when a candidate applied.',
  beaconArticleId: '64c2faccf3abbf47e505260c',
};

// Warnings from Project flags
export const dropoffDataIntegrityWarning = {
  header: 'WARNING: The integrity of your drop-off data may be at risk.',
  message: 'Your drop-off rates for the 2 most recently completed quarters fall far enough outside of the expected range to suggest that the drop-off data collected in your ATS may not be accurate/complete. Please exercise caution when interpreting this report.',
  beaconArticleId: '654ad142d6c4276986645dd3',
};

export const hiringOfferEndpointWarning = {
  header: 'WARNING: Based on your integration settings, the data used for this report is approximate.',
  message: 'For a more accurate Hiring Report, you can easily share your Greenhouse Offer endpoint with Datapeople!',
  beaconArticleId: '655d2a70b364db51594cf5cc',
};
