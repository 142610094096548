const defaultShow = () => ({
  filter: false,
  globalFilter: false,
  bookDemo: false,
  settings: false,
  createDraft: false,
  createEmail: false,
  dashboard: false,
  actionableInsight: false,
  requisitionShare: false,
  persona: false,
});

export default defaultShow;
