const updateParam = (params, key, value) => {
  const finalParams = { ...params };
  if (finalParams[key]) {
    if (Array.isArray(finalParams[key])) {
      finalParams[key] = [...finalParams[key], value];
    } else {
      finalParams[key] = [finalParams[key], value];
    }
  } else {
    finalParams[key] = value;
  }

  return finalParams;
};

export const getQueryParamsFromURL = () => {
  const searchParams = new URLSearchParams(window.location.search);

  let finalParams = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of searchParams) {
    if (Number.isNaN(Number(value))) {
      finalParams = updateParam(finalParams, key, value);
    } else {
      finalParams = updateParam(finalParams, key, Number(value));
    }
  }

  return finalParams;
};

export const getParentRoute = (route) => {
  const matchedRoutes = route.matched;

  if (matchedRoutes?.length > 0) {
    // The parent route is the second-to-last element in the matched array
    const parentRoute = matchedRoutes[matchedRoutes.length - 2];

    if (parentRoute?.name) return parentRoute;

    return null;
  }

  return null;
};
