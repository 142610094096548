import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { languageSnapshot } from '@/api/insights';
import { useAppLoading } from '@/stores/app/loading';
import { useAppAlerts } from '@/stores/app/alerts';
import { useInsightsProject } from '@/stores/insights/insightsProject';
import { TYPE_GREENHOUSE } from '@/helpers/constants/integrationTypes';
import { useAppAuthData } from '@/stores/app/authData';
import { mergeFilters } from '@/stores/insights/helpers';
import { useDatePicker } from '@/stores/filters/datePicker';
import { useFilters } from '@/stores/filters/filters';

export const useLanguageSnapshot = defineStore('languageSnapshot', () => {
  // State
  const languageBlocks = ref([]);
  const totals = ref({});

  // Getters
  const isGreenhouse = computed(() => useAppAuthData()
    .authData.integrationTypeKey === TYPE_GREENHOUSE);

  // Actions
  const setLanguageBlocks = (newLanguageBlocks) => {
    languageBlocks.value = newLanguageBlocks;
  };

  const setTotals = (newTotals) => {
    totals.value = newTotals;
  };

  const loadPage = async ({
    router,
    routeQuery,
    selectedFilters,
    selectedMyJobs,
    selectedDateWeek,
  }) => {
    try {
      useAppLoading().setLoading({ pageLoading: true, filtersLoading: true, listLoading: true });

      const parameters = {
        params: routeQuery,
        selectedMyJobs,
        selectedDateWeek,
        selectedFilters,
      };

      const {
        filters,
        dateWeek,
        owned,
        languageBlocks: respLanguageBlocks,
        totals: respTotals,
        projectImageUrl,
        projectName,
      } = await languageSnapshot(parameters);

      useDatePicker().setDateWeek(dateWeek);

      useFilters().updateFilters({ filters, myJobsEnabled: owned });

      setTimeout(() => {
        const queryFilters = mergeFilters(filters);

        const query = {
          week: dateWeek?.dateWeek || undefined,
          owned: owned?.value || undefined,
          ...queryFilters || {},
        };
        router.push({ query })
          .catch((err) => {
            if (err.message.startsWith('Avoided redundant navigation to current location')) {
              return;
            }
            throw err;
          });
      }, 0);

      setLanguageBlocks(respLanguageBlocks);
      useInsightsProject().setSharedProjectInfo({
        newProjectImageUrl: projectImageUrl,
        newProjectName: projectName,
      });
      setTotals(respTotals);
    } catch (e) {
      useAppAlerts().addAlert({ message: e.message });
      throw e;
    } finally {
      useAppLoading().setLoading({ pageLoading: false, filtersLoading: false, listLoading: false });
    }
  };

  return {
    // State
    languageBlocks,
    totals,

    // Getters
    isGreenhouse,

    // Actions
    setLanguageBlocks,
    setTotals,
    loadPage,
  };
});
