import { v4 as uuidv4 } from 'uuid';

const newHeader = (block) => ({
  ...block,
  id: uuidv4(),
  type: 'header',
  html: '<h3><br></h3>',
});

const newList = (block) => ({
  ...block,
  id: uuidv4(),
  type: 'list',
  html: '<ul><li><br></li></ul>',
});

const paragraphToHeader = (block) => ({
  ...block,
  type: 'header',
  html: block.html
    .replace(/^<p/i, '<h3')
    .replace(/p>$/i, 'h3>')
    .replace(/^<div/i, '<h3')
    .replace(/div>$/i, 'h3>'),
});

const cleanupHeader = (block) => {
  const text = block.html.replace(/<[^>]*>/g, '');
  return { ...block, html: `<h3>${text}</h3>` };
};

const normalizeSectionsAndBlocks = (sections = []) => {
  const result = [];

  const addBlock = (block) => {
    const lastSection = result[result.length - 1];
    if (lastSection && lastSection.blocks.length < 2) {
      lastSection.blocks.push(block);
    } else {
      result.push({
        ...lastSection,
        id: uuidv4(),
        blocks: [block],
      });
    }
  };

  sections.forEach((section, i) => {
    const { blocks } = section;

    // section is valid, push to result as is
    if (blocks.length === 2 && blocks[0].type === 'header' && blocks[1].type === 'list') {
      result.push(section);
      return;
    }

    // the first section is not valid, push it without blocks
    if (result.length === 0) {
      result.push({ ...section, blocks: [] });
    }

    blocks.forEach((block, j) => {
      const lastSection = result[result.length - 1];
      // next block of the same section or the first block of the next section
      const nextBlock = blocks[j + 1] || sections[i + 1]?.blocks[0] || {};

      if (lastSection.blocks.length !== 1 && block.type === 'list') {
        // first block must be header
        addBlock(newHeader(block));
      }

      if (block.type === 'paragraph') {
        addBlock(paragraphToHeader(block));
      } else if (block.type === 'header') {
        addBlock(cleanupHeader(block));
      } else {
        addBlock(block);
      }

      if (block.type !== 'list' && nextBlock.type !== 'list') {
        // header must always be followed by list
        addBlock(newList(block));
      } else if (block.type === 'list' && nextBlock.type === 'list') {
        // no two lists one after another
        addBlock(newHeader(block));
      }
    });
  });

  return result;
};

export const fixLeverList = ({ fields = [] } = {}) => ({
  fields: JSON.parse(JSON.stringify(fields)).map((field) => {
    if (field.type !== 'lever_list') {
      return field;
    }

    const { sections = [] } = field;
    return {
      ...field,
      sections: normalizeSectionsAndBlocks(sections),
    };
  }),
});
