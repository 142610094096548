import { computed } from 'vue';
import { defineStore } from 'pinia';

import {
  SETUP_ORPHANED, SETUP_PENDING_TOS, SETUP_PENDING_VERIFICATION,
} from '@/helpers/constants/userSetupStatus';
import { TYPE_SSO_LOCKED } from '@/helpers/constants/userType';
import * as project from '@/helpers/constants/projectStatus';
import {
  SETUP_CRAWLER_REQUIRED, SETUP_MISSING_COMPANY_INFO, SETUP_SEGMENT_NOT_ASSIGNED,
} from '@/helpers/constants/projectSetupStatus';
import { useAppAuthData } from '@/stores/app/authData';

const projectStatusToErrorCode = {
  [project.STATUS_TERMINATED_NO_ATS]: 'terminated-no-ats',
  [project.STATUS_TERMINATED_SUSPENDED]: 'terminated-suspended',
  [project.STATUS_TERMINATED_GENERIC]: 'terminated-generic',
  [project.STATUS_TERMINATED_HR_TECH]: 'terminated-hr-tech',
  [project.STATUS_TRIAL_FORCE_PAY]: 'trial-expired',
  [project.STATUS_TRIAL_PAUSED]: 'trial-paused',
  [project.STATUS_SALES_HOLD]: 'account-on-hold',
  [project.STATUS_TRIAL_EXPIRED]: 'trial-renew',
  [project.STATUS_BILLING_EXPIRED]: 'billing-expired',
};

const projectSetupStatusToErrorCode = {
  [SETUP_MISSING_COMPANY_INFO]: 'about-your-company',
  [SETUP_CRAWLER_REQUIRED]: 'account-being-setup',
  [SETUP_SEGMENT_NOT_ASSIGNED]: 'account-being-setup',
};

export const useAppGate = defineStore('appGate', () => {
  const errorPageRoute = computed(() => {
    const {
      isLoggedIn, userSetupStatus, isSuperuser, userType, projectStatus, projectMaintenanceMode,
      projectSetupStatus, appMaintenanceMode,
    } = useAppAuthData().authData;

    if (!isSuperuser && appMaintenanceMode) {
      return 'maintenance';
    }

    if (!isLoggedIn) {
      return '';
    }

    if (userSetupStatus === SETUP_ORPHANED) {
      return 'account-being-setup';
    }

    /**
     * TODO: redirect([
     *   '/app/create-password',
     *   'token' => $thisUser->password_reset_token,
     *   'reason' => 'expired'
     * ]);
     */

    if (isSuperuser) {
      return '';
    }

    if (userType === TYPE_SSO_LOCKED) {
      return 'sso-locked';
    }

    if (projectStatusToErrorCode[projectStatus]) {
      return projectStatusToErrorCode[projectStatus];
    }

    if (projectMaintenanceMode) {
      return 'technical-hold';
    }

    if (userSetupStatus === SETUP_PENDING_VERIFICATION) {
      return 'verify-email';
    }

    if (projectSetupStatusToErrorCode[projectSetupStatus]) {
      return projectSetupStatusToErrorCode[projectSetupStatus];
    }
    if (userSetupStatus === SETUP_PENDING_TOS) {
      return 'terms-of-service';
    }

    return '';
  });

  return { errorPageRoute };
});
