export const getEnvironment = (() => {
  const { origin } = window.location;
  if (origin === 'https://app.datapeople.io') {
    return 'production';
  }
  if (origin === 'https://sandbox.datapeople.io') {
    return 'sandbox';
  }
  if (origin.includes('test-app.taprecruit.co')) {
    return 'test-app';
  }
  if (origin.includes('staging.iapp.datapeople.io')) {
    return 'staging';
  }
  if (origin.includes('testing.iapp.datapeople.io')) {
    return 'testing';
  }
  return 'development';
});
