export const settings = (authDataStore) => ([
  {
    path: '/settings/',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings.vue'),
    redirect: { name: 'account' },
    meta: { title: 'Settings' },
    children: [
      {
        name: 'templates',
        path: 'templates/:from?',
        props: true,
        meta: { timer: 3000, title: 'Templates' },
        component: () => import(/* webpackChunkName: "templates" */ '@/components/settings/templates/Templates.vue'),
        beforeEnter: async (to, from, next) => {
          if (authDataStore.authData.canEditTemplates) {
            next();
          } else {
            next({ name: 'root' });
          }
        },
      },
      {
        name: 'account',
        path: 'account',
        meta: { timer: 3000, title: 'Account' },
        component: () => import(/* webpackChunkName: "account" */ '@/components/settings/account/Account.vue'),
      },
      {
        name: 'sso-setup-old',
        path: 'sso-setup',
        redirect: () => ({ name: 'sso-setup' }),
      },
      {
        name: 'notifications',
        path: 'notifications',
        meta: { timer: 3000, title: 'Notifications' },
        component: () => import(/* webpackChunkName: "notifications" */ '@/components/settings/Notifications.vue'),
      },
      {
        name: 'integrations',
        path: 'integrations',
        meta: { timer: 3000, title: 'Connect Your ATS' },
        component: () => import(/* webpackChunkName: "integrations" */ '@/components/settings/integrations/Integrations.vue'),
      },
      {
        name: 'team',
        path: 'team',
        meta: { timer: 3000, title: 'Team' },
        component: () => import(/* webpackChunkName: "team" */ '@/components/settings/team/Team.vue'),
      },
      {
        name: 'usage-settings',
        path: 'usage',
        redirect: (to) => ({ name: 'usage', query: { ...to.query, legacy: 'true' } }),
      },
      {
        name: 'organization-settings',
        path: 'organization-settings',
        component: () => import(/* webpackChunkName: "organization-settings" */ '@/components/settings/OrganizationSettings.vue'),
        redirect: () => ({ name: 'sso-setup' }),
        children: [
          {
            name: 'sso-setup',
            path: 'sso-setup',
            meta: { timer: 3000, title: 'Organization Settings', parentTitle: 'Settings' },
            component: () => import(/* webpackChunkName: "organization-settings" */ '@/components/settings/ssoSetup/SsoSetup.vue'),
            beforeEnter: async (to, from, next) => {
              if (authDataStore.authData.isSuperuser || authDataStore.authData.canTeamAdmin) {
                next();
              } else {
                next({ name: 'settings' });
              }
            },
          },
          {
            name: 'user-roles',
            path: 'user-roles',
            meta: { timer: 3000, title: 'Organization Settings', parentTitle: 'Settings' },
            component: () => import(/* webpackChunkName: "organization-settings" */ '@/components/settings/UserRoles.vue'),
            beforeEnter: async (to, from, next) => {
              if (authDataStore.authData.isSuperuser || authDataStore.authData.canGreenhouseRoles) {
                next();
              } else {
                next({ name: 'settings' });
              }
            },
          },
          {
            name: 'custom-fields',
            path: 'custom-fields',
            meta: { timer: 3000, title: 'Organization Settings', parentTitle: 'Settings' },
            component: () => import(/* webpackChunkName: "organization-settings" */ '@/components/settings/CustomFields.vue'),
            beforeEnter: async (to, from, next) => {
              if (authDataStore.authData.isSuperuser || authDataStore.authData.canCustomFields) {
                next();
              } else {
                next({ name: 'settings' });
              }
            },
          },
        ],
      },
      {
        name: 'user-roles-old',
        path: 'user-roles',
        redirect: () => ({ name: 'user-roles' }),
      },
      {
        name: 'custom-fields-old',
        path: 'custom-fields',
        redirect: () => ({ name: 'custom-fields' }),
      },
      {
        name: 'style-guide',
        path: 'style-guide',
        meta: { timer: 3000, title: 'Style Guide' },
        component: () => import(/* webpackChunkName: "style-guide" */ '@/components/settings/styleGuide/StyleGuide.vue'),
        beforeEnter: async (to, from, next) => {
          if (authDataStore.authData.canTeamAdmin) {
            next();
          } else {
            next({ name: 'settings' });
          }
        },
      },
      {
        name: 'policy-guide',
        path: 'policy-guide',
        meta: { timer: 3000, title: 'Policy Guide' },
        component: () => import(/* webpackChunkName: "policy-guide" */ '@/components/settings/policyGuide/PolicyGuide.vue'),
        beforeEnter: async (to, from, next) => {
          if (authDataStore.authData.canTeamAdmin) {
            next();
          } else {
            next({ name: 'settings' });
          }
        },
      },
      {
        name: 'campaigns',
        path: 'campaigns/:from?',
        props: true,
        meta: { timer: 3000, title: 'Campaigns' },
        component: () => import(/* webpackChunkName: "campaigns" */ '@/components/settings/Campaigns.vue'),
        beforeEnter: async (to, from, next) => {
          if (authDataStore.authData.showCampaignsPage) {
            next();
          } else {
            next({ name: 'root' });
          }
        },
      },
    ],
  },
]);
