import { roundFloat } from '@/helpers/formatters';
import { format } from 'date-fns';
import theme from '@/configs/vuetify';
import { getValues } from './transformers';
import { getWidgetAndTableLabels } from './common';

const {
  primaryColor,
  pacificBlue,
  pacificBlueLighten60,
  primaryColorLighten90,
  zirconGrey,
  caribbeanGreen,
  violetPurple,
  newOrange,
} = theme.theme.themes.light.colors;

/** defaultWidgetConfig manages the frontend config for each api widget */
const defaultWidgetConfig = [
  {
    id: 'requisitions-by-time',
    itemLabel: 'Requisitions',
    groupLabel: 'by Time',
    type: 'lineChart',
    size: 'large',
    colors: [
      primaryColor,
      primaryColorLighten90,
    ],
    labels: [],
    values: [],
    isTimestamp: true,
  },
  {
    id: 'requisitions-by-status',
    itemLabel: 'Requisitions',
    groupLabel: 'by Status',
    type: 'verticalBarChart',
    colors: [pacificBlue, pacificBlueLighten60],
    colorPerDataset: true,
    labels: [],
    values: [],
    isTimestamp: true,
  },
  {
    id: 'hiring-team-by-time',
    itemLabel: 'Hiring Team',
    groupLabel: 'by Time',
    type: 'verticalStackedBarChart',
    colors: [
      primaryColor,
      pacificBlue,
      pacificBlueLighten60,
      zirconGrey,
    ],
    labels: [],
    values: [],
    isTimestamp: true,
    config: {
      titleFormatter: (title) => format(new Date(`${title} 00:00`), 'MM/dd/yyyy'),
    },
  },
  {
    id: 'applications-by-time',
    itemLabel: 'Applications',
    groupLabel: 'by Time',
    type: 'verticalStackedBarChart',
    colors: [
      primaryColor,
      pacificBlueLighten60,
      caribbeanGreen,
      pacificBlue,
      violetPurple,
      newOrange,
      zirconGrey,
    ],
    labels: [],
    values: [],
    isTimestamp: true,
    config: {
      titleFormatter: (title) => format(new Date(`${title} 00:00`), 'MM/dd/yyyy'),
    },
  },
  {
    id: 'hires-by-time',
    itemLabel: 'Hires',
    groupLabel: 'by Time',
    type: 'verticalStackedBarChart',
    colors: [
      primaryColor,
      pacificBlueLighten60,
      caribbeanGreen,
      pacificBlue,
      violetPurple,
      newOrange,
      zirconGrey,
    ],
    labels: [],
    values: [],
    isTimestamp: true,
    config: {
      titleFormatter: (title) => format(new Date(`${title} 00:00`), 'MM/dd/yyyy'),
    },
  },
];

/**
 * fieldMappings is used to map the index of a widget from `defaultWidgetConfig`
 * to which values it will need to pull off the api object, labels, and formatting
 */
const fieldMappings = [
  {
    value: 'count',
    label: 'date',
    labelFormat: 'timestamp',
  },
  {
    value: ['reqs_closed', 'reqs_opened'],
    label: 'date',
    labelFormat: 'timestamp',
    datasetLabels: [
      'Closed',
      'Opened',
    ],
  },
  {
    value: [['Coordinator', 'Recruiter', 'Sourcer', 'Other']],
    label: 'date',
    labelFormat: 'timestamp',
    datasetLabels: ['Coordinators', 'Recruiters', 'Sourcers', 'Other'],
  },
  {
    value: [['events_applied', 'internal_applied', 'organic_applied', 'other_applied', 'referrals_applied', 'socialmedia_applied', 'sourcing_applied']],
    label: 'date',
    labelFormat: 'timestamp',
    datasetLabels: ['Events', 'Internal', 'Organic', 'Other', 'Referrals', 'Social Media', 'Sourcing'],
  },
  {
    value: [['events_hired', 'internal_hired', 'organic_hired', 'other_hired', 'referrals_hired', 'socialmedia_hired', 'sourcing_hired']],
    label: 'date',
    labelFormat: 'timestamp',
    datasetLabels: ['Events', 'Internal', 'Organic', 'Other', 'Referrals', 'Social Media', 'Sourcing'],
  },
];

/**
 * widgetApiDataIndex is used to map the index of a widget from `defaultWidgetConfig`
 * to the api data definition `dashboardRequests` defined in `src/api/helpers/insights/insights`
 */
const widgetApiDataIndex = [
  0,
  1,
  2,
  3,
  3,
];

/**
 * getHiringDynamicsTotals maps what fields the frontend needs for totals to the api dataset
 *
 * @param totals
 * @returns {object}
 */
const getHiringDynamicsTotals = (totals) => {
  const data = totals[0];

  if (data) {
    const activeReqs = roundFloat(data.active_reqs);
    const totalJobOffers = roundFloat(data.offers_total);
    const totalHires = roundFloat(data.offers_accepted);

    return {
      total_active_requisitions: activeReqs,
      total_job_offers: totalJobOffers,
      total_hires: totalHires,
    };
  }

  return {};
};

/**
 * transformHiringTeamData is used to handle custom logic
 * for the hiring team data that wasn't handled in the raw data api
 *
 * @param datasets
 * @returns {object}
 */
const transformHiringTeamData = (datasets) => {
  const other = datasets.Other.map((value, i) => {
    let total = value;
    total += datasets?.['Hiring Manager']?.[i] || 0;
    total += datasets?.Executive?.[i] || 0;
    total += datasets?.Interviewer?.[i] || 0;

    return total;
  });

  return { ...datasets, Other: other };
};

/**
 * getHiringDynamicsData decouples the totals data at index `0`
 * and then handles failed and successful requests. It generates the widget for successful requests
 *
 * @param results
 * @returns {object} { widgets, totals }
 */
export const getHiringDynamicsData = (results) => {
  const totals = results[0].status === 'fulfilled' ? getHiringDynamicsTotals(results[0].value) : {};

  results.shift();

  const widgets = widgetApiDataIndex.map((apiDataIndex, i) => {
    const result = results[apiDataIndex];
    const defaultConfig = defaultWidgetConfig[i];
    const isValidData = Array.isArray(result.value) ? false : Object.keys(result.value).length > 0;

    if (result.status === 'fulfilled' && isValidData) {
      const fields = fieldMappings[i];
      const { labels, datasets } = result.value;
      const isTimestamp = fields.labelFormat.startsWith('timestamp');

      // Handles aggregating hiring team widget data
      const transformedValue = apiDataIndex === 2
        ? transformHiringTeamData(datasets) : datasets;

      return {
        ...defaultConfig,
        isTimestamp,
        labels,
        values: getValues(transformedValue, fields, labels, defaultConfig.type, isTimestamp),
      };
    }

    return { ...defaultConfig };
  });

  const labelFormats = fieldMappings.map(({ labelFormat }) => labelFormat);

  return { widgets, totals, labelFormats };
};

export const getHiringDynamicsLabelsForLoading = getWidgetAndTableLabels(
  defaultWidgetConfig,
);
