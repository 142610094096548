import { isEmpty } from 'lodash-es';

export const getBenchmarkMaxMinValues = (options, max, chartId) => {
  if (isEmpty(options)) return null;

  const {
    max: benchmarkMaxValue, min: benchmarkMinValue, leftOffset, rightOffset,
  } = options;
  const benchmarkMinXPercent = benchmarkMinValue / max;
  const benchmarkMaxXPercent = benchmarkMaxValue / max;
  const canvasWidth = document.getElementById(chartId)?.offsetWidth;

  if (!canvasWidth) return null;

  const width = canvasWidth - rightOffset - leftOffset;

  const newestMinX = width * benchmarkMinXPercent + leftOffset;
  const newestMaxX = width * benchmarkMaxXPercent + leftOffset;

  return { newestMinX, newestMaxX };
};
