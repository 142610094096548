import ChartDataLabels from 'chartjs-plugin-datalabels';
import { dataLabelPercentages, xTickPercentFormat } from '@/api/helpers/insights/commonChartConfigs';
import { LegendSpacingPlugin } from '@/api/helpers/insights/chartPlugins';
import theme from '@/configs/vuetify';
import { getWidgets } from './transformers';
import {
  getPassthroughDropOffTables,
  sortPassthroughDropoffLabelsAndValues,
  sortPassthroughDropoffTables,
  calculatePassthroughPercentages,
  getWidgetAndTableLabels,
} from './common';

const {
  primaryColor, royalFuscia, jaffaOrange, caribbeanGreen,
} = theme.theme.themes.light.colors;

/** defaultWidgetConfig manages the frontend config for each api widget */
const defaultWidgetConfig = [
  {
    id: 'higher-proportion-drop-offs',
    tooltipKey: 'dropoff-highest-proportion-by-stage',
    itemLabel: 'At what stage do most candidates drop off?',
    groupLabel: '',
    labels: [],
    values: [],
    type: 'horizontalBarChart',
    colors: [[
      primaryColor,
      royalFuscia,
      jaffaOrange,
      caribbeanGreen,
    ]],
    optionsOverride: {
      ...xTickPercentFormat,
      ...dataLabelPercentages,
    },
    pluginsOverride: [ChartDataLabels],
    convertToPercent: false,
  },
  {
    id: 'most-drop-offs',
    tooltipKey: 'dropoff-by-stage',
    itemLabel: 'At what stage do most drop-offs occur?',
    groupLabel: '',
    labels: [],
    values: [],
    type: 'doughnutChart',
    colors: [
      primaryColor,
      royalFuscia,
      jaffaOrange,
      caribbeanGreen,
    ],
    pluginsOverride: [
      LegendSpacingPlugin,
    ],
    convertToPercent: false,
  },
];

/**
 * fieldMappings is used to map the index of a widget from `defaultWidgetConfig`
 * to which values it will need to pull off the api object, labels, and formatting
 */
const fieldMappings = [
  {
    value: 'count',
    label: 'count',
    labelFormat: 'percent',
  },
  {
    value: 'count',
    label: 'count',
    labelFormat: 'percent',
  },
];

const tableLabelForLoading = 'Drop-off Report';
const tableKeys = ['applied', 'screening', 'assessment', 'offer', 'hired'];
const tableLabels = ['Drop-off Report'];
const displayOptions = {
  passthroughPercentages: {
    showTotals: false,
    showRows: false,
  },
};

const formatDropOffRate = (tables) => {
  const newTables = {};

  Object.keys(tables).forEach((key) => {
    newTables[key] = {
      ...tables[key],
      data: (tables[key]?.data || []).map((dataPoint) => ({
        ...dataPoint,
        items: dataPoint.items.map((item) => {
          const newItem = item;

          if (item.label === 'Net Drop-off Rate') {
            Object.keys(item).forEach((itemKey) => {
              if (tableKeys.includes(itemKey)) {
                newItem[itemKey] = {
                  ...newItem[itemKey],
                  format: 'percent',
                  hide: itemKey === 'hired',
                };
              }
            });

            return newItem;
          }

          if (item.label === 'Net Total Applications') {
            newItem.label = 'Net Applications';
          }

          return newItem;
        }),
      })),
    };
  });

  return tables;
};

const getDropoffTables = (tableData) => {
  const tables = getPassthroughDropOffTables(tableData, { tableKeys, tableLabels, totalLabel: 'Total Applications' });
  const dropOffFormattedTables = formatDropOffRate(tables);
  const sortedTables = sortPassthroughDropoffTables(dropOffFormattedTables, ['Dropped Out', 'Net Applications', 'Net Drop-off Rate']);
  const tablesWithPassthroughPercentages = calculatePassthroughPercentages(
    sortedTables,
    displayOptions.passthroughPercentages,
  );

  return tablesWithPassthroughPercentages;
};

const getDropoffWidgets = (chartData) => {
  const widgets = getWidgets(chartData, {
    defaultWidgetConfig,
    fieldMappings,
  });
  const sortedWidgets = sortPassthroughDropoffLabelsAndValues(widgets);

  return sortedWidgets;
};

/**
 * getDropOffData decouples the totals data at index `0`
 * and then handles failed and successful requests. It generates the widget for successful requests
 * @param results
 * @param results.chartData
 * @param results.tableData
 * @param results.metrics
 * @returns {object} { widgets, totals }
 */
export const getDropOffData = ({ chartData, tableData, metrics }) => {
  const widgets = getDropoffWidgets(chartData);
  const tables = getDropoffTables(tableData);
  const labelFormats = fieldMappings.map(({ labelFormat }) => labelFormat);

  return {
    widgets, totals: metrics, labelFormats, tables, displayOptions,
  };
};

export const getDropOffLabelsForLoading = getWidgetAndTableLabels(
  defaultWidgetConfig,
  tableLabelForLoading,
);
