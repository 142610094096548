import { isEmpty } from 'lodash-es';
import { paramCleaner } from '@/helpers/formatters';
import { getQuarter } from '@/helpers/date';
import {
  dashboardRequests,
} from '@/api/helpers/insights/insights';
import { format } from 'date-fns';
import { postRequest, formSubmitRequest, getRequest } from './request';

export const initReporting = async () => {
  const {
    analyticsStatus = {},
    customerSuccessCalendarUrl = '',
    accountExecutiveEmail = '',
    validUsers,
  } = await getRequest({ url: '/analytics/init' });
  const {
    analytics_premium: analyticsPremium = '',
    analytics_premium_seconds_left: analyticsPremiumSecondsLeft = 0,
    gender_report: genderReport = false,
  } = analyticsStatus || {};

  return {
    analyticsPremium,
    analyticsPremiumSecondsLeft,
    genderReport,
    customerSuccessCalendarUrl,
    validUsers,
    accountExecutiveEmail,
  };
};

export const getExecutiveInsights = async ({
  selectedDateRange = {}, routeQuery = {}, filters = [], owned,
}) => {
  const {
    to: queryTo, from: queryFrom, owned: queryOwned, ...paramFilters
  } = routeQuery;

  const cleanedParams = paramCleaner(paramFilters);

  const { to = queryTo, from = queryFrom } = selectedDateRange;

  const searchParams = new URLSearchParams();

  if (typeof to !== 'undefined') searchParams.append('to', to);
  else searchParams.append('to', format(getQuarter(1)[1], 'yyyy-MM-dd'));

  if (typeof from !== 'undefined') searchParams.append('from', from);
  else searchParams.append('from', format(getQuarter(1)[0], 'yyyy-MM-dd'));

  return postRequest({
    url: `/analytics/executive-insights?${searchParams}`,
    timeout: 60000,
    body: {
      filters: !isEmpty(filters) ? filters : cleanedParams,
      dateRange: selectedDateRange,
      owned,
    },
  });
};

export const complianceReport = async ({
  params, selectedFilters = {}, selectedMyJobs, sort,
}) => {
  const {
    page, to, from, ...paramFilters
  } = params;

  const cleanedParams = paramCleaner(paramFilters);

  const searchParams = new URLSearchParams();
  if (typeof page !== 'undefined') searchParams.append('page', page);
  if (typeof sort !== 'undefined') searchParams.append('sort', sort);

  const {
    documents = [],
    filters = [],
    pagination = {},
    totals = {},
    owned = {},
    sorting = {},
    exclusions = {},
  } = await postRequest({
    url: `/analytics/compliance?${searchParams}`,
    body: {
      filters: !isEmpty(selectedFilters) ? selectedFilters : cleanedParams,
      owned: selectedMyJobs,
    },
  });
  return {
    documents,
    filters,
    pagination,
    totals,
    owned,
    sorting,
    exclusions,
  };
};

export const easyWinsReport = async ({
  params, selectedFilters = {}, selectedMyJobs, sort,
}) => {
  const {
    page, to, from, ...paramFilters
  } = params;

  const cleanedParams = paramCleaner(paramFilters);

  const searchParams = new URLSearchParams();
  if (typeof page !== 'undefined') searchParams.append('page', page);
  if (typeof sort !== 'undefined') searchParams.append('sort', sort);

  const {
    documents = [],
    filters = [],
    pagination = {},
    totals = {},
    owned = {},
    sorting = {},
    exclusions = {},
  } = await postRequest({
    url: `/analytics/easy-wins?${searchParams}`,
    body: {
      filters: !isEmpty(selectedFilters) ? selectedFilters : cleanedParams,
      owned: selectedMyJobs,
    },
  });
  return {
    documents,
    filters,
    pagination,
    totals,
    owned,
    sorting,
    exclusions,
  };
};

export const confidenceGapReport = async ({
  params, selectedFilters = {}, selectedMyJobs, sort,
}) => {
  const {
    page, to, from, ...paramFilters
  } = params;

  const cleanedParams = paramCleaner(paramFilters);

  const searchParams = new URLSearchParams();
  if (typeof page !== 'undefined') searchParams.append('page', page);
  if (typeof sort !== 'undefined') searchParams.append('sort', sort);

  const body = {
    filters: !isEmpty(selectedFilters) ? selectedFilters : cleanedParams,
    owned: selectedMyJobs,
  };

  const { reportingShareKey } = window.sessionStorage;

  if (reportingShareKey) {
    body.key = reportingShareKey;
  }

  const {
    documents = [],
    filters = [],
    pagination = {},
    totals = {},
    owned = {},
    sorting = {},
    projectImageUrl,
    projectName,
  } = await postRequest({
    url: `/analytics/confidence-gap?${searchParams}`,
    body,
  });
  return {
    documents,
    filters,
    pagination,
    totals,
    owned,
    sorting,
    projectImageUrl,
    projectName,
  };
};

export const reportingUsage = async ({
  params, selectedFilters = {}, selectedDateRange = {}, selectedMyJobs,
}) => {
  const {
    page, to, from, ...paramFilters
  } = params;

  const cleanedParams = paramCleaner(paramFilters);

  const searchParams = new URLSearchParams();
  if (typeof page !== 'undefined') searchParams.append('page', page);

  const {
    dateChart = [],
    dateRange = {},
    documents = [],
    engagementUsers = [],
    filters = [],
    pagination = {},
    scoreMatrix = [],
    totals = {},
    owned = {},
  } = await postRequest({
    url: `/analytics/usage?${searchParams}`,
    body: {
      filters: !isEmpty(selectedFilters) ? selectedFilters : cleanedParams,
      dateRange: !isEmpty(selectedDateRange) ? selectedDateRange : { to, from },
      owned: selectedMyJobs,
    },
  });
  return {
    dateChart,
    dateRange,
    documents,
    engagementUsers,
    filters,
    pagination,
    scoreMatrix,
    totals,
    owned,
  };
};

export const startReportingFreeTrial = async () => postRequest({
  url: '/analytics/start-reporting-trial',
});

export const reportingDownloadUsage = ({
  document, filters, dateRange, myJobsEnabled,
}) => {
  const { to, from } = dateRange;

  formSubmitRequest(document, '/analytics/usage-download', {
    filters,
    dateRange: { to, from },
    owned: myJobsEnabled,
  });
};

export const getDashboardsPage = async ({
  page, selectedDateRange = {}, routeQuery = {}, filters = [], owned,
}) => {
  const url = dashboardRequests[page];

  const {
    to: queryTo, from: queryFrom, owned: queryOwned, ...paramFilters
  } = routeQuery;

  const cleanedParams = paramCleaner(paramFilters);

  const { to = queryTo, from = queryFrom } = selectedDateRange;

  const searchParams = new URLSearchParams();

  if (typeof to !== 'undefined') searchParams.append('to', to);
  else searchParams.append('to', format(getQuarter(1)[1], 'yyyy-MM-dd'));

  if (typeof from !== 'undefined') searchParams.append('from', from);
  else searchParams.append('from', format(getQuarter(1)[0], 'yyyy-MM-dd'));

  return postRequest({
    url,
    body: {
      filters: !isEmpty(filters) ? filters : cleanedParams,
      dateRange: selectedDateRange,
      owned,
    },
  });
};

export const sendDashboardShareLink = async ({ userIds, note, url }) => {
  try {
    const { success, succeeded, failed } = await postRequest({
      url: '/analytics/dashboard-share-link',
      body: { userIds, note, url },
    });

    /* If all emails sent successfully: success = true */
    if (success) {
      return { success, succeeded, failed };
    }
  } catch (error) {
    const {
      failed, succeeded, success, attributes, errors, code,
    } = error.response || {};

    /* If some emails sent successfully, but others failed:
      success = false,
      succeeded = array of email address that were successfully sent to,
      failed = array of email address that could not be sent to,
    */
    if (!success && succeeded && failed) {
      return { failed, succeeded, success };
    }

    if (code === 'validation_failed') {
      return { attributes, errors, code };
    }
  }
  throw new Error('Email failed');
};

export const sendActionableInsightsShareLinks = async ({
  userIds, note, jobIds, reportUrl,
}) => {
  try {
    const { success, succeeded, failed } = await postRequest({
      url: '/analytics/notify',
      body: {
        userIds, note, jobIds, reportUrl,
      },
    });

    /* If all emails sent successfully: success = true */
    if (success) {
      return { success, succeeded, failed };
    }
  } catch (error) {
    const {
      failed, succeeded, success, attributes, errors, code,
    } = error.response || {};

    /* If some emails sent successfully, but others failed:
      success = false,
      succeeded = array of email address that were successfully sent to,
      failed = array of email address that could not be sent to,
    */
    if (!success && succeeded && failed) {
      return { failed, succeeded, success };
    }

    if (code === 'validation_failed') {
      return { attributes, errors, code };
    }
  }
  throw new Error('Email failed');
};

export const complianceReportDownload = ({
  document, filters, dateRange, myJobsEnabled, sort,
}) => formSubmitRequest(document, `/analytics/compliance-download?sort=${sort}`, {
  filters,
  dateRange,
  owned: myJobsEnabled,
});

export const easyWinsReportDownload = ({
  document, filters, dateRange, myJobsEnabled, sort,
}) => formSubmitRequest(document, `/analytics/easy-wins-download?sort=${sort}`, {
  filters,
  dateRange,
  owned: myJobsEnabled,
});

export const confidenceGapReportDownload = ({
  document, filters, dateRange, myJobsEnabled, sort,
}) => formSubmitRequest(document, `/analytics/confidence-gap-download?sort=${sort}`, {
  filters,
  dateRange,
  owned: myJobsEnabled,
});

export const getTooltips = async (keys) => postRequest({
  url: '/alt/tooltips/any',
  body: {
    keys,
  },
});

export const contentSnapshot = async ({
  selectedFilters = {}, selectedDateWeek = '', selectedMyJobs, params = {},
}) => {
  const {
    week: paramWeek, owned: paramOwned, key: paramKey, ...filterParams
  } = params;
  const cleanedFilters = paramCleaner(filterParams);

  const body = {
    filters: !isEmpty(selectedFilters) ? selectedFilters : cleanedFilters,
    dateWeek: selectedDateWeek,
    owned: selectedMyJobs,
  };

  const { reportingShareKey } = window.sessionStorage;

  if (reportingShareKey) {
    body.key = reportingShareKey;
  }

  const {
    filters = [],
    dateWeek,
    owned = {},
    contentStats,
    totals,
    projectImageUrl,
    projectName,
  } = await postRequest({
    url: '/analytics/content',
    body,
  });
  return {
    filters, dateWeek, owned, contentStats, totals, projectImageUrl, projectName,
  };
};

export const weeklySnapshot = async ({
  selectedFilters = {}, week = '', owned: selectedMyJobs, page = 1, sort = 'title', params = {},
}) => {
  const {
    week: paramWeek, page: paramPage, sort: paramSort, owned: paramOwned, ...filterParams
  } = params;
  const cleanedFilters = paramCleaner(filterParams);

  const {
    filters = [],
    dateWeek,
    owned = {},
    pagination = {},
    documents = [],
    totals = {},
  } = await postRequest({
    url: `/analytics/health?page=${page}&sort=${sort}`,
    body: {
      filters: !isEmpty(selectedFilters) ? selectedFilters : cleanedFilters,
      dateWeek: week,
      owned: selectedMyJobs,
    },
  });

  return {
    filters, dateWeek, owned, documents, pagination, totals,
  };
};

export const weeklySnapshotDownload = ({
  document, filters, dateWeek, myJobsEnabled, sort = 'title',
}) => formSubmitRequest(document, `/analytics/health-download?sort=${sort}`, {
  filters,
  dateWeek,
  owned: myJobsEnabled,
});

export const recruitingPerformance = async ({
  selectedFilters = {}, selectedDateRange = {}, selectedMyJobs, page = 1, params = {},
}) => {
  const {
    week: paramWeek, from, to, page: paramPage, sort: paramSort, owned: paramOwned, ...filterParams
  } = params;
  const cleanedFilters = paramCleaner(filterParams);

  const {
    filters = [],
    dateRange = {},
    owned = {},
    periodData = {},
    pagination = {},
    documents = [],
    totals = {},
    summary = {},
  } = await postRequest({
    url: `/analytics/recruiting-performance?page=${page}`,
    body: {
      filters: !isEmpty(selectedFilters) ? selectedFilters : cleanedFilters,
      dateRange: selectedDateRange,
      owned: selectedMyJobs,
    },
  });

  return {
    filters,
    dateRange,
    owned,
    pagination,
    documents,
    totals,
    periodData,
    summary,
  };
};

export const languageSnapshot = async ({
  selectedFilters = {}, selectedDateWeek = '', selectedMyJobs, params = {},
}) => {
  const {
    week: paramWeek, owned: paramOwned, key: paramKey, ...filterParams
  } = params;
  const cleanedFilters = paramCleaner(filterParams);

  const body = {
    filters: !isEmpty(selectedFilters) ? selectedFilters : cleanedFilters,
    dateWeek: selectedDateWeek,
    owned: selectedMyJobs,
  };

  const { reportingShareKey } = window.sessionStorage;

  if (reportingShareKey) {
    body.key = reportingShareKey;
  }

  const {
    filters = [],
    dateWeek,
    owned = {},
    languageBlocks,
    totals,
    projectImageUrl,
    projectName,
  } = await postRequest({
    url: '/analytics/language',
    body,
  });

  return {
    filters, dateWeek, owned, languageBlocks, totals, projectImageUrl, projectName,
  };
};
